import React, { useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import moment from "moment";
import axios from "axios";

import { components, data, sharedHelper } from "@crewos/shared";

import SectionDetail from "./SectionDetail";
import Signatures from "./Signatures";

const { Icon } = components;

const { useWorkOrderDetails } = data;

const SafetyDetail = ({ safety, status }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [isLoading, setIsLoading] = useState();

  const templateWorkOrder = useMemo(() => {
    return safety?.safetyTemplateWorkOrder || {};
  }, [safety]);

  const template = useMemo(() => {
    return templateWorkOrder?.template || {};
  }, [templateWorkOrder]);

  const sections = useMemo(() => {
    return template?.sections || [];
  }, [template]);

  const totalQuestionsBySection = useMemo(() => {
    return sections?.reduce((acc, section) => {
      acc[section?.id] = section?.groups.reduce(
        (acc, group) => acc + group?.questions?.length,
        0
      );
      return acc;
    }, {});
  }, [sections]);

  const totalQuestions = useMemo(() => {
    return Object.values(totalQuestionsBySection).reduce(
      (acc, total) => acc + total,
      0
    );
  }, [totalQuestionsBySection]);

  const signatures = useMemo(() => {
    return safety?.safetySignatures || [];
  }, [safety]);

  const crewMembers = useMemo(() => {
    return safety?.crewWorkDay?.crew?.employeeCrews?.map(
      (employeeCrew) => employeeCrew?.employee
    );
  }, [safety]);

  const configuration = useMemo(() => {
    return safety?.configuration || {};
  }, [safety]);

  const crewLead = useMemo(() => {
    return safety?.crewWorkDay?.crew?.employeeCrews.find(
      (employeeCrew) => employeeCrew?.isLead
    )?.employee;
  }, [safety]);

  if (!safety) {
    return null;
  }

  const onDownloadPdf = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    sharedHelper.loadingToast();
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/safety/${safety.id}/report`,
      {
        responseType: "blob",
        withCredentials: true,
      }
    );
    const woNumber = workOrderDetails.workOrder.workOrderNumber;
    const templateName = safety.safetyTemplateWorkOrder.template.name;
    const date = safety.crewWorkDay.date;

    const fileName = `${woNumber}-${templateName}-${date}.pdf`
      .replaceAll(" ", "_")
      .toLowerCase();
    const report = new Blob([response.data], { type: "application/pdf" });
    sharedHelper.downloadFile(report, fileName);
    sharedHelper.dismissLoadingToast();
    setIsLoading(false);
  };

  return (
    <div>
      <Row className="border-0 align-items-start w-100 mt-n4">
        <Col sm={10} className="d-flex align-items-center">
          <span className="d-block fw-semibold font-size-1-1">
            {template.name}
          </span>
          <Icon
            onClick={onDownloadPdf}
            name="download"
            className="ms-2 cursor-pointer"
          />
        </Col>
        <Col
          sm={2}
          className="d-flex align-items-center justify-content-center"
        >
          <small style={status?.color} className="p-2 flex-shrink-0 rounded">
            {status?.item?.name}
          </small>
        </Col>
        <Col sm={12}>
          <small className="text-gray">
            {template.name} - {totalQuestions} questions
          </small>
        </Col>
      </Row>
      <Row sm={3} className="mt-3">
        <Col>
          <h5>Inspector</h5>
          <p>
            {safety.createdByUser
              ? `${safety.createdByUser.firstName} ${safety.createdByUser.lastName}`
              : "N/A"}
          </p>
        </Col>
        <Col>
          <h5>Contact Name</h5>
          <p>
            {safety.employee
              ? `${safety.employee.firstName} ${safety.employee.lastName}`
              : "N/A"}
          </p>
        </Col>
        <Col>
          <h5>Date</h5>
          <p>{moment(safety.date).format("MM/DD/YYYY")}</p>
        </Col>
      </Row>
      <hr />
      <div className="height-vh-60 overflow-y-auto hidden-scrollbar">
        {sections
          .sort((a, b) => a.order - b.order)
          .map((section) => (
            <SectionDetail section={section} key={section.id} />
          ))}
        <Signatures
          signatures={signatures}
          crewMembers={crewMembers}
          configuration={configuration}
          crewLead={crewLead}
          noSignatureReason={safety.noSignatureReason}
        />
      </div>
    </div>
  );
};

export default SafetyDetail;
