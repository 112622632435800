import React, { useEffect, useMemo, useCallback, memo } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { components } from "@crewos/shared";
import {
  useGetInspectionsTemplates,
  useGetCustomerLocationAsset,
} from "../../api/Inspections.hooks";

const { SelectAdvance } = components;

const AssignTemplateWOForm = ({
  onSelectTemplate,
  onSelectAsset,
  error,
  isAssetFlowEntityEnabled,
  workOrder,
  selectedAsset,
  selectedTemplate,
}) => {
  // Use the custom hook to fetch inspection templates
  const {
    data: templatesData,
    isLoading: isLoadingTemplates,
    get: fetchTemplates,
  } = useGetInspectionsTemplates();

  const {
    data: assetsData,
    isLoading: isLoadingAssets,
    get: fetchAssets,
  } = useGetCustomerLocationAsset();

  useEffect(() => {
    if (isAssetFlowEntityEnabled && workOrder) {
      fetchAssets({
        search: "",
        pageSize: 30,
        customerLocationId: workOrder.customerLocationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to fetch templates on component mount
  useEffect(() => {
    fetchTemplates({ search: "", pageSize: 30, status: "PUBLISHED" });
  }, [fetchTemplates]);

  // Handler for searching templates
  const handleSearchTemplates = useCallback(
    (searchText) => {
      fetchTemplates({ search: searchText, pageSize: 30, status: "PUBLISHED" });
    },
    [fetchTemplates]
  );

  const handleSearchAssets = useCallback(
    (searchText) => {
      fetchAssets({
        search: searchText,
        pageSize: 30,
        customerLocationId: workOrder.customerLocationId,
      });
    },
    [fetchAssets, workOrder]
  );

  const templateOptions = useMemo(() => {
    if (templatesData && templatesData.data) {
      return templatesData.data.map((template) => ({
        value: template.id,
        label: template.name,
      }));
    }
    return [];
  }, [templatesData]);

  const assetOptions = useMemo(() => {
    if (assetsData && assetsData.data) {
      return assetsData.data.map((asset) => ({
        value: asset.id,
        label: asset.name,
      }));
    }
    return [];
  }, [assetsData]);

  const handleSelect = useCallback(
    (selectedOption) => {
      onSelectTemplate(selectedOption);
    },
    [onSelectTemplate]
  );

  const handleSelectAsset = useCallback(
    (selectedOption) => {
      if (isAssetFlowEntityEnabled && onSelectAsset) {
        onSelectAsset(selectedOption);
      }
    },
    [onSelectAsset, isAssetFlowEntityEnabled]
  );

  return (
    <>
      <FormGroup>
        <Label data-testid="asset-name">
          <span>{isAssetFlowEntityEnabled ? "Asset" : "Asset Name"}</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        {isAssetFlowEntityEnabled ? (
          <>
            <SelectAdvance
              entity="asset"
              name="asset"
              className={`form-control-redesign ${
                error?.asset?.length > 0 ? "border-warning color-warning" : ""
              }`}
              wrapperClassName={error?.asset?.length > 0 ? "is-invalid" : ""}
              placeholder="Search an asset..."
              noOptionsMessage="No assets found"
              options={assetOptions}
              isLoading={isLoadingAssets}
              onSelected={handleSelectAsset}
              onSearch={handleSearchAssets}
              aria-invalid={error?.asset?.length > 0}
              defaultOptions
              cacheOptions
              selected={selectedAsset}
              dataTestId="asset-select"
            />
          </>
        ) : (
          <input
            className="form-control-redesign"
            type="text"
            placeholder="Type an asset name"
            required
            name="name"
          />
        )}
      </FormGroup>
      <FormGroup>
        <Label data-testid="select-template-label">
          <span>Select an Inspection Template</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <SelectAdvance
          entity="template"
          name="template"
          className={`form-control-redesign ${
            error?.template?.length > 0 ? "border-warning color-warning" : ""
          }`}
          wrapperClassName={error?.template?.length > 0 ? "is-invalid" : ""}
          placeholder="Search templates"
          noOptionsMessage="No templates found"
          options={templateOptions}
          isLoading={isLoadingTemplates}
          onSelected={handleSelect}
          onSearch={handleSearchTemplates}
          aria-invalid={error?.template?.length > 0}
          dataTestId="template-select"
        />
        {error?.template?.length > 0 &&
          error.template.map((errorLine, index) => (
            <FormFeedback key={index}>{errorLine}</FormFeedback>
          ))}
      </FormGroup>
    </>
  );
};

export default memo(AssignTemplateWOForm);
