import React, { useEffect, useMemo, useState } from "react";

import { DebounceInput } from "react-debounce-input";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import moment from "moment";
import { useGetUsersBilling } from "../../api/Billing.hooks";

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const MONTHS_PERIOD = 6;

const {
  AdvanceTable,
  MonthSelector,
  AdvanceTablePagination,
  Icon,
  InformationModal,
} = components;

const Cards = ({ totalCost, totalActive, costPerUser }) => {
  return (
    <div className="d-flex justify-content-start align-items-center p-3 work-performed-cards overflow-auto">
      <Card
        className="flex-grow-1 flex-shrink-0 mb-0 me-4"
        data-testid="total-cost-card"
      >
        <CardBody className="pb-3">
          <h1
            className="mb-0 text-center currency"
            data-testid="total-cost-value"
          >
            {sharedHelper.formatCurrency(totalCost)}
          </h1>
          <div className="mt-1 text-center small text-truncate">total cost</div>
        </CardBody>
      </Card>
      <Card className="flex-grow-1 mb-0 me-4" data-testid="active-users-card">
        <CardBody className="pb-3">
          <h1
            className="mb-0 text-center currency"
            data-testid="active-users-value"
          >
            {totalActive}
          </h1>
          <div className="mt-1 text-center small text-truncate">
            active users
          </div>
        </CardBody>
      </Card>
      <Card className="flex-grow-1 mb-0 me-4" data-testid="cost-per-user-card">
        <CardBody className="pb-3">
          <h1
            className="mb-0 text-center currency"
            data-testid="cost-per-user-value"
          >
            {sharedHelper.formatCurrency(costPerUser)}
          </h1>
          <div className="mt-1 text-center small text-truncate">
            cost per user
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const UsersBilling = () => {
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [informationModal, setInformationModal] = useState();

  const [monthStart, setMonthStart] = useState(moment().format("MMM/YY"));

  const {
    data: reportData,
    isLoading,
    get: getUsersBilling,
  } = useGetUsersBilling();

  useEffect(() => {
    getUsersBilling({
      search,
      monthStart,
      page,
      pageSize: sizePerPage,
    });
  }, [getUsersBilling, refresh, search, monthStart, page, sizePerPage]);

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [reportData, setData]);

  const onSeeDetails = (entry, month) => {
    const { activePeriods, activeDays, cost } = entry[month];
    const { userName } = entry;
    setInformationModal({
      isOpen: true,
      title: `User Cost Detail`,
      rawBody: true,
      bodyClass: "pb-4",
      body: (
        <ListGroup className="small">
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-graylight fw-bold"
            tag="div"
          >
            <span className="text-start">User</span>
            <span className="text-end">Period</span>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex flex-column justify-content-between align-items-start py-2"
            tag="div"
          >
            <div className="w-100 d-flex justify-content-between align-items-center">
              <span className="text-start">{userName}</span>
              <span className="text-end">{month}</span>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-graylight fw-bold"
            tag="div"
          >
            <span className="text-start">Cost</span>
            <span className="text-end">Active Days</span>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex flex-column justify-content-between align-items-start py-2"
            tag="div"
          >
            <div className="w-100 d-flex justify-content-between align-items-center">
              <span className="text-start">
                {sharedHelper.formatCurrency(cost)}
              </span>
              <span className="text-end">{activeDays}</span>
            </div>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-center align-items-center py-2 bg-graylight fw-bold"
            tag="div"
          >
            Active Periods
          </ListGroupItem>
          <ListGroupItem
            className="d-flex flex-column justify-content-between align-items-start py-2"
            tag="div"
          >
            {activePeriods.length ? (
              <Table
                className="col-12 px-0 mb-0 overflow-hidden my-1"
                striped
                size="small"
              >
                <thead>
                  <tr className="bg-graylight small">
                    <th className="text-start">Period</th>
                    <th className="text-center">Start</th>
                    <th className="text-end">End</th>
                  </tr>
                </thead>
                <tbody className="small">
                  {activePeriods
                    .sort((x, y) =>
                      moment(y.start).isBefore(moment(x.start) ? 1 : -1)
                    )
                    .map((period, index) => (
                      <tr key={period.start}>
                        <td className="text-start">#{index + 1}</td>
                        <td className="text-center">
                          {moment(period.start).format("YYYY/MM/DD")}
                        </td>
                        <td className="text-end">
                          {period.end
                            ? moment(period.end).format("YYYY/MM/DD")
                            : "-"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <span>No periods</span>
            )}
          </ListGroupItem>
        </ListGroup>
      ),
      onClose: () => setInformationModal(),
    });
  };

  const months = useMemo(
    () =>
      Array.from(Array(MONTHS_PERIOD).keys()).map((c) => {
        const month = moment(monthStart, "MMM/YY")
          .subtract(c, "month")
          .format("MMM/YY");
        return {
          accessor: month,
          header: month,
          headerProps: { className: "text-truncate" },
          cellProps: {
            className: "text-truncate",
          },
          type: "number",
          Cell: (rowData) => {
            if (!rowData.row[month]) {
              return "0.00";
            }
            const value = rowData.row[month].cost;
            const formattedValue = sharedHelper.formatCurrency(value);
            const hasDetail = value !== 0;
            return (
              <span
                onClick={() =>
                  hasDetail ? onSeeDetails(rowData.row, month) : null
                }
                data-value={formattedValue}
                className={hasDetail ? "text-link" : ""}
              >
                {formattedValue}
              </span>
            );
          },
        };
      }),
    [monthStart]
  );

  const columns = [
    {
      accessor: "userName",
      header: "User",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        return rowData.row.userName;
      },
    },
    ...months,
  ];

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Users Billing Report</h2>
            <small className="text-muted ms-2 pt-1">({data.count})</small>
          </div>
          <div className="d-flex align-items-center justify-content-end col-6">
            <MonthSelector
              className="me-3"
              loading={isLoading}
              monthStart={monthStart}
              setMonthStart={setMonthStart}
            />
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search users"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
          </div>
        </CardHeader>
        <Cards
          totalCost={data.totalCost || 0}
          totalActive={data.totalActive || 0}
          costPerUser={data.costPerUser || 0}
        />
        <CardBody className="section-body mt-0">
          <div className="overflow-x-auto">
            <AdvanceTable
              key={monthStart}
              exportable
              exportName="UsersBilling.csv"
              columns={columns}
              data={data.data || []}
              pageSize={sizePerPage}
              isLoading={isLoading}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "overflow-hidden w-100",
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={data.count}
            pageCount={data.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {informationModal ? <InformationModal {...informationModal} /> : null}
    </Container>
  );
};

export default UsersBilling;
