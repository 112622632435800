import { sharedHelper, data } from "@crewos/shared";
import React from "react";
import { SafetyDetailProvider } from "../../providers/safetyDetailProvider";

const { useAuth } = data;

const SafetyDetailWrapper = ({ children }) => {
  const [authContext] = useAuth();
  const isPackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "safety"
  );
  if (!isPackageEnabled) {
    return null;
  }

  return <SafetyDetailProvider>{children}</SafetyDetailProvider>;
};

export default SafetyDetailWrapper;
