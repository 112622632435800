import { sharedHelper, data } from "@crewos/shared";
import React from "react";
import { InspectionDetailProvider } from "../../providers/inspectionDetailProvider";

const { useAuth } = data;

const InspectionDetailWrapper = ({ children }) => {
  const [authContext] = useAuth();
  const isPackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "inspections"
  );
  if (!isPackageEnabled) {
    return null;
  }

  return <InspectionDetailProvider>{children}</InspectionDetailProvider>;
};

export default InspectionDetailWrapper;
