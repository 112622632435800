import { useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const serviceLocationEndpoint = `/api/user/{id}/service-location`;
const sidebarStatusEndpoint = `/api/user/{id}/sidebar-status`;

export const useUpdateServiceLocation = () => {
  return useApiMutation(HttpMethod.PUT, serviceLocationEndpoint);
};

export const useUpdateSidebarStatus = () => {
  return useApiMutation(HttpMethod.PUT, sidebarStatusEndpoint);
};
