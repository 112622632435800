import React from "react";

import { components } from "@crewos/shared";
import SettingsHeaderActions from "../../components/SettingsHeaderActions";

const { Settings } = components;

const WorkOrderSettings = () => {
  return <Settings pkg="workorders" HeaderActions={SettingsHeaderActions} />;
};

export default WorkOrderSettings;
