import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Row, Col } from "reactstrap";
import FormTemplateHeader from "./FormTemplateHeader";
import FormTemplateSections from "./FormTemplateSections";
import FormTemplateGroups from "./FormTemplateGroups";
import {
  useFormTemplate,
  useFormSections,
} from "../../providers/FormTemplateProvider";
import { relevantDiffDates } from "../../helpers/time";
import BlockNavigation from "../BlockNavigation";
import "../../assets/scss/formTemplate.scss";

const FormTemplate = ({
  getErrors,
  handleUpdateName,
  onSaveTemplate,
  onPublishTemplate,
  isLoadingSaveTemplate,
  isReadOnly,
  data,
  questionTypes,
  prefix,
  ConfigurationTemplateModal,
  isSuccessSaved,
  onSaveConfiguration,
}) => {
  const {
    state: {
      hasUnsavedChanges,
      template,
      activeTab,
      questionTypes: templateQuestionTypes,
    },
    getSections,
    initializeTemplate,
    setQuestionTypes,
    setUnsavedChanges,
    getFullTemplate,
  } = useFormTemplate();
  const { getGroups } = useFormSections();
  const sections = getSections();
  const groups = getGroups(activeTab);
  const [lastUpdatedTemplate, setLastUpdatedTemplate] = useState(null);

  useEffect(() => {
    if (questionTypes) {
      setQuestionTypes(questionTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTypes]);

  useEffect(() => {
    if (templateQuestionTypes) {
      initializeTemplate(data, prefix);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateQuestionTypes, data, prefix]);

  useEffect(() => {
    if (isSuccessSaved) {
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSaved]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdatedTemplate(relevantDiffDates(template.updatedAt, new Date()));
    }, 15000);
    return () => clearInterval(interval);
  }, [template]);

  const headerMessage = useMemo(() => {
    if (hasUnsavedChanges) {
      return "You have unsaved changes";
    }
    if (isLoadingSaveTemplate) {
      return "Saving...";
    }
    if (!hasUnsavedChanges && !isLoadingSaveTemplate && lastUpdatedTemplate) {
      return `Last updated ${lastUpdatedTemplate} ago`;
    }
    return "";
  }, [hasUnsavedChanges, isLoadingSaveTemplate, lastUpdatedTemplate]);

  const handleSaveTemplate = useCallback(async () => {
    const fullTemplate = await getFullTemplate({ verifyData: true });
    await onSaveTemplate({ ...fullTemplate });
  }, [getFullTemplate, onSaveTemplate]);

  const handlePublishTemplate = useCallback(async () => {
    const fullTemplate = await getFullTemplate({ verifyData: true });
    await onPublishTemplate({ ...fullTemplate });
  }, [getFullTemplate, onPublishTemplate]);

  return (
    <div className="form-template">
      <BlockNavigation
        isBlocked={hasUnsavedChanges && !isReadOnly}
        title="You have unsaved changes"
        content="There are edits and progress from your current session that might get lost."
        mainButtonText="Save Draft Template"
        secondaryButtonText="Continue Without Saving"
        onClickMainButton={handleSaveTemplate}
      />
      <FormTemplateHeader
        template={template}
        getErrors={getErrors}
        headerMessage={headerMessage}
        handleUpdateName={handleUpdateName}
        onSaveTemplate={handleSaveTemplate}
        onPublishTemplate={handlePublishTemplate}
        isLoadingSaveTemplate={isLoadingSaveTemplate}
        isReadOnly={isReadOnly}
      />
      <Row className="px-5">
        <Col xs={3} className="p-0">
          <FormTemplateSections
            isReadOnly={isReadOnly}
            sections={sections}
            ConfigurationTemplateModal={ConfigurationTemplateModal}
            onSaveConfiguration={onSaveConfiguration}
          />
        </Col>
        <Col xs={9} className="p-0 ps-4">
          <FormTemplateGroups isReadOnly={isReadOnly} groups={groups} />
        </Col>
      </Row>
    </div>
  );
};

export default FormTemplate;
