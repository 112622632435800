import React, { useCallback, useMemo } from "react";

import { sharedHelper, components } from "@crewos/shared";

import {
  SAFETY_DETAIL_ACTIONS,
  useSafetyDetail,
} from "../../providers/safetyDetailProvider";

const { Icon } = components;

const QuestionDetail = ({ question }) => {
  const { dispatch } = useSafetyDetail();

  const answers = useMemo(() => {
    return question.answers || [];
  }, [question]);

  const attachments = useMemo(() => {
    return question.attachments || [];
  }, [question]);

  const hasImages = useMemo(() => {
    return attachments.some((attachment) => attachment.type === "IMAGE");
  }, [attachments]);

  const hasNotes = useMemo(() => {
    return attachments.some((attachment) => attachment.type === "NOTE");
  }, [attachments]);

  const handlePreviewImages = useCallback(
    (attachment) => {
      const images = attachments
        .filter((attachment) => attachment.type === "IMAGE")
        .map((image) => ({
          id: image.id,
          title: image.description || "No description",
          url: image.content,
          selected: image.id === attachment.id,
        }));
      dispatch({
        type: SAFETY_DETAIL_ACTIONS.SET_ATTACHMENTS,
        payload: images,
      });
      dispatch({ type: SAFETY_DETAIL_ACTIONS.OPEN_PREVIEW_MODAL });
    },
    [attachments, dispatch]
  );

  return (
    <div className="m-3 box-shadow-card p-2 border-radius-md">
      <div className="d-flex px-2 justify-content-between">
        <div>
          <span>
            {question.order}. {question.content}
          </span>
        </div>
        <div className="d-flex">
          {hasImages && <Icon name="image" className="ms-2" />}
          {hasNotes && <Icon name="file-text" className="ms-2" />}
          {answers.length > 0 && (
            <Icon name="check-circle" className="ms-2 text-success" />
          )}
        </div>
      </div>
      <div>
        {answers.length > 0 &&
          answers.map((answer) => (
            <div key={answer.id} className="d-flex flex-column">
              <div className="fw-semibold px-2">
                {question.questionType.slug === "DATE_FIELD"
                  ? sharedHelper.formatDate(answer.content)
                  : answer.content}
                {!answer.content &&
                  answer.options &&
                  answer.options.length > 0 &&
                  answer.options.map((option) => (
                    <div key={option.id} className="d-flex flex-column">
                      <div className="fw-semibold">{option.content}</div>
                    </div>
                  ))}
                {!answer.content && !answer.options && "-"}
              </div>
            </div>
          ))}
        {answers.length === 0 && (
          <div className="text-muted px-2 d-flex gap-2 align-items-center">
            {question.isRequired && (
              <Icon name="asteriks" className="ms-2 text-warning" />
            )}
            Pending answer
          </div>
        )}
        {attachments
          .sort((a, b) => {
            if (a.type === "IMAGE" && b.type === "NOTE") {
              return -1;
            }
            if (a.type === "NOTE" && b.type === "IMAGE") {
              return 1;
            }
            return 0;
          })
          .map((attachment) => (
            <div
              key={attachment.id}
              className="p-2 bg-light my-1 border-radius-md d-flex gap-3"
            >
              {attachment.type === "IMAGE" && (
                <>
                  <img
                    src={attachment.content}
                    alt={attachment.name}
                    width="80"
                    height="80"
                    className="border-radius-md cursor-pointer"
                    onClick={() => handlePreviewImages(attachment)}
                  />
                  <p>{attachment.description}</p>
                </>
              )}
              {attachment.type === "NOTE" && <span>{attachment.content}</span>}
            </div>
          ))}
      </div>
    </div>
  );
};

export default QuestionDetail;
