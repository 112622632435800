import ACTIONS from "../actions/questions.actions";

const questionsReducer = (state, action) => {
  const errors = { ...state.errors };
  switch (action.type) {
    case ACTIONS.UPDATE_QUESTION_NAME:
      if (
        action.payload.content === state.questions[action.payload.id].content
      ) {
        return state;
      }
      delete errors[action.payload.id];
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          [action.payload.id]: {
            ...state.questions[action.payload.id],
            content: action.payload.content,
            status: action.payload.status,
          },
        },
        errors,
      };
    case ACTIONS.UPDATE_QUESTIONS_ORDER:
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          ...action.payload,
        },
      };
    case ACTIONS.ADD_QUESTION:
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          [action.payload.id]: action.payload,
        },
      };
    case ACTIONS.DELETE_QUESTION:
      delete errors[action.payload.id];
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: action.payload,
        errors,
      };
    case ACTIONS.COPY_QUESTION:
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          [action.payload.id]: action.payload,
        },
      };
    case ACTIONS.UPDATE_QUESTION_TYPE:
      delete errors[action.payload.id];
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          [action.payload.id]: {
            ...state.questions[action.payload.id],
            questionType: action.payload.questionType,
            status: action.payload.status,
            questionTypeId: action.payload.questionType.id,
          },
        },
        errors,
      };
    case ACTIONS.UPDATE_QUESTION_REQUIRED:
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          [action.payload.id]: {
            ...state.questions[action.payload.id],
            isRequired: action.payload.isRequired,
            status: action.payload.status,
          },
        },
      };
    case ACTIONS.ADD_GUIDANCE:
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            guidance: action.payload.guidance,
          },
        },
      };
    case ACTIONS.DELETE_GUIDANCE:
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            guidance: action.payload.guidance,
          },
        },
      };
    case ACTIONS.UPDATE_QUESTIONS:
      return {
        ...state,
        hasUnsavedChanges: true,
        questions: action.payload,
      };
    default:
      return state;
  }
};

export default questionsReducer;
