import { useCallback, useState } from "react";
import { Api, apiHelper } from "./Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sharedHelper } from "../helpers/sharedHelper";

export const toastConfig = {
  toastId: "loading",
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const handleError = (err, navigate) => {
  if (err?.response?.status === 401) {
    if (!window.location.href.includes("auth/sign-in")) {
      window.location.href = `/auth/sign-in?return-url=${window.location.pathname}`;
    }
  } else if (err?.response?.status === 404) {
    navigate("/error/404");
  } else {
    sharedHelper.errorToast(err);
  }
};

export const useApiGet = (path) => {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const get = useCallback(
    async (params) => {
      setIsLoading(true);
      setError(null);
      await toast.promise(
        async () => {
          try {
            const response = await Api.get(path, params);
            setData(response.data);
          } catch (error) {
            setIsLoading(false);
            setError(error);
            handleError(error, navigate);
          }
        },
        {
          pending: "Loading..",
        },
        toastConfig
      );
      setIsLoading(false);
    },
    [path, setData, setIsLoading, navigate]
  );

  return { data, error, isLoading, get };
};

export function useApiMutation(method, path) {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const mutate = useCallback(
    async (params, config = {}) => {
      setIsLoading(true);
      setError(null);
      const finalPath = Object.keys(config.pathParams || {}).reduce(
        (acc, key) => {
          return acc.replace(`{${key}}`, config.pathParams[key]);
        },
        path
      );
      await toast.promise(
        async () => {
          try {
            const response = await apiHelper(method, finalPath, params);
            setData(response);
          } catch (error) {
            setIsLoading(false);
            setError(error);
            handleError(error, navigate);
          }
        },
        {
          pending: "Loading..",
        },
        toastConfig
      );
      setIsLoading(false);
    },
    [method, path, setData, setIsLoading, navigate, setError]
  );

  return { data, error, isLoading, mutate };
}
