import React, { useState, useCallback, useMemo, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  ModalFooter,
  Button,
} from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

const { TooltipItem, InputEmail, Icon, Select } = components;
const { useAuth } = data;

const SIGN_OFF = {
  NOT_NEEDED: "NOT_NEEDED",
  ONLY_CREW_LEADER: "ONLY_CREW_LEADER",
  ALL_CREW: "ALL_CREW",
};

const SIGN_OFF_OPTIONS = [
  { value: SIGN_OFF.NOT_NEEDED, label: "Not needed" },
  { value: SIGN_OFF.ONLY_CREW_LEADER, label: "Only Crew Leader" },
  { value: SIGN_OFF.ALL_CREW, label: "All Crew" },
];

const ConfigurationModal = ({
  isOpen,
  onClose,
  title,
  onSave,
  configuration,
}) => {
  const [authContext] = useAuth();

  const [isRequired, setIsRequired] = useState(
    configuration?.isRequired || true
  );
  const [signOff, setSignOff] = useState(SIGN_OFF.NOT_NEEDED);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [emailNotificationsRecipients, setEmailNotificationsRecipients] =
    useState([]);

  const isSettingEmailNotificationsEnabled = useMemo(() => {
    return sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      "SAFETY_EMAIL_NOTIFICATION_ENABLED"
    );
  }, [authContext.userData?.packages]);

  const settingRequestSignOffValue = useMemo(() => {
    return sharedHelper.getSettingValue(
      authContext.userData?.packages,
      "safety",
      "SAFETY_SIGNATURE_CAN_BE_CHANGED"
    );
  }, [authContext.userData?.packages]);

  const isSettingRequestSignOffEnabled = useMemo(() => {
    return sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      "SAFETY_SIGNATURE_CAN_BE_CHANGED"
    );
  }, [authContext.userData?.packages]);

  const handleTemplateRequiredChange = useCallback((value) => {
    setIsRequired((prev) => !prev);
  }, []);

  const handleSignOffChange = useCallback((record) => {
    setSignOff(record);
  }, []);

  const handleEmailNotificationsChange = useCallback((value) => {
    setEmailNotifications((prev) => !prev);
  }, []);

  const handleEmailNotificationsRecipientsChange = useCallback((value) => {
    setEmailNotificationsRecipients(value);
  }, []);

  const handleSave = useCallback(() => {
    const data = {
      ...configuration,
      isRequired,
      signatureRequest: signOff?.value,
      isEmailNotificationEnabled: emailNotifications,
      emailRecipients: emailNotificationsRecipients,
    };
    onSave(data);
  }, [
    isRequired,
    signOff,
    emailNotifications,
    emailNotificationsRecipients,
    onSave,
    configuration,
  ]);

  useEffect(() => {
    setEmailNotifications(isSettingEmailNotificationsEnabled);
  }, [isSettingEmailNotificationsEnabled]);

  useEffect(() => {
    const signOffOption = SIGN_OFF_OPTIONS.find(
      (option) => option.value === settingRequestSignOffValue
    );
    setSignOff(signOffOption);
  }, [settingRequestSignOffValue]);

  useEffect(() => {
    if (configuration) {
      setIsRequired(configuration?.isRequired);
      if (configuration?.signatureRequest) {
        setSignOff(
          SIGN_OFF_OPTIONS.find(
            (option) => option.value === configuration?.signatureRequest
          )
        );
      }
      setEmailNotifications(configuration?.isEmailNotificationEnabled);
      setEmailNotificationsRecipients(configuration?.emailRecipients);
    }
  }, [configuration]);

  useEffect(() => {
    if (!configuration?.id) {
      handleSave();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={onClose} className="border-bottom-0 text-lg">
        {title || "Configurations"}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between my-3 border-bottom pb-3">
          <div className="d-flex align-items-center gap-2">
            Template required for every work order
            <TooltipItem title="If this setting is ON, the Template will be automatically assigned to all the Work Orders, and will be marked as 'Required' for each Work Day of the WO.">
              <Icon
                name="info"
                className="text-primary"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          </div>
          <div>
            <FormGroup switch>
              <Input
                type="switch"
                role="switch"
                id="templateRequired"
                name="templateRequired"
                data-testid="templateRequired"
                checked={isRequired}
                onChange={handleTemplateRequiredChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="d-flex justify-content-between my-3 border-bottom pb-3">
          <div className="col-7 d-flex align-items-center">
            Request sign off
          </div>
          <div className="col-5">
            <Select
              options={SIGN_OFF_OPTIONS}
              value={signOff}
              onChange={handleSignOffChange}
              dataTestId="signOff"
              isDisabled={!isSettingRequestSignOffEnabled}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between my-3">
          <div className="d-flex align-items-center gap-2">
            Email notifications
          </div>
          <div>
            <FormGroup switch>
              <Input
                type="switch"
                role="switch"
                id="emailNotifications"
                name="emailNotifications"
                data-testid="emailNotifications"
                checked={emailNotifications}
                onChange={handleEmailNotificationsChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="d-flex justify-content-between ps-4">
          <div className="col-4">Send to</div>
          <div className="col-8">
            <InputEmail
              id="emailNotificationsRecipients"
              name="emailNotificationsRecipients"
              data-testid="emailNotificationsRecipients"
              defaultValue={emailNotificationsRecipients}
              onChange={handleEmailNotificationsRecipientsChange}
              disabled={!emailNotifications}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between mt-4">
        <Button color="secondary" onClick={onClose}>
          Discard
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfigurationModal;
