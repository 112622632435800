import { useGetCustomers } from "./api/Customers.hooks";

import { data, helpers, sharedHelper, components } from "@crewos/shared";

import Customers from "./pages/Customers";

import CustomerDetails from "./pages/CustomerDetails";
import AssetTypes from "./pages/AssetTypes";
import Assets from "./pages/Assets";

const { Settings } = components;

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const CUSTOMER = "CUSTOMER";

// Routes
const routes = [
  {
    icon: "message-circle",
    sidebar: true,
    name: "Customers",
    path: "/customers",
    package: "customers",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    entries: [
      {
        path: `all`,
        name: "All Customers",
        sidebar: true,
        component: Customers,
        scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
      },
      {
        path: `assets`,
        name: "Assets",
        sidebar: true,
        scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
        package: "customers",
        component: Assets,
      },
    ],
  },
  {
    path: `/customers/:customerId`,
    name: "Customers Details",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    package: "customers",
    component: CustomerDetails,
  },
  {
    path: `/customers/:customerId/locations/:locationId/assets`,
    name: "Location Assets",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    package: "customers",
    component: Assets,
  },
  {
    sidebar: true,
    icon: null,
    name: "Customers Settings",
    path: `customers`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "customers",
    category: "Customers",
    isSetting: true,
    component: Settings,
    order: 1,
  },
  {
    sidebar: true,
    icon: null,
    name: "Asset Types",
    path: `asset-types`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "customers",
    category: "Assets",
    isSetting: true,
    component: AssetTypes,
    order: 2,
  },
];

const setCustomerSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "customers");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: CUSTOMER, order: 6 },
    });
  }
};

const Routes = createRoutes(routes);

export { Routes as CustomerRoutes, setCustomerSidebarRoutes, useGetCustomers };
