import ACTIONS from "../actions";

const templateReducer = (state, action) => {
  const errors = { ...state.errors };
  switch (action.type) {
    case ACTIONS.UPDATE_TEMPLATE_NAME:
      delete errors[state.template.id];
      return {
        ...state,
        template: {
          ...state.template,
          name: action.payload,
        },
        hasUnsavedChanges: true,
        errors,
      };
    case ACTIONS.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case ACTIONS.INITIALIZE_TEMPLATE:
      return {
        ...state,
        ...action.payload,
        hasUnsavedChanges: false,
      };
    case ACTIONS.SET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.payload,
      };
    case ACTIONS.SET_CONFIGURATION:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...action.payload,
        },
        hasUnsavedChanges: true,
      };
    case ACTIONS.SET_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.payload,
      };
    default:
      return state;
  }
};

export default templateReducer;
