import React, { useEffect } from "react";
import { components, data } from "@crewos/shared";
import { useParams } from "react-router-dom";
import {
  useGetQuestionTypes,
  useGetInspectionsTemplates,
} from "../../api/Inspections.hooks";

const { FormTemplate, Loader } = components;
const { FormTemplateProvider } = data;

const ViewTemplates = () => {
  const { id: templateId } = useParams();

  const {
    data: questionTypes,
    get: getQuestionTypes,
    isLoading: isQuestionTypesLoading,
  } = useGetQuestionTypes();

  const {
    data: inspectionsTemplates,
    get: getInspectionsTemplates,
    isLoading: isInspectionsTemplatesLoading,
  } = useGetInspectionsTemplates();

  useEffect(() => {
    getQuestionTypes();
    if (templateId) {
      getInspectionsTemplates({ id: templateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isQuestionTypesLoading || isInspectionsTemplatesLoading) {
    return <Loader />;
  }

  return (
    <FormTemplateProvider>
      <FormTemplate
        isReadOnly
        data={inspectionsTemplates}
        questionTypes={questionTypes}
        prefix="inspection"
      />
    </FormTemplateProvider>
  );
};

export default ViewTemplates;
