import React, { useEffect, useMemo } from "react";

import { useAuth, ACTIONS } from "../../providers/authProvider";
import { useUpdateServiceLocation } from "../../api/Sidebar.hooks";

import Select from "../Select";

const allServiceLocations = {
  label: "All Locations",
  value: "",
};

const SidebarServiceLocation = () => {
  const [authContext, setAuthContext] = useAuth();

  const {
    isLoading: isLoadingUpdateServiceLocation,
    mutate: updateServiceLocation,
    data: updateServiceLocationData,
  } = useUpdateServiceLocation();

  useEffect(() => {
    if (updateServiceLocationData) {
      setAuthContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [updateServiceLocationData, setAuthContext]);

  const serviceLocationsOptions = useMemo(() => {
    if (authContext.userData) {
      return [
        allServiceLocations,
        ...authContext.userData.serviceLocations.map((sl) => ({
          value: sl.id,
          label: sl.name,
        })),
      ];
    }
    return [allServiceLocations];
  }, [authContext.userData]);

  const selectedServiceLocation = useMemo(() => {
    if (serviceLocationsOptions && authContext.userData?.serviceLocationId) {
      const serviceLocation = serviceLocationsOptions.find(
        (slo) => slo.value === authContext.userData.serviceLocationId
      );
      return serviceLocation;
    }
    return allServiceLocations;
  }, [authContext.userData, serviceLocationsOptions]);

  const onServiceLocationChange = async (selected) => {
    updateServiceLocation(
      {
        serviceLocationId: selected.value,
      },
      { pathParams: { id: authContext.userData.id } }
    );
  };

  return (
    <div className="ps-sidebar-element d-flex justify-content-center align-items-center">
      <Select
        isLoading={isLoadingUpdateServiceLocation}
        name="slSelect"
        onChange={onServiceLocationChange}
        value={selectedServiceLocation}
        placeholder="Service location"
        options={serviceLocationsOptions}
        data-testid="service-locations-select"
        className="w-100"
        required
      />
    </div>
  );
};

export default SidebarServiceLocation;
