import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardText } from "reactstrap";
import { sharedHelper, components } from "@crewos/shared";

import CustomerLocationModal from "../CustomerLocationModal";
import CustomerLocationContactsModal from "../CustomerLocationContactsModal";
import { useDeleteCustomerLocation } from "../../api/Customers.hooks";
import { useNavigate } from "react-router";

const { ConfirmationModal, Icon } = components;

const CustomerLocations = ({ customer, setRefresh }) => {
  const navigate = useNavigate();

  const [editModal, setEditModal] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [customerLocationContactsModal, setCustomerLocationContactsModal] =
    useState(null);

  const { data: deleteCustomerLocationData, deleteCustomerLocation } =
    useDeleteCustomerLocation();

  useEffect(() => {
    if (deleteCustomerLocationData) {
      sharedHelper.successToast("Customer location deleted");
      setRefresh((prev) => !prev);
    }
  }, [deleteCustomerLocationData, setRefresh]);

  const handleEdit = (location) => {
    setEditModal(location);
  };

  const handleDelete = (location) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteCustomerLocation(location.id);
        setConfirmationModal(null);
      },
      onClose: () => {
        setConfirmationModal(null);
        setRefresh((prev) => !prev);
      },
      title: "Delete Customer Location",
      body: `Are you sure you want to delete ${sharedHelper.getAddress(
        location
      )}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  return (
    <div className="w-100 px-2">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="fw-bold mb-0">Customer Locations</h2>
        <Button color="primary" onClick={() => setCreateModal(true)}>
          Create
        </Button>
      </div>

      {!customer.locations?.length && (
        <div className="text-muted small border rounded p-3">No locations</div>
      )}

      <div className="row">
        {customer.locations?.map((location, index) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3" key={index}>
            <Card className="h-100">
              <CardHeader className="py-2 d-flex justify-content-between align-items-center">
                <h6 className="mb-0 fw-bold">
                  {location.name || `Location ${index + 1}`}
                </h6>
                <div>
                  <Button
                    data-testid="edit-button"
                    color="link"
                    size="sm"
                    className="text-primary"
                    onClick={() => handleEdit(location)}
                  >
                    <Icon name="edit-2" className="fs-5" />
                  </Button>
                  <Button
                    data-testid="delete-button"
                    color="link"
                    size="sm"
                    className="text-danger"
                    onClick={() => handleDelete(location)}
                  >
                    <Icon name="trash" className="fs-5" />
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column pt-0">
                <CardText className="flex-grow-1 d-flex flex-column mb-0 small">
                  {sharedHelper.getAddress(location, true).map((item, idx) => (
                    <span key={idx}>{item}</span>
                  ))}
                </CardText>
                {/* etc... */}
              </CardBody>
            </Card>
          </div>
        ))}
      </div>

      {/* MODALS */}
      {editModal && (
        <CustomerLocationModal
          customerLocation={editModal}
          onClose={() => setEditModal(null)}
          onSubmit={() => {
            setEditModal(null);
            setRefresh((prev) => !prev);
          }}
        />
      )}
      {createModal && (
        <CustomerLocationModal
          customerLocation={{ customerId: customer.id, isActive: true }}
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      )}
      {confirmationModal && <ConfirmationModal {...confirmationModal} />}
      {customerLocationContactsModal && (
        <CustomerLocationContactsModal
          customerLocation={customerLocationContactsModal}
          onClose={() => setCustomerLocationContactsModal(null)}
          onSubmit={() => {
            setCustomerLocationContactsModal(null);
            setRefresh((prev) => !prev);
            sharedHelper.successToast("Location customer contacts saved");
          }}
        />
      )}
    </div>
  );
};

export default CustomerLocations;
