import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
} from "reactstrap";
import Icon from "../Icon";
import React, { useCallback, useState } from "react";
import DeleteModal from "../DeleteModal";
import RestrictEditTemplateModal from "../RestrictEditTemplateModal";
import RestrictDeleteTemplateModal from "../RestrictDeleteTemplateModal";

const ListTemplatesItem = ({
  template,
  onDeleteItem,
  onCopyItem,
  onEdit,
  fieldToRestrict,
  onView,
  fieldToRestrictLabel,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRestrictEditModalOpen, setIsRestrictEditModalOpen] = useState(false);
  const [isRestrictDeleteModalOpen, setIsRestrictDeleteModalOpen] =
    useState(false);

  const handleCopyItem = useCallback(() => {
    onCopyItem(template.id);
  }, [onCopyItem, template.id]);

  const handleEdit = useCallback(() => {
    if (fieldToRestrict && template[fieldToRestrict] > 0) {
      setIsRestrictEditModalOpen((prev) => !prev);
    } else {
      onEdit(template.id);
    }
  }, [onEdit, fieldToRestrict, template]);

  const handleOpenDeleteModal = useCallback(() => {
    if (fieldToRestrict && template[fieldToRestrict] > 0) {
      setIsRestrictDeleteModalOpen((prev) => !prev);
    } else {
      setIsDeleteModalOpen((prev) => !prev);
    }
  }, [fieldToRestrict, template]);

  const handleDelete = useCallback(() => {
    if (fieldToRestrict && template[fieldToRestrict] > 0) {
      setIsRestrictDeleteModalOpen((prev) => !prev);
    } else {
      setIsDeleteModalOpen((prev) => !prev);
      onDeleteItem(template.id);
    }
  }, [fieldToRestrict, template, onDeleteItem]);

  return (
    <Col className="d-flex flex-column">
      <Card key={template.id} className="p-2 flex-grow-1">
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4 className="text-truncate mb-0">{template.name}</h4>
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button border-0"
              color="white"
              onClick={handleCopyItem}
              aria-label={`Copy ${template.name}`}
            >
              <Icon name="copy" />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button text-danger border-0"
              color="white"
              onClick={handleOpenDeleteModal}
              aria-label={`Delete ${template.name}`}
            >
              <Icon name="trash" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="text-black-50">
          <span>{template.totalSections} sections, </span>
          <span>{template.totalQuestions} questions, </span>
          <span>
            {template[fieldToRestrict]} {fieldToRestrictLabel}
          </span>
        </CardBody>
        <CardFooter>
          <Button
            color="secondary"
            className="w-100 rounded-4 py-2"
            onClick={handleEdit}
          >
            {template.status === "PUBLISHED" ? "Edit" : "Continue"}
          </Button>
        </CardFooter>
      </Card>
      <DeleteModal
        title="Delete Template"
        name={template.name}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen((prev) => !prev)}
        onDelete={handleDelete}
      />
      <RestrictEditTemplateModal
        isOpen={isRestrictEditModalOpen}
        onClose={() => setIsRestrictEditModalOpen((prev) => !prev)}
        template={template}
        fieldToRestrict={fieldToRestrict}
        onView={() => onView(template.id)}
        onDuplicate={handleCopyItem}
      />
      <RestrictDeleteTemplateModal
        isOpen={isRestrictDeleteModalOpen}
        onClose={() => setIsRestrictDeleteModalOpen((prev) => !prev)}
        template={template}
        onAccept={() => setIsRestrictDeleteModalOpen((prev) => !prev)}
        fieldToRestrict={fieldToRestrict}
      />
    </Col>
  );
};

export default ListTemplatesItem;
