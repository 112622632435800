import React, { useCallback } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDeleteSafety } from "../../api/Safety.hooks";

const DeleteSafetyModal = ({ safetyId, onClose, isOpen, onDelete }) => {
  const { mutate: deleteSafety, isLoading: isLoadingDeleteSafety } =
    useDeleteSafety();

  const handleDelete = useCallback(async () => {
    await deleteSafety({ id: safetyId });
    onDelete();
  }, [safetyId, deleteSafety, onDelete]);

  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader toggle={onClose}>Delete Safety</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to delete this safety?</p>
      </ModalBody>
      <ModalFooter className="justify-content-between flex-row flex-nowrap">
        <Button
          color="secondary"
          onClick={onClose}
          className="w-100"
          disabled={isLoadingDeleteSafety}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={handleDelete}
          className="w-100"
          disabled={isLoadingDeleteSafety}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteSafetyModal;
