import React, { useState, useCallback, useMemo } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import { useCrewWorkDays } from "@crewos/crews";
import { components, data } from "@crewos/shared";
import AssignTemplateWOModal from "../workOrderAssignedTemplates/AssignTemplateWOModal";
import AllInspections from "./AllInspections";

const { WorkOrderDateSelector, Select, Icon } = components;
const { useWorkOrderDetails } = data;

const WorkOrderInspectionsList = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();
  const [search, setSearch] = useState();
  const [viewPerDay, setViewPerDay] = useState(true);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(0);

  const handleSearch = useCallback(($event) => {
    setSearch($event.target.value);
  }, []);

  const handleViewPerDay = useCallback((e) => {
    setViewPerDay(e.value);
  }, []);

  const handleAssignTemplate = useCallback(() => {
    setShowAssignModal(false);
    setNeedsUpdate((prev) => prev + 1);
  }, []);

  const crewWorkDays = useMemo(() => {
    return (
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate] || []
    );
  }, [crewWorkDaysContext, workOrderDetails]);

  const crewWorkDaysWithInspections = useMemo(() => {
    return crewWorkDays.filter((crewWorkDay) => crewWorkDay.inspections > 0);
  }, [crewWorkDays]);

  const viewPerDaySelect = useMemo(() => {
    return [
      { label: "View per day", value: true },
      { label: "View all work days", value: false },
    ];
  }, []);

  const defaultViewPerDay = useMemo(() => {
    return viewPerDaySelect.find((option) => option.value === viewPerDay);
  }, [viewPerDay, viewPerDaySelect]);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h2 className="mb-0 col-6">Inspections</h2>
              <div className="col-6 d-flex align-items-center">
                <Select
                  id="view-per-day"
                  name="view-per-day"
                  value={defaultViewPerDay}
                  onChange={handleViewPerDay}
                  options={viewPerDaySelect}
                />
                <DebounceInput
                  className="mx-3 search form-control"
                  maxLength={50}
                  minLength={1}
                  debounceTimeout={900}
                  placeholder="Search something"
                  value={search}
                  onChange={handleSearch}
                />
                {!viewPerDay ? (
                  <Button
                    size="sm"
                    className="rounded-circle d-flex custom-rounded-button text-primary py-2"
                    color="white"
                    data-testid="refresh-button"
                    onClick={() => setNeedsUpdate((prev) => prev + 1)}
                  >
                    <Icon name="refresh-cw" />
                  </Button>
                ) : null}
              </div>
            </div>
            {viewPerDay && (
              <WorkOrderDateSelector
                crewWorkDays={crewWorkDaysContext.crewWorkDays}
              />
            )}
          </CardBody>
        </Card>
        {!viewPerDay ? (
          <AllInspections
            workOrder={workOrderDetails.workOrder}
            isViewPerDay={viewPerDay}
            needsUpdate={needsUpdate}
            search={search}
            crewWorkDays={[]}
          />
        ) : !crewWorkDays.length || !crewWorkDaysWithInspections.length ? (
          <Card className="w-100 px-0 box-shadow-none mb-3">
            <CardBody className="py-3">
              <div className="text-center text-muted small">
                No data to display
              </div>
            </CardBody>
          </Card>
        ) : (
          <AllInspections
            workOrder={workOrderDetails.workOrder}
            crewWorkDays={crewWorkDays}
            isViewPerDay={viewPerDay}
            needsUpdate={needsUpdate}
            search={search}
          />
        )}
      </Row>
      {showAssignModal && (
        <AssignTemplateWOModal
          isOpen={showAssignModal}
          workOrder={workOrderDetails.workOrder}
          onAssign={handleAssignTemplate}
          onClose={() => setShowAssignModal(false)}
        />
      )}
    </Col>
  );
};

export default WorkOrderInspectionsList;
