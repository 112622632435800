import React from "react";

import CurrencyInput from "react-currency-input-field";

const DynamicAttributeInput = ({
  dynamicAttribute,
  data,
  setData,
  className = "form-control-redesign",
}) => {
  if (!data.dynamicAttributes) {
    data.dynamicAttributes = {};
  } else if (typeof data.dynamicAttributes === "string") {
    data.dynamicAttributes = JSON.parse(data.dynamicAttributes);
  }
  if (data.dynamicAttributes[dynamicAttribute.key] === "null") {
    data.dynamicAttributes[dynamicAttribute.key] = null;
  }
  return (
    <div>
      {dynamicAttribute.type === "TEXT" ? (
        <input
          data-testid={dynamicAttribute.key}
          readOnly={dynamicAttribute.readOnly}
          className={className}
          required={dynamicAttribute.required}
          id={dynamicAttribute.key}
          name={dynamicAttribute.key}
          placeholder={
            dynamicAttribute.placeholder || `Enter a ${dynamicAttribute.label}`
          }
          maxLength={dynamicAttribute.maxLength}
          minLength={dynamicAttribute.minLength || 0}
          value={data.dynamicAttributes[dynamicAttribute.key] || ""}
          type={dynamicAttribute.type}
          onChange={(e) => {
            data.dynamicAttributes[dynamicAttribute.key] =
              e.target.value || null;
            setData({
              ...data,
            });
          }}
        />
      ) : dynamicAttribute.type === "NUMBER" ? (
        <input
          data-testid={dynamicAttribute.key}
          readOnly={dynamicAttribute.readOnly}
          className={className}
          required={dynamicAttribute.required}
          id={dynamicAttribute.key}
          name={dynamicAttribute.key}
          placeholder={
            dynamicAttribute.placeholder || `Enter a ${dynamicAttribute.label}`
          }
          max={dynamicAttribute.max}
          min={dynamicAttribute.min || 0}
          value={data.dynamicAttributes[dynamicAttribute.key] || ""}
          step={dynamicAttribute.step}
          type={dynamicAttribute.type}
          onChange={(e) => {
            data.dynamicAttributes[dynamicAttribute.key] =
              e.target.value || null;
            setData({
              ...data,
            });
          }}
        />
      ) : dynamicAttribute.type === "CURRENCY" ? (
        <CurrencyInput
          data-testid={dynamicAttribute.key}
          readOnly={dynamicAttribute.readOnly}
          className={className}
          required={dynamicAttribute.required}
          id={dynamicAttribute.key}
          name={dynamicAttribute.key}
          prefix="$"
          decimalsLimit={2}
          max={dynamicAttribute.max}
          allowNegativeValue={dynamicAttribute.allowNegativeValue}
          placeholder={
            dynamicAttribute.placeholder || `Enter a ${dynamicAttribute.label}`
          }
          value={data.dynamicAttributes[dynamicAttribute.key] || ""}
          onValueChange={(value) => {
            data.dynamicAttributes[dynamicAttribute.key] = value
              ? parseFloat(value)
              : null;
            setData({
              ...data,
            });
          }}
        />
      ) : (
        <span>{dynamicAttribute.type} not implemented</span>
      )}
    </div>
  );
};

export default DynamicAttributeInput;
