import React, { useEffect, useMemo, useState } from "react";

import {
  Input,
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import { sharedHelper, components, data } from "@crewos/shared";

import { useGetAllAssetTypes } from "../api/AssetTypes.hooks";
import {
  useCreateAsset,
  useGetAllAssets,
  useUpdateAsset,
} from "../api/Assets.hooks";

const { useGetAllDynamicAttributes } = data;

const { DynamicAttributeInput, DynamicAttributeLabel, Select, Loader } =
  components;

const AssetModal = ({ defaultAsset, onClose, onSubmit }) => {
  const [assetTypes, setAssetTypes] = useState([]);
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState(defaultAsset);
  const [dynamicAttributes, setDynamicAttributes] = useState([]);

  const { get: getDynamicAttributes, data: dynamicAttributesData } =
    useGetAllDynamicAttributes();

  const { get: getAssetTypes, data: assetTypesData } = useGetAllAssetTypes();

  const { get: getAssets, data: assetsData } = useGetAllAssets();

  useEffect(() => {
    getDynamicAttributes({ entity: "assetType" });
  }, [getDynamicAttributes]);

  useEffect(() => {
    if (dynamicAttributesData) {
      setDynamicAttributes(dynamicAttributesData);
    }
  }, [dynamicAttributesData]);

  useEffect(() => {
    getAssetTypes();
  }, [getAssetTypes]);

  useEffect(() => {
    const params = {};
    if (defaultAsset.customerLocationId) {
      params.customerLocationId = defaultAsset.customerLocationId;
    }
    getAssets(params);
  }, [getAssets, defaultAsset.customerLocationId]);

  useEffect(() => {
    if (assetTypesData) {
      setAssetTypes(assetTypesData);
    }
  }, [assetTypesData]);

  useEffect(() => {
    if (assetsData) {
      setAssets(assetsData);
    }
  }, [assetsData]);

  // Hooks for creating and updating assets
  const {
    isLoading: isLoadingCreateAsset,
    mutate: createAsset,
    data: createAssetData,
  } = useCreateAsset();

  const {
    isLoading: isLoadingUpdateAsset,
    update: updateAsset,
    data: updateAssetData,
  } = useUpdateAsset();

  // Effect to handle successful creation
  useEffect(() => {
    if (createAssetData) {
      sharedHelper.successToast(`Asset created`);
      onSubmit();
    }
  }, [createAssetData, onSubmit]);

  // Effect to handle successful update
  useEffect(() => {
    if (updateAssetData) {
      sharedHelper.successToast(`Asset saved`);
      onSubmit();
    }
  }, [updateAssetData, onSubmit]);

  // Handler for form submission
  const doSubmit = async (event) => {
    event.preventDefault();
    if (asset.id) {
      await updateAsset({
        ...asset,
      });
    } else {
      await createAsset({ ...asset });
    }
  };

  const assetTypeSelect = useMemo(
    () =>
      assetTypes.map((at) => ({
        value: at.id,
        label: at.name,
      })),
    [assetTypes]
  );

  const defaultAssetType = useMemo(
    () => assetTypeSelect.find((option) => option.value === asset.assetTypeId),
    [assetTypeSelect, asset.assetTypeId]
  );

  const assetParentSelect = useMemo(
    () =>
      assets
        .filter((a) => a.id !== asset.id)
        .map((a) => ({
          value: a.id,
          label: a.name,
        })),
    [assets, asset.id]
  );

  const defaultAssetParent = useMemo(
    () =>
      assetParentSelect.find(
        (option) => option.value && option.value === asset.assetParentId
      ),
    [assetParentSelect, asset.assetParentId]
  );

  return (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          toggle={onClose}
          className="d-flex justify-content-between"
        >
          {asset.id ? "Edit" : "Add"} Asset
        </ModalHeader>
        <ModalBody className="text-center">
          {isLoadingCreateAsset || isLoadingUpdateAsset ? (
            <div className="w-100 d-flex justify-content-center">
              <Loader size="sm" className="my-3" />
            </div>
          ) : (
            <>
              <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
                <thead>
                  <tr className="bg-graylight small text-muted">
                    <th>
                      <span>Name</span>
                      <span className="text-danger ms-1">*</span>
                    </th>
                    <th>
                      <span>Model #</span>
                      <span className="text-danger ms-1">*</span>
                    </th>
                    <th>
                      <span>Serial #</span>
                      <span className="text-danger ms-1">*</span>
                    </th>
                    <th>
                      <span>Manufacturer</span>
                      <span className="text-danger ms-1">*</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        className="border-0"
                        required={true}
                        placeholder="Enter a name"
                        value={asset.name}
                        onChange={(event) =>
                          setAsset({
                            ...asset,
                            name: event.currentTarget.value,
                          })
                        }
                        maxLength={50}
                      />
                    </td>
                    <td>
                      <Input
                        className="border-0"
                        required={true}
                        placeholder="Enter a model #"
                        value={asset.modelNumber}
                        onChange={(event) =>
                          setAsset({
                            ...asset,
                            modelNumber: event.currentTarget.value,
                          })
                        }
                        maxLength={50}
                      />
                    </td>
                    <td>
                      <Input
                        className="border-0"
                        required={true}
                        placeholder="Enter a serial #"
                        value={asset.serialNumber}
                        onChange={(event) =>
                          setAsset({
                            ...asset,
                            serialNumber: event.currentTarget.value,
                          })
                        }
                        maxLength={50}
                      />
                    </td>
                    <td>
                      <Input
                        className="border-0"
                        required={true}
                        placeholder="Enter a manufacturer"
                        value={asset.manufacturer}
                        onChange={(event) =>
                          setAsset({
                            ...asset,
                            manufacturer: event.currentTarget.value,
                          })
                        }
                        maxLength={50}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table className="col-12 px-0 mb-0 table-layout-fixed" striped>
                <thead>
                  <tr className="bg-graylight small text-muted">
                    <th>
                      <span>Type</span>
                      <span className="text-danger ms-1">*</span>
                    </th>
                    <th>
                      <span>Parent</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Select
                        id="assetTypeSelect"
                        name="assetTypeSelect"
                        isClearable
                        value={defaultAssetType}
                        onChange={(selected) => {
                          if (selected) {
                            const assetType = assetTypes.find(
                              (at) => at.id === selected.value
                            );
                            if (assetType) {
                              setAsset({
                                ...asset,
                                assetTypeId: assetType.id,
                                assetType,
                                dynamicAttributes: {},
                              });
                            }
                          } else {
                            setAsset({
                              ...asset,
                              assetTypeId: null,
                              assetType: null,
                              dynamicAttributes: {},
                            });
                          }
                        }}
                        placeholder="Select the type"
                        options={assetTypeSelect}
                        required
                        className="w-100"
                      />
                    </td>
                    <td>
                      <Select
                        id="assetParentSelect"
                        name="assetParentSelect"
                        isClearable
                        value={defaultAssetParent}
                        onChange={(selected) => {
                          if (selected) {
                            const assetParent = assets.find(
                              (a) => a.id === selected.value
                            );
                            if (assetParent) {
                              setAsset({
                                ...asset,
                                assetParentId: assetParent.id,
                                assetParent,
                              });
                            }
                          } else {
                            setAsset({
                              ...asset,
                              assetParentId: null,
                              assetParent: null,
                            });
                          }
                        }}
                        placeholder="Select the parent"
                        options={assetParentSelect}
                        className="w-100"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
                <thead>
                  <tr className="bg-graylight small text-muted">
                    {dynamicAttributes.map((dynamicAttribute) => (
                      <th key={dynamicAttribute.key}>
                        <DynamicAttributeLabel
                          align="center"
                          dynamicAttribute={dynamicAttribute}
                        />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {dynamicAttributes.map((dynamicAttribute) => (
                      <td key={dynamicAttribute.key}>
                        <DynamicAttributeInput
                          className="form-control border-0"
                          dynamicAttribute={dynamicAttribute}
                          data={asset}
                          setData={setAsset}
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Discard
          </Button>
          <Button className="ms-2" color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AssetModal;
