import React, { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment";
import { sharedHelper } from "../helpers/sharedHelper";
import Icon from "./Icon";

const CustomPeriodSelector = ({
  defaultStartDate,
  defaultEndDate,
  onSubmit,
}) => {
  // Start with the modal closed for the “normal” user flow.
  // But if you want it open initially for some reason, that's your choice.
  const [isOpen, setIsOpen] = useState(false);

  const [startDate, setStartDate] = useState(
    moment(defaultStartDate).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD")
  );

  // Correctly set isOpen to false when the modal finishes closing
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleChangeDateRange = useCallback((dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);

  return (
    <>
      {/* The clickable period text */}
      <div
        className="me-3 d-flex align-items-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Button
          className="d-none border bg-graylight text-primary"
          onClick={(evt) => {
            evt.stopPropagation();
            const newStartDate = moment(defaultStartDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD");
            const newEndDate = moment(defaultEndDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onSubmit(newStartDate, newEndDate);
          }}
        >
          <Icon name="chevron-left" className="text-primary" />
        </Button>
        <span
          className="mx-1 p-2 text-center flex-shrink-0 text-link"
          // Dangerously setting from sharedHelper.formatDateRange
          dangerouslySetInnerHTML={{
            __html: sharedHelper.formatDateRange(
              defaultStartDate,
              defaultEndDate
            ),
          }}
        />
        <Button
          className="d-none border bg-graylight text-primary"
          onClick={(evt) => {
            evt.stopPropagation();
            const newStartDate = moment(defaultStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
            const newEndDate = moment(defaultEndDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onSubmit(newStartDate, newEndDate);
          }}
        >
          <Icon name="chevron-right" className="text-primary" />
        </Button>
      </div>

      {/* The actual modal. We set fade={false} so that there's no async fade-out. */}
      <Modal isOpen={isOpen} fade={false} onClosed={handleClose} size="md">
        <ModalHeader
          toggle={handleClose}
          className="d-flex justify-content-between"
        >
          Date Range Selector
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <DatePicker
            selected={
              startDate ? moment(startDate).startOf("day").toDate() : null
            }
            startDate={
              startDate ? moment(startDate).startOf("day").toDate() : null
            }
            endDate={endDate ? moment(endDate).startOf("day").toDate() : null}
            onChange={handleChangeDateRange}
            selectsRange
            inline
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          {/* This is the important fix: setIsOpen(false) explicitly. */}
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
          <div className="min-width-50">
            <Button
              disabled={!startDate || !endDate}
              color="primary"
              onClick={() => {
                setIsOpen(false);
                onSubmit(
                  moment(startDate).format("YYYY-MM-DD"),
                  moment(endDate).format("YYYY-MM-DD")
                );
              }}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CustomPeriodSelector;
