import React, { useEffect, useMemo, useState } from "react";

import { Modal, ModalBody, Button, ModalFooter, ModalHeader } from "reactstrap";

import { sharedHelper } from "../helpers/sharedHelper";
import { useAuth, ACTIONS } from "../providers/authProvider";
import ConfirmationModal from "./ConfirmationModal";
import Icon from "./Icon";
import Loader from "./Loader";
import { useDisable2FA } from "../api/2FA.hooks";
import TwoFASetupModal from "./twoFA/TwoFASetupModal";

const ProfileModal = ({ onClose }) => {
  const [authContext, setAuthContext] = useAuth();

  const [twoFAEnabled, setTwoFAEnabled] = useState(
    authContext.userData.twoFAEnabled
  );
  const [confirmationModal, setConfirmationModal] = useState();
  const [enableTwoFAModal, setTwoFASetupModal] = useState();

  const {
    isLoading: isLoadingDisable2FA,
    mutate: disable2FA,
    data: disable2FAData,
  } = useDisable2FA();

  useEffect(() => {
    if (disable2FAData) {
      setTwoFAEnabled(false);
      sharedHelper.successToast("2FA disabled");
    }
  }, [disable2FAData]);

  const onDisable2FA = () =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await disable2FA();
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Disable 2FA",
      body: `Are you sure you want to disable 2FA?`,
      confirmText: "Confirm",
    });

  const doClose = () => {
    const touched = twoFAEnabled !== authContext.userData.twoFAEnabled;
    if (touched) {
      setAuthContext({
        action: ACTIONS.REFRESH,
      });
    }
    onClose();
  };

  const isForce2FAEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        "FORCE_WEB_2FA"
      ),
    [authContext.userData]
  );

  return enableTwoFAModal ? (
    <TwoFASetupModal
      userId={authContext.userData.id}
      onSubmit={() => {
        if (enableTwoFAModal === "CHANGE") {
          sharedHelper.successToast("2FA device changed");
        } else {
          sharedHelper.successToast("2FA enabled");
        }
        setTwoFASetupModal();
        setTwoFAEnabled(true);
      }}
      onClose={() => {
        setTwoFASetupModal();
      }}
    />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} onClosed={doClose} size="sm">
      <ModalHeader
        className="d-flex justify-content-between"
        toggle={doClose}
      ></ModalHeader>
      <ModalBody className="pt-0">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div
            data-testid="profile-user-image"
            className="profile-user-image rounded-circle flex-shrink-0 border p-1 d-flex align-items-center justify-content-center"
          >
            <Icon name="user" size="xl" />
          </div>
          <div className="d-flex flex-column ms-2 mt-2 mb-1">
            <span className="text-center text-truncate text-dark fw-bold">
              {`${authContext.userData.firstName} ${authContext.userData.lastName}`}
            </span>
            <small className="text-center text-truncate text-dark">
              {`${authContext.userData.role.name}`}
            </small>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="flex-nowrap justify-content-center">
        {isLoadingDisable2FA ? (
          <Loader size="sm" />
        ) : twoFAEnabled ? (
          <div className="w-100 d-flex justify-content-around align-items-center">
            {!isForce2FAEnabled ? (
              <Button
                style={{ minWidth: "auto" }}
                color="secondary"
                onClick={onDisable2FA}
              >
                Disable 2FA
              </Button>
            ) : null}
            <Button
              style={{ minWidth: "auto" }}
              color="primary"
              onClick={() => setTwoFASetupModal("CHANGE")}
            >
              Change Device
            </Button>
          </div>
        ) : (
          <Button color="primary" onClick={() => setTwoFASetupModal("SETUP")}>
            Enable 2FA
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ProfileModal;
