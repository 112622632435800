import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import moment from "moment";
import DatePicker from "react-datepicker";

import {
  CREW_WORK_DAY_ACTIONS,
  crewHelper,
  useCrews,
  useCrewWorkDays,
} from "@crewos/crews";

import { components, data, sharedHelper } from "@crewos/shared";

import { useGetEquipmentOptions } from "../api/EquipmentOptions.hooks";
import { useCreateEquipment, useGetEquipments } from "../api/Equipments.hooks";

const { Select, Loader } = components;

const { useWorkOrderDetails, useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const AddEquipmentModal = ({ onClose }) => {
  const [authContext] = useAuth();
  const [crewsContext] = useCrews();
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [crewsWithLeadForDate, setCrewsWithLeadForDate] = useState([]);
  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(
    workOrderDetails.selectedDate
  );

  const [equipmentOptionInput, setEquipmentOptionInput] = useState();
  const [equipmentOptionsAvailables, setEquipmentOptionsAvailables] = useState(
    []
  );
  const [quantityInput, setQuantityInput] = useState(1);
  const [equipments, setEquipments] = useState([]);

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const {
    data: getEquipmentsData,
    isLoading: isLoadingGetEquipments,
    get: getEquipments,
  } = useGetEquipments();

  useEffect(() => {
    if (selectedDate && crew) {
      getEquipments({
        date: moment(selectedDate).format("YYYY-MM-DD"),
        crewId: crew.id,
      });
    }
  }, [getEquipments, selectedDate, crew]);

  useEffect(() => {
    if (getEquipmentsData) {
      setEquipments(getEquipmentsData);
    }
  }, [getEquipmentsData, setEquipments]);

  const {
    data: equipmentOptions,
    isLoading: isLoadingEquipmentOptions,
    get: getEquipmentOptions,
  } = useGetEquipmentOptions();

  useEffect(() => {
    getEquipmentOptions();
  }, [getEquipmentOptions]);

  useEffect(() => {
    if (equipmentOptions) {
      const remainingEquipmentOptions = equipmentOptions.data.filter(
        (currentEquipmentOption) =>
          !equipments.find(
            (equipment) =>
              currentEquipmentOption.id === equipment.equipmentOption.id
          )
      );
      if (!remainingEquipmentOptions.length) {
        sharedHelper.warningToast("No equipment available to add to this day");
      }
      setEquipmentOptionsAvailables(remainingEquipmentOptions);
    }
  }, [equipmentOptions, equipments]);

  const {
    isLoading: isLoadingCreateEquipment,
    mutate: createEquipment,
    data: createEquipmentData,
  } = useCreateEquipment();

  useEffect(() => {
    if (createEquipmentData && crew) {
      sharedHelper.successToast(`Equipment added`);
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH_CREW_SECTION,
        payload: { crewId: crew.id, section: "EQUIPMENTS" },
      });
      onClose();
    }
  }, [
    onClose,
    createEquipmentData,
    crewWorkDaysContext.crewWorkDays,
    setCrewWorkDaysContext,
    crew,
  ]);

  const doSubmit = async (e) => {
    e.preventDefault();
    await createEquipment({
      date: moment(selectedDate).format("YYYY-MM-DD"),
      crewId: crew.id,
      equipmentOptionId: equipmentOptionInput.value,
      quantity: quantityInput,
    });
  };

  useEffect(() => {
    const crewsWithLeadForDate = crewsContext.crews.filter((crew) =>
      crewHelper.getCrewLead(crew, employeeCrewPastDatesEnabled, selectedDate)
    );
    setCrewsWithLeadForDate(crewsWithLeadForDate);
  }, [crewsContext.crews, selectedDate, employeeCrewPastDatesEnabled]);

  const crewForDate = useMemo(
    () => (crew ? crewsWithLeadForDate.find((c) => c.id === crew.id) : false),
    [crew, crewsWithLeadForDate]
  );

  const isLoading = useMemo(
    () =>
      isLoadingEquipmentOptions ||
      isLoadingCreateEquipment ||
      isLoadingGetEquipments,
    [
      isLoadingEquipmentOptions,
      isLoadingCreateEquipment,
      isLoadingGetEquipments,
    ]
  );

  const crewSelect = useMemo(() => {
    return crewsWithLeadForDate.map((crew) => {
      const lead = crewHelper.getCrewLead(crew, employeeCrewPastDatesEnabled);
      return {
        label: lead
          ? `${lead.employee.firstName} ${lead.employee.lastName}`
          : "No Lead",
        value: crew.id,
      };
    });
  }, [crewsWithLeadForDate, employeeCrewPastDatesEnabled]);

  const defaultCrew = useMemo(() => {
    return crewSelect.find((c) => c.value === crew?.id);
  }, [crewSelect, crew]);

  const onSelectedCrew = (selected) => {
    const crew = crewsContext.crews.find((c) => c.id === selected.value);
    setCrew(crew);
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Add Equipment
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoading ? (
            <Loader size="sm" />
          ) : (
            <Row className="mb-2">
              <FormGroup>
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>Date</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                <div className="d-flex col-12 align-items-center justify-content-between">
                  <DatePicker
                    selected={
                      selectedDate
                        ? moment(selectedDate).startOf("day").toDate()
                        : null
                    }
                    onChange={(date) => setSelectedDate(date)}
                    className="d-flex align-items-center justify-content-between form-control-redesign cursor-pointer"
                  />
                </div>
              </FormGroup>
              {selectedDate ? (
                <FormGroup>
                  <div className="d-flex col-12 align-items-center justify-content-start">
                    <span>
                      {directAssignationEnabled ? "Employee" : "Crew"}
                    </span>
                    <span className="text-danger ms-1">*</span>
                  </div>
                  {crewsWithLeadForDate.length ? (
                    <Select
                      id="crewSelect"
                      name="crewSelect"
                      data-testid={`${
                        directAssignationEnabled ? "employee" : "crew"
                      }-select`}
                      value={defaultCrew}
                      onChange={onSelectedCrew}
                      placeholder={`Select a ${
                        directAssignationEnabled ? "n Employee" : " Crew"
                      }`}
                      options={crewSelect}
                      required
                    />
                  ) : (
                    <div className="text-muted small">
                      No crews for selected date
                    </div>
                  )}
                </FormGroup>
              ) : null}
              {selectedDate && crewForDate && (
                <>
                  <FormGroup>
                    <Label>
                      <span>Equipment</span>
                      <small className="text-danger ms-1">*</small>
                    </Label>
                    <Select
                      name="equipmentSelect"
                      inputId="equipmentSelect"
                      data-testid="equipment-select"
                      placeholder="Search equipments"
                      options={equipmentOptionsAvailables.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      value={equipmentOptionInput}
                      onChange={(selected) => setEquipmentOptionInput(selected)}
                      required
                    />
                  </FormGroup>
                  {equipmentOptionInput ? (
                    <FormGroup className="col-12 d-flex flex-column">
                      <Label>
                        <span>Quantity</span>
                        <small className="text-danger ms-1">*</small>
                      </Label>
                      <input
                        className="form-control-redesign"
                        placeholder="Enter a quantity"
                        type="number"
                        step={1}
                        max={999}
                        min={1}
                        required={true}
                        value={quantityInput}
                        onChange={(evt) => setQuantityInput(evt.target.value)}
                      />
                    </FormGroup>
                  ) : null}
                </>
              )}
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          <Button color="primary" type="submit">
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddEquipmentModal;
