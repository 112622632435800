import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DebounceInput } from "react-debounce-input";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import moment from "moment";

import { components, data, sharedHelper } from "@crewos/shared";

import {
  calculateHoursWithExceptions,
  useGetAllWorkTimeTypes,
} from "@crewos/worktimes";

import { useGetLaborReportByJob } from "../api/Reports.hooks";

import LaborCostDetail from "../components/LaborCostDetail";

const {
  Icon,
  AdvanceTable,
  AdvanceTablePagination,
  WeekSelector,
  CustomPeriodSelector,
  PeriodTypeSelector,
} = components;

const { WORK_ORDER_DETAILS_TABS } = data;

const TYPE_WEEKLY = "TYPE_WEEKLY";

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const LaborByJobReport = ({ workOrderId }) => {
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });
  const [detailModal, setDetailModal] = useState();

  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [dates, setDates] = useState({
    start: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    end: moment().endOf("isoWeek").format("YYYY-MM-DD"),
  });

  const {
    data: workTimeTypes,
    isLoading: isLoadingWorkTimeTypes,
    get: getAllWorkTimeTypes,
  } = useGetAllWorkTimeTypes();

  const {
    data: reportData,
    isLoading: isLoadingReport,
    get: getLaborReport,
  } = useGetLaborReportByJob();

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [reportData, setData]);

  useEffect(() => {
    getAllWorkTimeTypes();
  }, [getAllWorkTimeTypes]);

  useEffect(() => {
    const params = {
      search,
      page,
      pageSize: sizePerPage,
      startDate: dates.start,
      endDate: dates.end,
    };
    if (workOrderId) {
      params.workOrderId = workOrderId;
    }
    getLaborReport(params);
  }, [
    getLaborReport,
    workOrderId,
    dates,
    refresh,
    workTimeTypes,
    sizePerPage,
    page,
    search,
  ]);

  const handleChangePeriodType = useCallback((selectedPeriodType) => {
    setPeriodType((prev) => {
      if (prev.value === selectedPeriodType.value) {
        return prev;
      }
      if (selectedPeriodType.value === TYPE_WEEKLY) {
        setDates((dates) => ({
          start: moment(dates.start).startOf("isoWeek").format("YYYY-MM-DD"),
          end: moment(dates.start).endOf("isoWeek").format("YYYY-MM-DD"),
        }));
      }
      return selectedPeriodType;
    });
  }, []);

  const workTimeTypesColumn = useMemo(() => {
    if (workTimeTypes && !isLoadingWorkTimeTypes) {
      return workTimeTypes.map((type) => ({
        accessor: `workTimes.${type.shortName}`,
        header: sharedHelper.capitalize(type.name),
        headerProps: { className: "text-truncate text-center" },
        cellProps: { className: "text-truncate text-center" },
        type: "number",
        Cell: (rowData) => {
          const {
            workTimes = {},
            firstName,
            lastName,
            workOrderId,
            workOrderNumber,
          } = rowData.row;
          if (!workTimes[type.shortName]) {
            return "0.00";
          }
          const entries = Object.values(workTimes[type.shortName])
            .flatMap((e) => e)
            .filter((entry) => typeof entry === "object")
            .sort((x, y) => (moment(x.date).isBefore(moment(y.date)) ? -1 : 1));
          const isInProgress = Boolean(
            entries.find((wt) => wt.startTime && !wt.endTime)
          );
          const totalHours = entries.reduce(
            (p, c) => p + calculateHoursWithExceptions(c),
            0
          );
          const value = isInProgress ? "In Progress" : totalHours.toFixed(2);
          return (
            <span
              data-value={value}
              className="text-link"
              onClick={() => {
                setDetailModal({
                  workTimeTypeName: type.name,
                  workTimeTypeId: type.id,
                  workOrderId,
                  workOrderNumber,
                  firstName,
                  lastName,
                });
              }}
            >
              {value}
            </span>
          );
        },
      }));
    }
    return [];
  }, [workTimeTypes, isLoadingWorkTimeTypes]);

  const columns = useMemo(() => {
    return [
      {
        accessor: "workOrderNumber",
        header: "Work Order",
        headerProps: { className: "text-truncate" },
        cellProps: { className: "text-truncate" },
        Cell: (rowData) => {
          const { workOrderNumber, workOrderId } = rowData.row;
          return (
            <a
              className="text-link"
              target="_blank"
              rel="noreferrer"
              data-value={workOrderNumber}
              data-testid="work-order-link"
              href={`/workorders/details/${workOrderId}/${WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB}`}
            >
              {workOrderNumber}
            </a>
          );
        },
      },
      ...workTimeTypesColumn,
      {
        accessor: "total",
        header: "Total",
        headerProps: { className: "text-truncate text-end" },
        cellProps: { className: "text-truncate fw-bold text-end" },
        Cell: (rowData) => rowData.row.total.toFixed(2),
      },
    ];
  }, [workTimeTypesColumn]);

  const isLoading = useMemo(
    () => isLoadingWorkTimeTypes || isLoadingReport,
    [isLoadingWorkTimeTypes, isLoadingReport]
  );

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center flex-shrink-0">
            <h2 className="mb-0">Labor By Job Report</h2>
            <small className="text-muted ms-2 pt-1">({data.count})</small>
          </div>
          <div className="ms-4 d-flex align-items-center justify-content-end flex-shrink-0">
            <PeriodTypeSelector
              periodType={periodType}
              setPeriodType={handleChangePeriodType}
            />
            {periodType.value === TYPE_WEEKLY ? (
              <WeekSelector
                className="me-3"
                loading={isLoading}
                weekStart={dates.start}
                setWeekStart={(startDate) => {
                  const endDate = moment(startDate)
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");
                  setDates({
                    start: startDate,
                    end: endDate,
                  });
                }}
              />
            ) : (
              <CustomPeriodSelector
                defaultStartDate={dates.start}
                defaultEndDate={dates.end}
                onSubmit={(startDate, endDate) => {
                  setDates({
                    start: moment(startDate).format("YYYY-MM-DD"),
                    end: moment(endDate).format("YYYY-MM-DD"),
                  });
                }}
              />
            )}
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search wo#"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              key={`${dates.start}-${columns.length}`}
              exportable
              exportName="LaborByJobReport.csv"
              columns={columns}
              data={data.data || []}
              pageSize={data.count}
              isLoading={isLoading}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "overflow-hidden w-100 table-layout-auto",
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={data.count}
            pageCount={data.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {detailModal ? (
        <LaborCostDetail
          {...detailModal}
          startDate={dates.start}
          endDate={dates.end}
          onClose={(touched) => {
            setDetailModal();
            if (touched) {
              setRefresh(!refresh);
            }
          }}
        />
      ) : null}
    </Container>
  );
};

export default LaborByJobReport;
