import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import FormTemplateGroupItem from "./FormTemplateGroupItem";
import SortableWrapper from "../SortableWrapper";
import {
  useFormGroups,
  useFormSections,
} from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

const FormTemplateGroups = ({ isReadOnly, groups }) => {
  const [activeGroupId, setActiveGroupId] = useState(null);
  const { state, updateGroupsOrder, addGroup } = useFormGroups();
  const { addSection } = useFormSections();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active && over && active.id !== over.id) {
        const oldIndex = groups.findIndex((item) => item.id === active.id);
        const newIndex = groups.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(groups, oldIndex, newIndex);
        updateGroupsOrder(newItems);
      }
    },
    [updateGroupsOrder, groups]
  );

  const handleAddGroup = useCallback(() => {
    if (state.activeTab) {
      addGroup(state.activeTab);
    } else {
      addSection();
    }
  }, [addGroup, addSection, state.activeTab]);

  const handleCopyGroup = useCallback((groupId) => {
    setActiveGroupId(groupId);
  }, []);

  const handleScrollToGroup = useCallback((groupId) => {
    const groupElement = document.getElementById(`group-${groupId}`);
    if (groupElement) {
      groupElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (activeGroupId) {
      handleScrollToGroup(activeGroupId);
    }
    return () => {
      setActiveGroupId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  return (
    <Card className="mb-3 w-100 box-shadow-none">
      <CardBody className="d-flex flex-column gap-3 p-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={groups.map((group) => group.id)}
            strategy={verticalListSortingStrategy}
          >
            {groups.map((group, index) => (
              <SortableWrapper
                id={group.id}
                index={index}
                key={group.id}
                className="sortable-wrapper"
                isReadOnly={isReadOnly}
              >
                <FormTemplateGroupItem
                  key={group.id}
                  group={group}
                  isReadOnly={isReadOnly}
                  onCopyGroup={handleCopyGroup}
                />
              </SortableWrapper>
            ))}
          </SortableContext>
          {!isReadOnly && (
            <Row>
              <Col className="d-grid gap-2 add-btn-container">
                <Button
                  size="sm"
                  color="white"
                  className="btn-dashed"
                  onClick={handleAddGroup}
                >
                  <Icon name="circle-plus" /> Add Group
                </Button>
              </Col>
            </Row>
          )}
        </DndContext>
      </CardBody>
    </Card>
  );
};

export default FormTemplateGroups;
