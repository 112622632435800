import React, { useEffect, useState, useCallback, useMemo } from "react";

import { DebounceInput } from "react-debounce-input";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Table,
  Badge,
} from "reactstrap";

import { EmployeeNotesModal } from "@crewos/notes";
import { sharedHelper, components, data } from "@crewos/shared";

import { useDeleteEmployee, useGetEmployees } from "../api/Employees.hooks";
import EmployeeModal from "../components/EmployeeModal";

const {
  Icon,
  Select,
  AdvanceTable,
  AdvanceTablePagination,
  ConfirmationModal,
  InformationModal,
} = components;

const { useAuth, WORK_ORDER_DETAILS_TABS } = data;

const columns = (
  onEditNotes,
  onEdit,
  onDelete,
  onAssignments,
  notesPackageEnabled,
  serviceLocationEnabled
) =>
  [
    {
      accessor: "firstName",
      header: "First Name",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        const { firstName } = rowData.row;
        return firstName || "-";
      },
    },
    {
      accessor: "lastName",
      header: "Last Name",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        const { lastName } = rowData.row;
        return lastName || "-";
      },
    },
    {
      accessor: "email",
      header: "Email",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        const { email } = rowData.row;
        return email || "-";
      },
    },
    {
      accessor: "phone",
      header: "Phone",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        const { phone } = rowData.row;
        return phone || "-";
      },
    },
    {
      accessor: "role.name",
      header: "Role",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        const { role } = rowData.row;
        return role.name || "-";
      },
    },
    serviceLocationEnabled
      ? {
          accessor: "serviceLocations",
          header: "Service Location",
          disableSortBy: true,
          headerProps: { className: "text-truncate", style: { maxWidth: 250 } },
          cellProps: {
            className: "text-truncate",
            style: { maxWidth: 250 },
          },
          Cell: (rowData) => {
            const { serviceLocations = [] } = rowData.row;
            return serviceLocations.map((sl) => sl.name).join(", ");
          },
        }
      : false,
    {
      accessor: "id",
      header: "",
      disableFilter: true,
      disableSortBy: true,
      headerProps: { style: { maxWidth: 350 } },
      cellProps: {
        className: "text-end",
        style: { maxWidth: 350 },
      },
      Cell: (rowData) => (
        <div className="d-flex align-items-center justify-content-end">
          {rowData.row.isInternal ? (
            <Badge pill size="sm" color="dark">
              Internal
            </Badge>
          ) : null}
          {notesPackageEnabled ? (
            <Button
              size="sm"
              color="primary"
              className="ms-2"
              onClick={() => onEditNotes(rowData.row.id)}
            >
              Notes
            </Button>
          ) : null}
          <Button
            size="sm"
            color="primary"
            className="ms-2"
            onClick={() => onAssignments(rowData.row)}
          >
            Assignments
          </Button>
          <Button
            size="sm"
            color="none"
            className="ms-2 text-primary"
            onClick={() => onEdit(rowData.row)}
          >
            Edit
          </Button>
          {onDelete ? (
            <Button
              size="sm"
              color="none"
              className="ms-2 text-danger"
              data-testid="delete-button"
              onClick={() => onDelete(rowData.row)}
            >
              Delete
            </Button>
          ) : null}
        </div>
      ),
    },
  ].filter(Boolean);

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const Employees = () => {
  const [authContext] = useAuth();

  const [informationModal, setInformationModal] = useState();
  const [employees, setEmployees] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("firstName");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [showInactive, setShowInactive] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const [employeeNotesModal, setEmployeeNotesModal] = useState();

  const {
    data: employeesData,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const { data: deleteEmployeeData, deleteEmployee } = useDeleteEmployee();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getEmployees({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
      isActive: showInactive ? "false" : "true",
    });
  }, [
    getEmployees,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    showInactive,
  ]);

  useEffect(() => {
    if (employeesData) {
      setEmployees(employeesData);
    }
  }, [employeesData, setEmployees]);

  useEffect(() => {
    if (deleteEmployeeData) {
      sharedHelper.successToast(`Employee deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteEmployeeData, setRefresh]);

  const onEdit = (employee) => setEditModal(employee);

  const onDelete = (employee) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteEmployee(employee.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Employee",
      body: `Are you sure you want to delete ${employee.firstName} ${employee.lastName}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const onEditNotes = (employeeId) => setEmployeeNotesModal(employeeId);

  const notesPackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "notes"
  );

  const onAssignments = (employee) => {
    return setInformationModal({
      title: "Employee Assignments",
      rawBody: true,
      size: "xl",
      onClose: () => setInformationModal(),
      body: (
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-graylight small">
              <th>Work Order</th>
              <th>Role</th>
              <th>Crew Lead</th>
              <th>From</th>
              <th>To</th>
            </tr>
          </thead>
          <tbody className="small">
            {employee.employeeCrews.length ? (
              employee.employeeCrews
                .sort(sharedHelper.sortEmployeeCrew)
                .map((employeeCrew) => (
                  <tr key={employeeCrew.id}>
                    <td>
                      <a
                        target="_blank"
                        className="text-link"
                        href={`/workorders/details/${employeeCrew.crew.workOrder.id}/${WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB}`}
                        rel="noreferrer"
                      >{`${employeeCrew.crew.workOrder.workOrderNumber}`}</a>
                    </td>
                    <td>{employeeCrew.role.name}</td>
                    <td>{employeeCrew.isLead ? "Yes" : "No"}</td>
                    <td>
                      {sharedHelper.formatDateTime(employeeCrew.createdAt)}
                    </td>
                    <td>
                      {employeeCrew.disabledAt
                        ? sharedHelper.formatDateTime(employeeCrew.disabledAt)
                        : "Present"}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No assignments
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ),
    });
  };

  const isSuperAdmin = useMemo(
    () =>
      sharedHelper.userHasScope(authContext.userData?.role, [
        IS_SUPER_ADMIN_USER,
      ]),
    [authContext.userData]
  );

  const activeSelect = useMemo(() => {
    return [
      { label: "Active", value: false },
      { label: "Inactive", value: true },
    ];
  }, []);

  const defaultActive = useMemo(() => {
    return activeSelect.find((option) => option.value === showInactive);
  }, [showInactive, activeSelect]);

  const serviceLocationEnabled = useMemo(
    () =>
      authContext.userData
        ? sharedHelper.isSettingEnabled(
            authContext.userData.packages,
            "SERVICE_LOCATIONS_ENABLED"
          )
        : false,
    [authContext.userData]
  );

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Employees</h2>
            <small className="text-muted ms-2 pt-1">({employees.count})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <Select
                id="activeSelect"
                name="activeSelect"
                options={activeSelect}
                onChange={(selected) => setShowInactive(selected.value)}
                value={defaultActive}
                placeholder="Filter by status"
              />
            </div>
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search employees"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(
                onEditNotes,
                onEdit,
                isSuperAdmin ? onDelete : false,
                onAssignments,
                notesPackageEnabled,
                serviceLocationEnabled
              )}
              data={employees.data || []}
              pageSize={sizePerPage}
              sortable
              exportable
              exportName="employees.csv"
              onSort={onSort}
              defaultSort={{
                sortBy,
                direction,
              }}
              isLoading={isLoadingEmployees}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={employees.count}
            pageCount={employees.totalPages}
            currentPage={page}
            onPageChange={(page) => {
              setPage(page - 1);
            }}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {editModal ? (
        <EmployeeModal
          employee={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <EmployeeModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : employeeNotesModal ? (
        <EmployeeNotesModal
          employeeId={employeeNotesModal}
          onClose={() => setEmployeeNotesModal()}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal ? (
        <InformationModal {...informationModal} />
      ) : null}
    </Container>
  );
};

export default Employees;
