import React, { useCallback, useMemo } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import classNames from "classnames";

import {
  useFormQuestions,
  ELEMENT_STATUS,
} from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

import InputEditable from "../InputEditable";
import FormTemplateQuestionTypePreview from "./FormTemplateQuestionTypePreview";
import FormTemplateGuidance from "./FormTemplateGuidance";
import Select from "../Select";

const FormTemplateQuestionItem = ({ question, isReadOnly, onCopyQuestion }) => {
  const {
    state: { questionTypes },
    getOptions,
    updateQuestionName,
    updateQuestionType,
    updateQuestionRequired,
    addGuidance,
    deleteGuidance,
    deleteQuestion,
    copyQuestion,
    getErrors,
  } = useFormQuestions();

  const handleUpdateName = useCallback(
    ($event) => {
      updateQuestionName(question.id, $event.target.value);
    },
    [updateQuestionName, question]
  );

  const handleUpdateQuestionType = useCallback(
    ($event) => {
      updateQuestionType(question.id, $event.value);
    },
    [updateQuestionType, question]
  );

  const handleUpdateRequired = useCallback(
    ($event) => {
      updateQuestionRequired(question.id, $event.target.checked);
    },
    [updateQuestionRequired, question]
  );

  const handleUpdateGuidance = useCallback(
    ($event) => {
      if ($event.target.checked) {
        addGuidance(question.id);
      } else {
        deleteGuidance(question.id);
      }
    },
    [addGuidance, deleteGuidance, question]
  );

  const handleCopyQuestion = useCallback(() => {
    const questionId = copyQuestion(question);
    onCopyQuestion(questionId);
  }, [copyQuestion, question, onCopyQuestion]);

  const handleRemoveQuestion = useCallback(() => {
    deleteQuestion(question.id);
  }, [deleteQuestion, question]);

  const questionTypesSelect = useMemo(() => {
    return (
      questionTypes?.map((type) => ({ value: type.id, label: type.name })) || []
    );
  }, [questionTypes]);

  const defaultQuestionType = useMemo(() => {
    return questionTypesSelect.find(
      (type) => type.value === question?.questionType?.id
    );
  }, [question, questionTypesSelect]);

  const hasGuidance = useMemo(() => {
    return (
      !!question.guidance && question.guidance?.status !== ELEMENT_STATUS.DELETE
    );
  }, [question]);

  const options = getOptions(question.id);

  return (
    <Card
      className={classNames("w-100 sortable-item bg-white", {
        "border-danger border-1": getErrors(question.id)?.field?.length > 0,
      })}
      id={`question-${question.id}`}
    >
      <CardHeader className="py-3 pt-4 pb-0">
        <Row>
          <Col sm={11}>
            <span className="text-muted small">{question.order}. </span>
            <InputEditable
              disabled={isReadOnly}
              onBlur={handleUpdateName}
              defaultValue={question.content}
              placeholder="Question name"
              size="medium"
              errors={getErrors(question.id)?.field}
            />
          </Col>
          {!isReadOnly && (
            <Col className="d-flex justify-content-end" sm={1}>
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button border-0"
                color="white"
                onClick={handleCopyQuestion}
                aria-label="Copy Question"
              >
                <Icon name="copy" />
              </Button>
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={6}>
            <FormTemplateQuestionTypePreview
              question={question}
              options={options}
              isReadOnly={isReadOnly}
            />
            {getErrors(question.id)?.field?.map((error) => (
              <small key={error} className="fst-italic text-danger d-block">
                {error}
              </small>
            ))}
          </Col>
          <Col xs={5} className="d-flex justify-content-center">
            <div className="gap-3 d-flex flex-column w-50">
              <Select
                options={questionTypesSelect}
                isDisabled={isReadOnly}
                value={defaultQuestionType}
                onChange={handleUpdateQuestionType}
                placeholder="Select the type"
                data-testid={`question-type-${question.id}`}
                key={`select-question-type-${question.id}`}
              />
              <FormGroup
                switch
                className="input-switch ps-5 d-flex align-items-center gap-2"
              >
                <Input
                  type="switch"
                  role="switch"
                  id={`is-required-${question.id}`}
                  name={`is-required-${question.id}`}
                  disabled={isReadOnly}
                  checked={!!question.isRequired}
                  onChange={handleUpdateRequired}
                  className="cursor-pointer"
                />
                <Label
                  for={`is-required-${question.id}`}
                  className="cursor-pointer pt-1 mb-0 d-flex text-muted small"
                >
                  Is Required
                </Label>
              </FormGroup>
              <FormGroup
                switch
                className="input-switch ps-5 d-flex align-items-center gap-2"
              >
                <Input
                  type="switch"
                  role="switch"
                  id={`add-guidance-${question.id}`}
                  name={`add-guidance-${question.id}`}
                  disabled={isReadOnly}
                  checked={!!hasGuidance}
                  onChange={handleUpdateGuidance}
                  className="cursor-pointer"
                />
                <Label
                  for={`add-guidance-${question.id}`}
                  className="cursor-pointer pt-1 mb-0 d-flex text-muted small"
                >
                  Add guidance
                </Label>
              </FormGroup>
            </div>
          </Col>
          {!isReadOnly && (
            <Col xs={1} className="d-flex align-items-end justify-content-end">
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button text-danger border-0"
                color="white"
                onClick={handleRemoveQuestion}
                aria-label="Delete Question"
              >
                <Icon name="trash" />
              </Button>
            </Col>
          )}
        </Row>
        {hasGuidance && (
          <FormTemplateGuidance
            guidance={question.guidance || {}}
            questionId={question.id}
            isReadOnly={isReadOnly}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default FormTemplateQuestionItem;
