import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import CurrencyInput from "react-currency-input-field";
import moment from "moment";

import { components, data, sharedHelper } from "@crewos/shared";
import {
  useCreateExpenseAttachment,
  useDeleteExpenseAttachment,
} from "../api/ExpenseAttachments.hooks";
import { useUpdateExpense } from "../api/Expenses.hooks";
import { useGetAllExpenseTypes } from "../api/ExpenseTypes.hooks";

const {
  Icon,
  Select,
  Loader,
  ImagesViewer,
  ImagesViewerModal,
  ConfirmationModal,
  UploadPhotosModal,
} = components;

const { useAuth } = data;

const PAYMENT_TYPE_CARD = "card";
const PAYMENT_TYPE_CASH = "cash";

const STATUS_PENDING = "pending";
const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";

const MODE_PREVIEW = "MODE_PREVIEW";
const PREVIEW_WIDTH = 48;
const PREVIEW_HEIGHT = 48;

const ExpenseModal = ({ isReadOnly, onClose, onSubmit, expense }) => {
  const [authContext] = useAuth();

  const [imageModal, setImageModal] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [localExpense, setLocalExpense] = useState(expense);

  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [touched, setTouched] = useState(null);

  const doClose = useMemo(
    () => (touched ? onSubmit : onClose),
    [touched, onClose, onSubmit]
  );

  const { mutate: createExpenseAttachment, data: createExpenseAttachmentData } =
    useCreateExpenseAttachment();

  const { data: deleteExpenseAttachmentData, deleteExpenseAttachment } =
    useDeleteExpenseAttachment();

  useEffect(() => {
    if (createExpenseAttachmentData) {
      setTouched(true);
      sharedHelper.successToast("Photos uploaded");
      const expenseAttachment = createExpenseAttachmentData.data;
      setLocalExpense((prev) => ({
        ...prev,
        expenseAttachments: [...prev.expenseAttachments, expenseAttachment],
      }));
      setShowAddPhotoModal(false);
    }
  }, [createExpenseAttachmentData]);

  useEffect(() => {
    if (deleteExpenseAttachmentData) {
      setTouched(true);
      sharedHelper.successToast("Expense photo deleted");
      setShowAddPhotoModal(false);
    }
  }, [deleteExpenseAttachmentData, onSubmit]);

  const {
    isLoading: isLoadingGetExpenseTypes,
    get: getExpenseTypes,
    data: expenseTypesData,
  } = useGetAllExpenseTypes();

  useEffect(() => {
    getExpenseTypes();
  }, [getExpenseTypes]);

  useEffect(() => {
    if (expenseTypesData) {
      setExpenseTypes(expenseTypesData);
    }
  }, [expenseTypesData]);

  const {
    isLoading: isUpdatingExpense,
    update: updateExpense,
    data: updateExpenseData,
  } = useUpdateExpense();

  useEffect(() => {
    if (updateExpenseData) {
      sharedHelper.successToast("Expense saved");
      onSubmit();
    }
  }, [updateExpenseData, onSubmit]);

  const onDeleteImage = (attachment) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await deleteExpenseAttachment(attachment.id);
        setConfirmationModal(null);
        setImageModal(null);
        const expenseAttachments = [...localExpense.expenseAttachments];
        const index = expenseAttachments.findIndex(
          (a) => a.id === attachment.id
        );
        if (index > -1) {
          expenseAttachments.splice(index, 1);
          setLocalExpense({
            ...localExpense,
            expenseAttachments,
          });
        }
      },
      onClose: () => {
        setConfirmationModal(null);
      },
      title: "Delete Photo",
      body: "Are you sure you want to delete this photo?",
      confirmText: "Delete",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Handle approved/declined dates if status changes
    if (expense.status !== localExpense.status) {
      if (localExpense.status === STATUS_APPROVED) {
        localExpense.approvedAt = moment().toISOString();
        localExpense.approvedBy = authContext.userData.id;
        localExpense.declinedAt = null;
        localExpense.declinedBy = null;
        localExpense.declinedReason = null;
      } else {
        localExpense.approvedAt = null;
        localExpense.approvedBy = null;
        if (localExpense.status === STATUS_DECLINED) {
          localExpense.declinedAt = moment().toISOString();
          localExpense.declinedBy = authContext.userData.id;
        } else {
          localExpense.declinedAt = null;
          localExpense.declinedBy = null;
          localExpense.declinedReason = null;
        }
      }
    }

    await updateExpense({ ...localExpense });
  };

  // Build options
  const expenseExpenseTypesSelect = useMemo(() => {
    return expenseTypes.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [expenseTypes]);

  const defaultExpenseType = useMemo(() => {
    return expenseExpenseTypesSelect.find(
      (item) => item.value === localExpense.expenseTypeId
    );
  }, [expenseExpenseTypesSelect, localExpense.expenseTypeId]);

  const paymentTypeSelect = useMemo(
    () => [
      { value: PAYMENT_TYPE_CARD, label: "Card" },
      { value: PAYMENT_TYPE_CASH, label: "Cash" },
    ],
    []
  );

  const defaultPaymentType = useMemo(() => {
    return paymentTypeSelect.find(
      (item) => item.value === localExpense.paymentType
    );
  }, [paymentTypeSelect, localExpense.paymentType]);

  const statusSelect = useMemo(
    () => [
      { value: STATUS_APPROVED, label: "Approved" },
      { value: STATUS_DECLINED, label: "Declined" },
      { value: STATUS_PENDING, label: "Pending" },
    ],
    []
  );

  const defaultStatus = useMemo(() => {
    return statusSelect.find((item) => item.value === localExpense.status);
  }, [statusSelect, localExpense.status]);

  // --- READ-ONLY RENDER ---
  const renderReadOnlyBody = () => {
    const employeeName = localExpense.employee
      ? `${localExpense.employee.firstName} ${localExpense.employee.lastName}`
      : "-";

    const expenseTypeName =
      expenseTypes.find((et) => et.id === localExpense.expenseTypeId)?.name ||
      "-";

    let paymentTypeName = "-";
    if (localExpense.paymentType === PAYMENT_TYPE_CASH)
      paymentTypeName = "Cash";
    else if (localExpense.paymentType === PAYMENT_TYPE_CARD)
      paymentTypeName = "Card";

    let statusName = "-";
    if (localExpense.status === STATUS_PENDING) statusName = "Pending";
    else if (localExpense.status === STATUS_APPROVED) statusName = "Approved";
    else if (localExpense.status === STATUS_DECLINED) statusName = "Declined";

    return (
      <>
        <ModalBody>
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <strong>Employee</strong>
              <span>{employeeName}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <strong>Expense Type</strong>
              <span>{expenseTypeName}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <strong>Payment Method</strong>
              <span>{paymentTypeName}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <strong>Amount</strong>
              <span>${localExpense.amount?.toFixed(2) || "0.00"}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <strong>Description</strong>
              <span>{localExpense.description || "-"}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <strong>Status</strong>
              <span>{statusName}</span>
            </ListGroupItem>
            {localExpense.status === STATUS_DECLINED && (
              <ListGroupItem className="d-flex justify-content-between align-items-center">
                <strong>Declined Reason</strong>
                <span>{localExpense.declinedReason || "-"}</span>
              </ListGroupItem>
            )}
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <strong>Photos</strong>
              <div className="d-flex flex-wrap">
                {localExpense.expenseAttachments.length ? (
                  localExpense.expenseAttachments.map((attachment) => (
                    <Col
                      key={attachment.id}
                      style={{
                        maxWidth: PREVIEW_WIDTH,
                        maxHeight: PREVIEW_HEIGHT,
                        minWidth: PREVIEW_WIDTH,
                        minHeight: PREVIEW_HEIGHT,
                      }}
                      className="my-1 cursor-pointer px-0 mx-1 border-radius-default overflow-hidden"
                      onClick={() => setImageModal(attachment)}
                    >
                      <ImagesViewer
                        mode={MODE_PREVIEW}
                        height={PREVIEW_WIDTH}
                        width={PREVIEW_HEIGHT}
                        showActions={false}
                        showCaption={false}
                        items={[
                          {
                            url: attachment.attachmentUrl,
                            title: attachment.description,
                          },
                        ]}
                      />
                    </Col>
                  ))
                ) : (
                  <small className="text-muted">No photos</small>
                )}
              </div>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter className="d-flex flex-nowrap justify-content-end">
          <Button
            color="secondary"
            onClick={doClose}
            className="col-6 text-dark"
          >
            Close
          </Button>
        </ModalFooter>
      </>
    );
  };

  // --- EDITABLE RENDER ---
  const renderEditableBody = () => {
    return (
      <Form className="px-1" onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="employee">
                  <span>Employee</span>
                </Label>
                <input
                  data-testid="employee"
                  className="form-control-redesign"
                  required
                  placeholder="Enter an employee"
                  id="employee"
                  name="employee"
                  value={
                    localExpense.employee
                      ? `${localExpense.employee.firstName} ${localExpense.employee.lastName}`
                      : "-"
                  }
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="expenseExpenseTypesSelect">
                  <span>Expense Type</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                {isLoadingGetExpenseTypes ? (
                  <Loader size="sm" />
                ) : (
                  <Select
                    id="expenseExpenseTypesSelect"
                    name="expenseExpenseTypesSelect"
                    data-testid="expenseExpenseTypesSelect"
                    value={defaultExpenseType}
                    onChange={(selected) =>
                      setLocalExpense({
                        ...localExpense,
                        expenseTypeId: selected.value,
                      })
                    }
                    placeholder="Select the expense type"
                    options={expenseExpenseTypesSelect}
                    required
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="paymentTypeSelect">
                  <span>Payment Method</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <Select
                  id="paymentTypeSelect"
                  name="paymentTypeSelect"
                  data-testid="paymentTypeSelect"
                  value={defaultPaymentType}
                  onChange={(selected) =>
                    setLocalExpense({
                      ...localExpense,
                      paymentType: selected.value,
                    })
                  }
                  placeholder="Select the payment method"
                  options={paymentTypeSelect}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="amount">
                  <span>Amount</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <CurrencyInput
                  data-testid="amount"
                  className="form-control-redesign"
                  required
                  id="amount"
                  name="amount"
                  value={localExpense.amount || ""}
                  prefix="$"
                  decimalsLimit={2}
                  max={999999999}
                  allowNegativeValue={false}
                  placeholder="Enter an amount"
                  onValueChange={(value) =>
                    setLocalExpense({
                      ...localExpense,
                      amount: parseFloat(value) || 0,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">
                  <span>Description</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  data-testid="description"
                  className="form-control-redesign"
                  required
                  id="description"
                  name="description"
                  rows={4}
                  placeholder="Enter a description"
                  type="textarea"
                  value={localExpense.description || ""}
                  onChange={(e) =>
                    setLocalExpense({
                      ...localExpense,
                      description: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="statusSelect">
                  <span>Status</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <Select
                  id="statusSelect"
                  name="statusSelect"
                  data-testid="statusSelect"
                  value={defaultStatus}
                  onChange={(selected) =>
                    setLocalExpense({
                      ...localExpense,
                      status: selected?.value || "",
                    })
                  }
                  placeholder="Select the status"
                  options={statusSelect}
                  required
                />
              </FormGroup>
              {localExpense.status === STATUS_DECLINED && (
                <FormGroup>
                  <Label htmlFor="declinedReason">
                    <span>Declined Reason</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    data-testid="declinedReason"
                    className="form-control-redesign"
                    required
                    id="declinedReason"
                    rows={4}
                    placeholder="Enter a reason"
                    type="textarea"
                    maxLength="255"
                    value={localExpense.declinedReason || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        declinedReason: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              )}
              <FormGroup className="mb-0">
                <Label>
                  <span>Photos</span>
                </Label>
                <div className="d-flex mx-n1">
                  {/* Plus button ONLY when isReadOnly = false */}
                  <Col
                    style={{
                      maxWidth: PREVIEW_WIDTH,
                      maxHeight: PREVIEW_HEIGHT,
                      minWidth: PREVIEW_WIDTH,
                      minHeight: PREVIEW_HEIGHT,
                    }}
                    className="mb-1 mt-1 cursor-pointer px-0 mx-1 d-flex align-items-center justify-content-center"
                    onClick={() => setShowAddPhotoModal(true)}
                    data-testid="add-photo-button"
                  >
                    <Icon name="plus-circle" className="text-primary fs-4" />
                  </Col>
                  {/* Show thumbnails only if there are attachments. 
                      Do not display "No photos" in editable mode. */}
                  {!!localExpense.expenseAttachments.length &&
                    localExpense.expenseAttachments.map((attachment) => (
                      <Col
                        key={attachment.id}
                        style={{
                          maxWidth: PREVIEW_WIDTH,
                          maxHeight: PREVIEW_HEIGHT,
                          minWidth: PREVIEW_WIDTH,
                          minHeight: PREVIEW_HEIGHT,
                        }}
                        className="my-1 cursor-pointer px-0 mx-1 border-radius-default overflow-hidden"
                        onClick={() => setImageModal(attachment)}
                      >
                        <ImagesViewer
                          mode={MODE_PREVIEW}
                          height={PREVIEW_WIDTH}
                          width={PREVIEW_HEIGHT}
                          showActions={false}
                          showCaption={false}
                          items={[
                            {
                              url: attachment.attachmentUrl,
                              title: attachment.description,
                            },
                          ]}
                        />
                      </Col>
                    ))}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex flex-nowrap justify-content-center">
          <Button
            color="secondary"
            onClick={doClose}
            className="col-6 text-dark"
          >
            Close
          </Button>
          <Button color="primary" type="submit" className="col-6">
            Save
          </Button>
        </ModalFooter>
      </Form>
    );
  };

  // --- DIALOG CONTENT SWITCHES ---
  if (confirmationModal) {
    return <ConfirmationModal {...confirmationModal} />;
  }
  if (showAddPhotoModal) {
    return (
      <UploadPhotosModal
        isMulti
        title="Add Expense's Photos"
        onClose={() => setShowAddPhotoModal(false)}
        includeDescription
        onSubmit={(data) =>
          createExpenseAttachment({
            ...data,
            expenseId: expense.id,
          })
        }
      />
    );
  }
  if (imageModal) {
    return (
      <ImagesViewerModal
        title="Expense's Photos"
        items={[
          {
            id: imageModal.id,
            title: imageModal.description,
            url: imageModal.attachmentUrl,
          },
        ]}
        onClose={() => setImageModal(null)}
        onDelete={isReadOnly ? null : onDeleteImage}
      />
    );
  }

  return (
    <Modal isOpen={true} onClosed={doClose} size={isReadOnly ? "md" : "sm"}>
      <ModalHeader toggle={doClose} className="d-flex justify-content-between">
        {isReadOnly ? "Expense Detail" : "Edit Expense"}
      </ModalHeader>
      {isUpdatingExpense ? (
        <Loader />
      ) : isReadOnly ? (
        renderReadOnlyBody()
      ) : (
        renderEditableBody()
      )}
    </Modal>
  );
};

export default ExpenseModal;
