import React, { useCallback, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  Input, // use reactstrap's <Input> for easier invalid prop usage
} from "reactstrap";

const DeleteModal = ({ isOpen, name, onClose, onDelete, title, isLoading }) => {
  const [invalidInput, setInvalidInput] = useState(false);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setInvalidInput(false); // always reset on each new submit

      const formData = new FormData(event.target);
      const value = formData.get("confirm-delete").trim().toUpperCase();

      if (value === "DELETE") {
        event.target.reset();
        onDelete();
      } else {
        setInvalidInput(true);
      }
    },
    [onDelete]
  );

  return (
    <Modal isOpen={isOpen} size="sm">
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={onClose} className="border-bottom-0 text-lg">
          {title || `Delete ${name}`}
        </ModalHeader>
        <ModalBody>
          <p className="text-danger text-sm">
            This action can not be reversed:
          </p>
          <p className="fw-bold">{name}</p>
          <FormGroup>
            <Label for="confirm-delete">
              Type the word <strong>DELETE</strong> to confirm{" "}
              <span className="text-danger">*</span>
            </Label>
            <Input
              id="confirm-delete"
              name="confirm-delete"
              maxLength="6"
              type="text"
              placeholder="Enter DELETE"
              required
              // Only pass invalid *true* or *false*, ensuring it's strictly boolean
              invalid={invalidInput}
            />
            {/* Conditionally render the feedback so it's removed from the DOM when invalidInput===false */}
            {invalidInput && (
              <FormFeedback>Please type DELETE to confirm.</FormFeedback>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button color="danger" type="submit" disabled={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default DeleteModal;
