import Expense from "./components/Expense";
import CrewsExpenses from "./components/CrewsExpenses";
import CrewWorkDayExpenses from "./components/CrewWorkDayExpenses";
import ExpenseTypes from "./pages/ExpenseTypes";

import { data, helpers, sharedHelper } from "@crewos/shared";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const EXPENSE = "EXPENSE";

const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Expense Types",
    path: `expense-types`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "customers",
    category: "Expenses",
    isSetting: true,
    component: ExpenseTypes,
    order: 2,
  },
];

const setExpenseSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "customers");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: EXPENSE, order: 6 },
    });
  }
};

const Routes = createRoutes(routes);

export {
  Routes as ExpenseRoutes,
  Expense,
  setExpenseSidebarRoutes,
  CrewsExpenses,
  CrewWorkDayExpenses,
};
