import { useMemo } from "react";
import { Row, Col } from "reactstrap";

import { components } from "@crewos/shared";

import Signature from "./Signature";

const { Icon } = components;

const Signatures = ({
  signatures,
  crewMembers,
  configuration,
  crewLead,
  noSignatureReason,
}) => {
  const signedMembers = useMemo(() => {
    if (configuration.signatureRequest === "ALL_CREW") {
      return crewMembers.map((crewMember) => {
        return {
          ...crewMember,
          signature: signatures.find(
            (signature) => signature.employeeId === crewMember.id
          ),
        };
      });
    } else if (configuration.signatureRequest === "ONLY_CREW_LEADER") {
      return [
        {
          ...crewLead,
          signature: signatures.find(
            (signature) => signature.employeeId === crewLead.id
          ),
        },
      ];
    } else {
      return [];
    }
  }, [signatures, crewMembers, crewLead, configuration]);

  return (
    <div className="mt-3 rounded-3 p-2">
      <h5>Signatures:</h5>

      <div className="text-center text-muted">
        {configuration.signatureRequest === "NOT_NEEDED" && (
          <>
            <Icon name="feather" className="fs-1 mx-2" />
            <span className="text-muted">
              Signature is not required for this document.
            </span>
          </>
        )}
        {!noSignatureReason &&
          signatures.length === 0 &&
          configuration.signatureRequest !== "NOT_NEEDED" && (
            <>
              <Icon name="feather" className="fs-1 mx-2" />
              <span className="text-muted">
                This document has not been signed yet.
              </span>
            </>
          )}

        {signedMembers.length > 0 && (
          <Row>
            {signedMembers.map((signedMember) => (
              <Col sm={4}>
                <Signature
                  signatureUrl={signedMember.signature?.signatureUrl}
                  signedAt={signedMember.signature?.createdAt}
                  signedBy={`${signedMember?.firstName} ${signedMember?.lastName}`}
                />
              </Col>
            ))}
          </Row>
        )}
        {noSignatureReason && (
          <Row className="m-2">
            <Col
              className="border border-gray-300 rounded-3 p-4 my-2"
              style={{ borderStyle: "dashed" }}
            >
              <Icon name="message-circle" className="fs-1 mx-2" />
              <span className="fw-semibold">
                Reason for signature not provided:{" "}
              </span>
              <span className="text-muted">{noSignatureReason}</span>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Signatures;
