import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { Badge, Card, CardBody, CardHeader, Col, Container } from "reactstrap";

import { CrewsData } from "@crewos/crews";
import { components, data, sharedHelper } from "@crewos/shared";

import { useGetWorkOrders } from "../api/WorkOrders.hooks";
import WorkOrderStatus from "../components/WorkOrderStatus";
import WorkOrderDetailsTabs from "../components/workOrderDetails/WorkOrderDetailsTabs";

const {
  useWorkOrderDetails,
  WORK_ORDER_DETAILS_ACTIONS,
  WorkOrderDetailsProvider,
  useAuth,
} = data;

const { Icon, Loader } = components;

const WorkOrderDetailsTabsWrapper = () => (
  <CrewsData>
    <WorkOrderDetailsTabs />
  </CrewsData>
);

const WorkOrderDetails = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const navigate = useNavigate();

  const { workOrderId } = useParams();

  const {
    data: workOrder,
    error: workOrderErr,
    isLoading: isLoadingWorkOrder,
    get: getWorkOrder,
  } = useGetWorkOrders();

  useEffect(() => {
    getWorkOrder({ id: workOrderId });
  }, [getWorkOrder, workOrderDetails.refresh, workOrderId]);

  useEffect(() => {
    if (workOrder) {
      setWorkOrderDetails({
        action: WORK_ORDER_DETAILS_ACTIONS.GET_WORK_ORDER_SUCCESS,
        payload: { workOrder },
      });
    }
  }, [workOrder, setWorkOrderDetails]);

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/workorders");
    }
  };

  useEffect(() => {
    if (workOrderErr) {
      navigate("/workorders");
    }
  }, [workOrderErr, navigate]);

  const onSelectDate = (selected) => {
    const date = sharedHelper.formatDate(selected, "YYYY-MM-DD");
    setWorkOrderDetails({
      action: WORK_ORDER_DETAILS_ACTIONS.SELECT_DATE,
      payload: { date },
    });
  };

  const serviceLocationEnabled = useMemo(
    () =>
      authContext.userData
        ? sharedHelper.isSettingEnabled(
            authContext.userData.packages,
            "SERVICE_LOCATIONS_ENABLED"
          )
        : false,
    [authContext.userData]
  );

  return (
    <Container fluid>
      {!workOrderDetails.workOrder || isLoadingWorkOrder ? (
        <Loader />
      ) : (
        <Col className="flex-grow-1 d-flex flex-column">
          <Card className="section-card">
            <CardHeader className="section-header flex-column align-items-start">
              <div className="d-flex align-items-center col-12 px-0">
                <div
                  className="d-flex flex-column justify-content-center cursor-pointer"
                  onClick={goBack}
                  data-testid="back-button"
                >
                  <Icon
                    name="chevron-left"
                    className="me-2 text-muted rounded-circle back-arrow bg-white fs-4"
                  />
                </div>
                <h2 className="mb-0">
                  {workOrderDetails.workOrder.customer?.customerName ||
                    "No customer name provided"}
                </h2>
                <Badge pill color="primary" className="fw-normal ms-2">
                  {workOrderDetails.workOrder.workOrderStatus.name}
                </Badge>
              </div>
              <div className="d-flex align-items-center justify-content-start mt-3 small">
                <div className="d-flex align-items-center">
                  <span className="text-muted">WO#:</span>
                  <span className="ms-1">
                    {workOrderDetails.workOrder.workOrderNumber}
                  </span>
                </div>
                <span className="mx-2">·</span>
                <div className="d-flex align-items-center">
                  <span className="text-muted">Dates:</span>
                  <div className="ms-1">
                    <span
                      className="text-link"
                      onClick={() =>
                        onSelectDate(workOrderDetails.workOrder.startDate)
                      }
                    >
                      {sharedHelper.formatDate(
                        workOrderDetails.workOrder.startDate,
                        "MM/DD/YYYY"
                      )}
                    </span>
                    {" to "}
                    <span
                      className="text-link"
                      onClick={() =>
                        onSelectDate(workOrderDetails.workOrder.endDate)
                      }
                    >
                      {sharedHelper.formatDate(
                        workOrderDetails.workOrder.endDate,
                        "MM/DD/YYYY"
                      )}
                    </span>
                  </div>
                </div>
                {serviceLocationEnabled &&
                workOrderDetails.workOrder.serviceLocation ? (
                  <>
                    <span className="mx-2">·</span>
                    <div className="d-flex align-items-center">
                      <span className="text-muted">Service Location:</span>
                      <span className="ms-1">
                        {workOrderDetails.workOrder.serviceLocation.name}
                      </span>
                    </div>
                  </>
                ) : null}
                {workOrderDetails.workOrder.customerLocation ? (
                  <>
                    <span className="mx-2">·</span>
                    <div className="d-flex align-items-center">
                      <span className="text-muted">Address:</span>
                      <span className="ms-1">
                        {workOrderDetails.workOrder.customerLocation
                          .shipToAddress || "-"}
                      </span>
                    </div>
                  </>
                ) : null}
                {workOrderDetails.workOrder.customerLocation ? (
                  <>
                    <span className="mx-2">·</span>
                    <div className="d-flex align-items-center">
                      <span className="text-muted">Phone:</span>
                      <span className="ms-1">
                        {workOrderDetails.workOrder.customerLocation.phone ||
                          "-"}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
              <WorkOrderStatus />
            </CardHeader>
            <CardBody className="section-body bg-transparent p-0">
              <WorkOrderDetailsTabsWrapper />
            </CardBody>
          </Card>
        </Col>
      )}
    </Container>
  );
};

const WorkOrderDetailsWrapper = (props) => (
  <WorkOrderDetailsProvider>
    <WorkOrderDetails {...props} />
  </WorkOrderDetailsProvider>
);

export default WorkOrderDetailsWrapper;
