import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import moment from "moment";

import { useGetGeneralBilling } from "../../api/Billing.hooks";

import { usePDF, Margin } from "react-to-pdf";

const { MonthSelector, Icon, Loader } = components;

const coreModules = [
  { moduleName: "User Management" },
  { moduleName: "Employee Management" },
  {
    moduleName: "Work Order Management",
  },
  { moduleName: "Customer Management" },
  { moduleName: "Scheduling" },
  { moduleName: "Security" },
  { moduleName: "Offline Mode" },
];

const options = {
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: "letter",
  },
};

const GeneralBilling = () => {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const [refresh, setRefresh] = useState();
  const [downloadPDF, setDownloadPDF] = useState();

  const [month, setMonth] = useState(moment().format("MMM/YY"));

  const { toPDF, targetRef } = usePDF({
    filename: `Billing Summary - ${moment(month, "MMM/YY").format(
      "MMMM, YYYY"
    )}`,
    ...options,
  });

  const {
    data: reportData,
    isLoading,
    get: getGeneralBilling,
  } = useGetGeneralBilling();

  useEffect(() => {
    getGeneralBilling({
      month,
    });
  }, [getGeneralBilling, refresh, month]);

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [reportData, setData]);

  useEffect(() => {
    const doDownloadPDF = async () => {
      sharedHelper.loadingToast("Generating PDF...");
      setTimeout(async () => {
        await toPDF();
        setDownloadPDF(false);
        setIsOpen((prev) => ({
          ...prev.prev,
        }));
        sharedHelper.dismissLoadingToast();
      }, 1000);
    };
    if (downloadPDF && isOpen.coreFeatures && isOpen.users && isOpen.modules) {
      doDownloadPDF();
    }
  }, [toPDF, downloadPDF, isOpen.coreFeatures, isOpen.users, isOpen.modules]);

  const nonCoreModules = useMemo(
    () => (data.modulesData ? data.modulesData.filter((m) => !m.isCore) : []),
    [data]
  );

  const nonCoreModulesTotal = useMemo(
    () => nonCoreModules.reduce((p, c) => p + c[month]?.cost || 0, 0),
    [nonCoreModules, month]
  );

  const totalMonth = useMemo(
    () =>
      (data.totalUsersCost || 0) + (data.baseCost || 0) + nonCoreModulesTotal,
    [data, nonCoreModulesTotal]
  );

  const onDownloadPDF = async () => {
    setIsOpen((prev) => ({
      coreFeatures: true,
      users: true,
      modules: true,
      prev,
    }));
    setDownloadPDF(true);
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Billing Summary Report</h2>
          </div>
          <div className="d-flex align-items-center justify-content-end col-6">
            <MonthSelector
              className="me-3"
              loading={isLoading}
              monthStart={month}
              setMonthStart={setMonth}
            />
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={onDownloadPDF}
              data-testid="download-button"
            >
              <Icon name="download" />
            </Button>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </CardHeader>
        <div ref={targetRef}>
          <CardBody className="section-body">
            {isLoading ? (
              <Loader />
            ) : (
              <ListGroup>
                {downloadPDF ? (
                  <div className="mb-3">
                    <ListGroupItem className="d-flex flex-column p-0" tag="div">
                      <div className="d-flex justify-content-center align-items-center fw-bold bg-graylight px-3 py-2">
                        <div className="text-center">
                          Billing Summary -{" "}
                          {moment(month, "MMM/YY").format("MMMM, YYYY")}
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex flex-column p-0" tag="div">
                      <div
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            coreFeatures: !isOpen.coreFeatures,
                          })
                        }
                        className={`general-report-item d-flex justify-content-between align-items-center fw-bold ${
                          isOpen.coreFeatures ? "bg-graylight" : ""
                        } px-3 py-2 cursor-pointer`}
                      >
                        <div className="d-flex align-items-center">
                          <span className="text-start">Core Features</span>
                          <small className="ms-1 text-muted">
                            ({coreModules.length || 0})
                          </small>
                        </div>
                        <div className="d-flex align-items-center justify-content-end col-3 px-0">
                          <span className="text-end">
                            {sharedHelper.formatCurrency(data.baseCost || 0)}
                          </span>
                          {!downloadPDF ? (
                            <Icon
                              name="chevron-right"
                              className={`mx-2 rotate ${
                                isOpen.coreFeatures ? "rotate-90" : ""
                              }`}
                            />
                          ) : null}
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex flex-column p-0" tag="div">
                      <div
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            modules: !isOpen.modules,
                          })
                        }
                        className={`general-report-item d-flex justify-content-between align-items-center fw-bold ${
                          isOpen.modules ? "bg-graylight" : ""
                        } px-3 py-2 cursor-pointer`}
                      >
                        <div className="d-flex align-items-center">
                          <span className="text-start">Modules</span>
                          <small className="ms-1 text-muted">
                            ({nonCoreModules.length})
                          </small>
                        </div>
                        <div className="d-flex align-items-center justify-content-end col-3 px-0">
                          <span className="text-end">
                            {sharedHelper.formatCurrency(nonCoreModulesTotal)}
                          </span>
                          {!downloadPDF ? (
                            <Icon
                              name="chevron-right"
                              className={`mx-2 rotate ${
                                isOpen.modules ? "rotate-90" : ""
                              }`}
                            />
                          ) : null}
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex flex-column p-0" tag="div">
                      <div
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            users: !isOpen.users,
                          })
                        }
                        className={`general-report-item d-flex justify-content-between align-items-center fw-bold ${
                          isOpen.users ? "bg-graylight" : ""
                        } px-3 py-2 cursor-pointer`}
                      >
                        <div className="d-flex align-items-center">
                          <span className="text-start">Users</span>
                          <small className="ms-1 text-muted">
                            ({data.usersData?.length || 0})
                          </small>
                        </div>
                        <div className="d-flex align-items-center justify-content-end col-3 px-0">
                          <span className="text-end">
                            {sharedHelper.formatCurrency(
                              data.totalUsersCost || 0
                            )}
                          </span>
                          {!downloadPDF ? (
                            <Icon
                              name="chevron-right"
                              className={`mx-2 rotate ${
                                isOpen.users ? "rotate-90" : ""
                              }`}
                            />
                          ) : null}
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex flex-column p-0" tag="div">
                      <div className="d-flex justify-content-between align-items-center fw-bold bg-graylight px-3 py-2">
                        <span className="text-start">Total</span>
                        <div className="d-flex align-items-center justify-content-end col-3 px-0">
                          <span className="text-end">
                            {sharedHelper.formatCurrency(totalMonth)}
                          </span>
                          {!downloadPDF ? (
                            <Icon
                              name="chevron-right"
                              className={`text-graylight mx-2 rotate ${
                                isOpen.users ? "rotate-90" : ""
                              }`}
                            />
                          ) : null}
                        </div>
                      </div>
                    </ListGroupItem>
                  </div>
                ) : null}
                <ListGroupItem className="d-flex flex-column p-0" tag="div">
                  <div className="d-flex justify-content-center align-items-center fw-bold bg-graylight px-3 py-2">
                    {downloadPDF ? (
                      <div className="text-center">Line Details</div>
                    ) : (
                      <div className="text-center">
                        Billing Summary -{" "}
                        {moment(month, "MMM/YY").format("MMMM, YYYY")}
                      </div>
                    )}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex flex-column p-0" tag="div">
                  <div
                    onClick={() =>
                      setIsOpen({
                        ...isOpen,
                        coreFeatures: !isOpen.coreFeatures,
                      })
                    }
                    className={`general-report-item d-flex justify-content-between align-items-center fw-bold ${
                      isOpen.coreFeatures ? "bg-graylight" : ""
                    } px-3 py-2 cursor-pointer`}
                  >
                    <div className="d-flex align-items-center">
                      <span className="text-start">Core Features</span>
                      <small className="ms-1 text-muted">
                        ({coreModules.length || 0})
                      </small>
                    </div>
                    <div className="d-flex align-items-center justify-content-end col-3 px-0">
                      <span className="text-end">
                        {sharedHelper.formatCurrency(data.baseCost || 0)}
                      </span>
                      {!downloadPDF ? (
                        <Icon
                          name="chevron-right"
                          className={`mx-2 rotate ${
                            isOpen.coreFeatures ? "rotate-90" : ""
                          }`}
                        />
                      ) : null}
                    </div>
                  </div>
                  <Collapse isOpen={isOpen.coreFeatures} className="px-4">
                    <div className="d-flex flex-column">
                      {coreModules.map((module, index) => {
                        const isLast = index === coreModules.length - 1;
                        return (
                          <div
                            key={index}
                            className={`d-flex align-items-center justify-content-between mt-1 py-2 border-${
                              isLast ? "" : "bottom"
                            }`}
                          >
                            <span>{module.moduleName}</span>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </ListGroupItem>
                <ListGroupItem className="d-flex flex-column p-0" tag="div">
                  <div
                    onClick={() =>
                      setIsOpen({
                        ...isOpen,
                        modules: !isOpen.modules,
                      })
                    }
                    className={`general-report-item d-flex justify-content-between align-items-center fw-bold ${
                      isOpen.modules ? "bg-graylight" : ""
                    } px-3 py-2 cursor-pointer`}
                  >
                    <div className="d-flex align-items-center">
                      <span className="text-start">Modules</span>
                      <small className="ms-1 text-muted">
                        ({nonCoreModules.length})
                      </small>
                    </div>
                    <div className="d-flex align-items-center justify-content-end col-3 px-0">
                      <span className="text-end">
                        {sharedHelper.formatCurrency(nonCoreModulesTotal)}
                      </span>
                      {!downloadPDF ? (
                        <Icon
                          name="chevron-right"
                          className={`mx-2 rotate ${
                            isOpen.modules ? "rotate-90" : ""
                          }`}
                        />
                      ) : null}
                    </div>
                  </div>
                  <Collapse isOpen={isOpen.modules} className="px-4">
                    <div className="d-flex flex-column">
                      {nonCoreModules.map((module, index) => {
                        const isLast = index === nonCoreModules.length - 1;
                        return (
                          <div
                            key={index}
                            className={`d-flex align-items-center justify-content-between mt-1 py-2 border-${
                              isLast ? "" : "bottom"
                            }`}
                          >
                            <span>{module.moduleName}</span>
                            <span>
                              {sharedHelper.formatCurrency(
                                module[month]?.cost || 0
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </ListGroupItem>
                <ListGroupItem className="d-flex flex-column p-0" tag="div">
                  <div
                    onClick={() =>
                      setIsOpen({
                        ...isOpen,
                        users: !isOpen.users,
                      })
                    }
                    className={`general-report-item d-flex justify-content-between align-items-center fw-bold ${
                      isOpen.users ? "bg-graylight" : ""
                    } px-3 py-2 cursor-pointer`}
                  >
                    <div className="d-flex align-items-center">
                      <span className="text-start">Users</span>
                      <small className="ms-1 text-muted">
                        ({data.usersData?.length || 0})
                      </small>
                    </div>
                    <div className="d-flex align-items-center justify-content-end col-3 px-0">
                      <span className="text-end">
                        {sharedHelper.formatCurrency(data.totalUsersCost || 0)}
                      </span>
                      {!downloadPDF ? (
                        <Icon
                          name="chevron-right"
                          className={`mx-2 rotate ${
                            isOpen.users ? "rotate-90" : ""
                          }`}
                        />
                      ) : null}
                    </div>
                  </div>
                  <Collapse isOpen={isOpen.users} className="px-4">
                    <div className="d-flex flex-column">
                      {data.usersData?.map((user, index) => {
                        const isLast = index === data.usersData.length - 1;
                        return (
                          <div
                            key={index}
                            className={`d-flex align-items-center justify-content-between mt-1 py-2 border-${
                              isLast ? "" : "bottom"
                            }`}
                          >
                            <span>{user.userName}</span>
                            <span>
                              {sharedHelper.formatCurrency(
                                user[month]?.cost || 0
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </ListGroupItem>
                <ListGroupItem className="d-flex flex-column p-0" tag="div">
                  <div className="d-flex justify-content-between align-items-center fw-bold bg-graylight px-3 py-2">
                    <span className="text-start">Total</span>
                    <div className="d-flex align-items-center justify-content-end col-3 px-0">
                      <span className="text-end">
                        {sharedHelper.formatCurrency(totalMonth)}
                      </span>
                      {!downloadPDF ? (
                        <Icon
                          name="chevron-right"
                          className={`text-graylight mx-2 rotate ${
                            isOpen.users ? "rotate-90" : ""
                          }`}
                        />
                      ) : null}
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            )}
          </CardBody>
        </div>
      </Card>
    </Container>
  );
};

export default GeneralBilling;
