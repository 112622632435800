import React, { useEffect, useMemo } from "react";
import { Nav, TabContent, TabPane } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";

import { data, sharedHelper } from "@crewos/shared";

import WorkOrderJobDetails from "./WorkOrderJobDetails";
import {
  WorkOrderCrews,
  WorkOrderCrewsTimes,
  WorkOrderCrewsEquipments,
  WorkOrderCrewsExpenses,
  WorkOrderCrewsPerDiems,
} from "@crewos/crews";

import {
  SmartReports,
  SmartReport,
  LaborByEmployeeReport,
  JobLaborReport,
} from "@crewos/reports";

import {
  WorkOrderInspectionsList,
  WorkOrderAssignedTemplates as WorkOrderInspectionsAssignedTemplates,
} from "@crewos/inspections";

import {
  WorkOrderSafetyList,
  WorkOrderAssignedTemplates as WorkOrderSafetyAssignedTemplates,
} from "@crewos/safety";

import ClosingDetails from "../workOrderCloseOut/ClosingDetails";
import WorkOrderJobDescription from "./WorkOrderJobDescription";
import NavItemTab from "./NavItemTab";

const {
  useAuth,
  useWorkOrderDetails,
  WORK_ORDER_DETAILS_ACTIONS,
  WORK_ORDER_DETAILS_TABS,
} = data;

const SMART_REPORTS_READ_ONLY = "SMART_REPORTS_READ_ONLY";
const SMART_REPORTS_IN_SIDEBAR = "SMART_REPORTS_IN_SIDEBAR";
const WO_CLOSE_OUT_ENABLED = "WO_CLOSE_OUT_ENABLED";
const SINGLE_CREW = "SINGLE_CREW";
const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const WorkOrderDetailsTabs = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const navigate = useNavigate();
  const { workOrderId, tab } = useParams();

  const isSuperAdmin = useMemo(
    () =>
      sharedHelper.userHasScope(authContext.userData?.role, [
        IS_SUPER_ADMIN_USER,
      ]),
    [authContext.userData]
  );

  const isSingleCrewEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        SINGLE_CREW
      ),
    [authContext.userData]
  );

  const directAssignationEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        DIRECT_ASSIGNATION
      ),
    [authContext.userData]
  );

  const smartReportsInSidebarEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        SMART_REPORTS_IN_SIDEBAR
      ) || isSuperAdmin,
    [authContext.userData, isSuperAdmin]
  );

  const smartReportsReadOnlyEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        SMART_REPORTS_READ_ONLY
      ) && !isSuperAdmin,
    [authContext.userData, isSuperAdmin]
  );

  const woCloseOutEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        WO_CLOSE_OUT_ENABLED
      ),
    [authContext.userData]
  );

  const smartReports = useMemo(
    () =>
      authContext.userData.smartReports.filter(
        (report) => report.context === "WORKORDER"
      ),
    [authContext.userData]
  );

  const timeTabEnabled = useMemo(
    () =>
      sharedHelper.isPackageEnabled(authContext.userData?.packages, [
        "worktimes",
        "traveltimes",
      ]),
    [authContext.userData]
  );

  const perdiemsTabEnabled = useMemo(
    () =>
      sharedHelper.isPackageEnabled(authContext.userData?.packages, "perdiems"),
    [authContext.userData]
  );

  const expensesTabEnabled = useMemo(
    () =>
      sharedHelper.isPackageEnabled(authContext.userData?.packages, "expenses"),
    [authContext.userData]
  );

  const equipmentsTabEnabled = useMemo(
    () =>
      sharedHelper.isPackageEnabled(
        authContext.userData?.packages,
        "equipments"
      ),
    [authContext.userData]
  );

  const inspectionsTabEnabled = useMemo(
    () =>
      sharedHelper.isPackageEnabled(
        authContext.userData?.packages,
        "inspections"
      ),
    [authContext.userData]
  );

  const safetyTabEnabled = useMemo(
    () =>
      sharedHelper.isPackageEnabled(authContext.userData?.packages, "safety"),
    [authContext.userData]
  );

  const reportsTabEnabled = useMemo(
    () =>
      sharedHelper.isPackageEnabled(authContext.userData?.packages, "reports"),
    [authContext.userData]
  );

  // Synchronize the global state with the URL. If there's no "tab" in the URL, go to the default tab.
  useEffect(() => {
    if (!tab) {
      navigate(
        `/workorders/details/${workOrderId}/${WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB}`,
        { replace: true }
      );
    } else if (workOrderDetails.activeTab !== tab) {
      setWorkOrderDetails({
        action: WORK_ORDER_DETAILS_ACTIONS.CHANGE_TAB,
        payload: { tab },
      });
    }
  }, [
    tab,
    workOrderId,
    workOrderDetails.activeTab,
    navigate,
    setWorkOrderDetails,
  ]);

  // When a tab is clicked, navigate directly so the URL updates immediately.
  const handleToggle = (newTab) => {
    if (newTab !== tab) {
      navigate(`/workorders/details/${workOrderId}/${newTab}`);
    }
  };

  return (
    <div className="tab tab-vertical h-100">
      <Nav tabs className="cursor-pointer me-3 min-width-200">
        <NavItemTab
          workOrderDetails={workOrderDetails}
          toggle={handleToggle}
          label="Work Performed"
          tab={WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB}
        />
        <NavItemTab
          workOrderDetails={workOrderDetails}
          toggle={handleToggle}
          label="Job Description"
          tab={WORK_ORDER_DETAILS_TABS.JOB_DESCRIPTION_DETAILS_TAB}
        />
        <NavItemTab
          workOrderDetails={workOrderDetails}
          toggle={handleToggle}
          label={
            directAssignationEnabled
              ? "Assignments"
              : isSingleCrewEnabled
              ? "Crew"
              : "Crews"
          }
          tab={WORK_ORDER_DETAILS_TABS.CREW_TAB}
        />
        {timeTabEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Time"
            tab={WORK_ORDER_DETAILS_TABS.TIME_TAB}
          />
        )}
        {perdiemsTabEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Per Diem"
            tab={WORK_ORDER_DETAILS_TABS.PER_DIEMS_TAB}
          />
        )}
        {expensesTabEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Expenses"
            tab={WORK_ORDER_DETAILS_TABS.EXPENSES_TAB}
          />
        )}
        {equipmentsTabEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Equipment"
            tab={WORK_ORDER_DETAILS_TABS.EQUIPMENTS_TAB}
          />
        )}
        {inspectionsTabEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Inspections"
            tab={WORK_ORDER_DETAILS_TABS.INSPECTIONS_TAB}
            childs={[
              {
                label: "Inspections List",
                tab: WORK_ORDER_DETAILS_TABS.INSPECTIONS_LIST_TAB,
              },
              {
                label: "Assigned Templates",
                tab: WORK_ORDER_DETAILS_TABS.INSPECTIONS_TEMPLATE_TAB,
              },
            ]}
          />
        )}
        {safetyTabEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Safety"
            tab={WORK_ORDER_DETAILS_TABS.SAFETY_TAB}
            childs={[
              {
                label: "Safety List",
                tab: WORK_ORDER_DETAILS_TABS.SAFETY_LIST_TAB,
              },
              {
                label: "Assigned Templates",
                tab: WORK_ORDER_DETAILS_TABS.SAFETY_TEMPLATE_TAB,
              },
            ]}
          />
        )}
        {reportsTabEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Reports"
            tab={WORK_ORDER_DETAILS_TABS.REPORTS_TAB}
            childs={[
              {
                label: "Labor by Employee",
                tab: WORK_ORDER_DETAILS_TABS.LABOR_BY_EMPLOYEE_REPORT_TAB,
              },
              {
                label: "Labor Cost",
                tab: WORK_ORDER_DETAILS_TABS.LABOR_REPORT_TAB,
              },
              smartReportsInSidebarEnabled
                ? {
                    label: `${
                      smartReportsReadOnlyEnabled ? "Preset" : "Smart"
                    } Reports`,
                    tab: WORK_ORDER_DETAILS_TABS.SMART_REPORTS_TAB,
                  }
                : false,
              ...smartReports
                .filter((report) => report.showSidebar)
                .map((report) => ({
                  label: report.name,
                  tab: report.id,
                  key: report.id,
                })),
            ].filter(Boolean)}
          />
        )}
        {woCloseOutEnabled && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={handleToggle}
            label="Closing Details"
            tab={WORK_ORDER_DETAILS_TABS.CLOSING_DETAILS_TAB}
          />
        )}
      </Nav>

      <TabContent
        activeTab={workOrderDetails.activeTab}
        className="h-100 p-0 box-shadow-none"
      >
        {/* Work Performed */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB && <WorkOrderJobDetails />}
        </TabPane>

        {/* Job Description */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.JOB_DESCRIPTION_DETAILS_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.JOB_DESCRIPTION_DETAILS_TAB && (
            <WorkOrderJobDescription />
          )}
        </TabPane>

        {/* Crews / Assignments */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.CREW_TAB}>
          {workOrderDetails.activeTab === WORK_ORDER_DETAILS_TABS.CREW_TAB && (
            <WorkOrderCrews />
          )}
        </TabPane>

        {/* Time */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.TIME_TAB}>
          {workOrderDetails.activeTab === WORK_ORDER_DETAILS_TABS.TIME_TAB && (
            <WorkOrderCrewsTimes />
          )}
        </TabPane>

        {/* Expenses */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.EXPENSES_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.EXPENSES_TAB && <WorkOrderCrewsExpenses />}
        </TabPane>

        {/* Per Diems */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.PER_DIEMS_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.PER_DIEMS_TAB && <WorkOrderCrewsPerDiems />}
        </TabPane>

        {/* Equipment */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.EQUIPMENTS_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.EQUIPMENTS_TAB && (
            <WorkOrderCrewsEquipments />
          )}
        </TabPane>

        {/* Smart Reports (parent tab) */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.SMART_REPORTS_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.SMART_REPORTS_TAB && (
            <SmartReports
              workOrderId={workOrderDetails.workOrder.id}
              onSelectReport={(nextTab) =>
                setWorkOrderDetails({
                  action: WORK_ORDER_DETAILS_ACTIONS.CHANGE_TAB,
                  payload: { tab: nextTab },
                })
              }
            />
          )}
        </TabPane>

        {/* Inspections */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.INSPECTIONS_LIST_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.INSPECTIONS_LIST_TAB && (
            <WorkOrderInspectionsList />
          )}
        </TabPane>
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.INSPECTIONS_TEMPLATE_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.INSPECTIONS_TEMPLATE_TAB && (
            <WorkOrderInspectionsAssignedTemplates />
          )}
        </TabPane>

        {/* Safety */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.SAFETY_LIST_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.SAFETY_LIST_TAB && <WorkOrderSafetyList />}
        </TabPane>
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.SAFETY_TEMPLATE_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.SAFETY_TEMPLATE_TAB && (
            <WorkOrderSafetyAssignedTemplates />
          )}
        </TabPane>

        {/* Base Reports */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.LABOR_BY_EMPLOYEE_REPORT_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.LABOR_BY_EMPLOYEE_REPORT_TAB && (
            <LaborByEmployeeReport
              workOrderId={workOrderDetails.workOrder.id}
              endDate={workOrderDetails.workOrder.endDate}
            />
          )}
        </TabPane>
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.LABOR_REPORT_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.LABOR_REPORT_TAB && (
            <JobLaborReport
              workOrderId={workOrderDetails.workOrder.id}
              endDate={workOrderDetails.workOrder.endDate}
            />
          )}
        </TabPane>

        {/* Dynamically mapped reports */}
        {smartReports.map((report) => (
          <TabPane key={report.id} tabId={report.id}>
            {workOrderDetails.activeTab === report.id && (
              <SmartReport
                workOrderId={workOrderDetails.workOrder.id}
                reportId={report.id}
                onDelete={() =>
                  setWorkOrderDetails({
                    action: WORK_ORDER_DETAILS_ACTIONS.CHANGE_TAB,
                    payload: {
                      tab: WORK_ORDER_DETAILS_TABS.SMART_REPORTS_TAB,
                    },
                  })
                }
              />
            )}
          </TabPane>
        ))}

        {/* Closing Details */}
        <TabPane tabId={WORK_ORDER_DETAILS_TABS.CLOSING_DETAILS_TAB}>
          {workOrderDetails.activeTab ===
            WORK_ORDER_DETAILS_TABS.CLOSING_DETAILS_TAB && (
            <ClosingDetails workOrderId={workOrderDetails.workOrder.id} />
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default WorkOrderDetailsTabs;
