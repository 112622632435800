import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Form,
} from "reactstrap";

import {
  useCreateExpenseType,
  useUpdateExpenseType,
} from "../api/ExpenseTypes.hooks";

import { components, sharedHelper } from "@crewos/shared";

const { Loader } = components;

const ExpenseTypeModal = ({ expenseType = {}, onSubmit, onClose }) => {
  // State variables
  const [name, setName] = useState(expenseType.name || "");

  // Hooks
  const {
    isLoading: isLoadingCreateExpenseType,
    mutate: createExpenseType,
    data: createExpenseTypeData,
  } = useCreateExpenseType();

  const {
    isLoading: isLoadingUpdateExpenseType,
    update: updateExpenseType,
    data: updateExpenseTypeData,
  } = useUpdateExpenseType();

  // Effects
  useEffect(() => {
    if (createExpenseTypeData) {
      sharedHelper.successToast("Expense type created");
      onSubmit?.();
    }
  }, [createExpenseTypeData, onSubmit]);

  useEffect(() => {
    if (updateExpenseTypeData) {
      sharedHelper.successToast("Expense type saved");
      onSubmit?.();
    }
  }, [updateExpenseTypeData, onSubmit]);

  // Submit
  const doSubmit = async (e) => {
    e.preventDefault();
    const payload = { name };

    if (expenseType.id) {
      await updateExpenseType({ id: expenseType.id, ...payload });
    } else {
      await createExpenseType(payload);
    }
  };

  return (
    <Modal isOpen={true} toggle={onClose} size="md">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {expenseType.id ? "Edit Expense Type" : "Add Expense Type"}
      </ModalHeader>
      <Form data-testid="expense-type-form" onSubmit={doSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Name</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="50"
              type="text"
              placeholder="Enter a name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          {isLoadingCreateExpenseType || isLoadingUpdateExpenseType ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-between">
              <Button color="white" onClick={onClose}>
                Cancel
              </Button>
              <Button className="ms-2" color="primary" type="submit">
                Confirm
              </Button>
            </div>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ExpenseTypeModal;
