import React, { useState, useCallback, useEffect } from "react";
import { Button, Input } from "reactstrap";
import cn from "classnames";
import Icon from "./Icon";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const InputEmail = ({
  onChange,
  className,
  emailContainerClassName,
  disabled,
  defaultValue,
  ...props
}) => {
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (defaultValue) {
      setEmails(defaultValue);
    }
  }, [defaultValue]);

  const handleAddEmail = useCallback(() => {
    // If disabled, do nothing
    if (disabled) return;

    // Add only if the current input text matches the email regex
    if (EMAIL_REGEX.test(inputValue)) {
      const newEmails = Array.from(new Set([...emails, inputValue]));
      setEmails(newEmails);
      onChange?.(newEmails);
      setInputValue("");
    }
  }, [disabled, emails, inputValue, onChange]);

  const handleRemoveEmail = useCallback(
    (email) => {
      if (disabled) return;

      const newEmails = emails.filter((e) => e !== email);
      setEmails(newEmails);
      onChange?.(newEmails);
    },
    [disabled, emails, onChange]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <Input
          type="email"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          disabled={disabled}
          className={cn("w-100", className)}
          {...props}
        />
        <Button
          className="ms-2"
          size="sm"
          color="primary"
          onClick={handleAddEmail}
        >
          Add
        </Button>
      </div>

      {!disabled && (
        <div className={cn("d-flex flex-wrap gap-2", emailContainerClassName)}>
          {emails.map((email, index) => (
            <div
              key={`${email}-${index}`}
              className="badge text-bg-secondary py-2 px-3 rounded-pill fs-5 fw-normal"
            >
              {email}
              <Icon
                name="x-circle"
                className="ms-2 text-dark cursor-pointer"
                onClick={() => handleRemoveEmail(email)}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default InputEmail;
