import React, { useEffect, useCallback, useMemo } from "react";
import { components, data, sharedHelper } from "@crewos/shared";
import {
  useGetQuestionTypes,
  useUpdateOrCreateTemplateFull,
} from "../../api/Inspections.hooks";
import { useNavigate } from "react-router-dom";

const { FormTemplate, Loader } = components;
const { FormTemplateProvider } = data;

const CreateTemplates = () => {
  const {
    data: questionTypes,
    get: getQuestionTypes,
    isLoading: isQuestionTypesLoading,
  } = useGetQuestionTypes();

  const {
    data: templateSavedData,
    mutate: saveTemplate,
    isLoading: isSavingTemplate,
    error: saveTemplateError,
  } = useUpdateOrCreateTemplateFull();

  const navigate = useNavigate();

  const handleSave = useCallback(
    async (template, errors, failedUploads) => {
      if (errors && Object.keys(errors).length > 0) {
        sharedHelper.warningToast("Complete all required fields");
        return;
      }
      if (failedUploads) {
        sharedHelper.warningToast("Failed to upload guidance attachments");
        return;
      }
      if (template) {
        await saveTemplate(template);
      }
    },
    [saveTemplate]
  );

  const handleSaveTemplate = useCallback(
    async (template) => {
      const { data, errors, failedUploads } = template;
      if (errors && Object.keys(errors).length > 0) {
        sharedHelper.warningToast("Complete all required fields");
        return;
      }
      if (failedUploads) {
        sharedHelper.warningToast("Failed to upload guidance attachments");
        return;
      }
      await handleSave({ ...data, status: "DRAFT" }, errors, failedUploads);
    },
    [handleSave]
  );

  const handlePublishTemplate = useCallback(
    async (template) => {
      const { data, errors, failedUploads } = template;
      if (errors && Object.keys(errors).length > 0) {
        sharedHelper.warningToast("Complete all required fields");
        return;
      }
      if (failedUploads) {
        sharedHelper.warningToast("Failed to upload guidance attachments");
        return;
      }
      await handleSave({ ...data, status: "PUBLISHED" }, errors, failedUploads);
    },
    [handleSave]
  );

  useEffect(() => {
    if (templateSavedData && !saveTemplateError) {
      sharedHelper.successToast("Template saved");
      navigate(`../edit/${templateSavedData?.data?.id}`, {
        relative: "path",
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSavedData, navigate, saveTemplateError]);

  useEffect(() => {
    getQuestionTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSuccessSaved = useMemo(() => {
    return templateSavedData && !saveTemplateError;
  }, [templateSavedData, saveTemplateError]);

  if (isQuestionTypesLoading) {
    return <Loader />;
  }

  return (
    <FormTemplateProvider>
      <FormTemplate
        isReadOnly={false}
        data={null}
        questionTypes={questionTypes}
        onSaveTemplate={handleSaveTemplate}
        onPublishTemplate={handlePublishTemplate}
        isLoadingSaveTemplate={isSavingTemplate}
        prefix="inspection"
        isSuccessSaved={isSuccessSaved}
      />
    </FormTemplateProvider>
  );
};

export default CreateTemplates;
