import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { useGenerateSmartReport } from "../api/Reports.hooks";
import NewSmartReportModal from "../components/NewSmartReportModal";
import SaveSmartReportModal from "../components/SaveSmartReportModal";
import SelectSmartReportModal from "../components/SelectSmartReportModal";
import SmartReportDetailModal from "../components/SmartReportDetailModal";
import { getColumns, isSmartReportsReadOnly } from "../helpers/smartReports";

import "../../assets/scss/smartReport.scss";

const { Icon, Loader, TooltipItem } = components;

const { useAuth } = data;

const { AdvanceTable } = components;

const SmartReports = ({ workOrderId, onSelectReport }) => {
  const [authContext] = useAuth();

  const navigate = useNavigate();

  const isReadOnly = useMemo(
    () => isSmartReportsReadOnly(authContext.userData),
    [authContext.userData]
  );

  const [report, setReport] = useState({ data: [] });
  const [refresh, setRefresh] = useState();

  const [selectedReportId, setSelectedReportId] = useState();
  const [prompt, setPrompt] = useState();
  const [query, setQuery] = useState();

  const [newSmartReportModal, setNewSmartReportModal] = useState();
  const [saveSmartReportModal, setSaveSmartReportModal] = useState();
  const [selectSmartReportModal, setSelectSmartReportModal] = useState();
  const [smartReportDetailModal, setSmartReportDetailModal] = useState();

  const {
    data: generateReportData,
    isLoading: isLoadingGenerateReport,
    mutate: generateReport,
    error: generateReportError,
  } = useGenerateSmartReport();

  useEffect(() => {
    if (selectedReportId) {
      if (onSelectReport) {
        onSelectReport(selectedReportId);
      } else {
        navigate(`/reports/smart/${selectedReportId}?back=true`);
      }
    }
  }, [selectedReportId, navigate, workOrderId, onSelectReport]);

  useEffect(() => {
    if (prompt || query) {
      generateReport({ prompt, query, workOrderId });
    }
  }, [generateReport, prompt, query, refresh, workOrderId]);

  useEffect(() => {
    if (generateReportError) {
      sharedHelper.errorToast(generateReportError);
      setReport({ data: [] });
    }
  }, [setReport, generateReportError]);

  useEffect(() => {
    if (generateReportData) {
      const result = generateReportData.data;
      const attributes = Object.keys(result.data[0] || []);
      const data = result.data.flatMap((d) => d);
      const hasError = false;
      data.some((d) =>
        attributes.some(
          (a) =>
            typeof d[a] !== "string" &&
            typeof d[a] !== "number" &&
            d[a] !== null
        )
      );
      if (hasError) {
        sharedHelper.errorToast(
          `There was an error generating the report, please try again or try with a different prompt.`
        );
        setReport({ data: [] });
      } else {
        setReport(result);
      }
    }
  }, [setReport, generateReportData]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader
          className={`section-header ${
            workOrderId ? "border-radius-default" : ""
          }`}
        >
          <div className="text-dark flex-grow-1 d-flex align-items-start flex-column">
            <div className="d-flex align-items-center w-100">
              <h2 className="mb-0">
                {workOrderId ? "" : isReadOnly ? "Preset " : "Smart "}
                Reports
              </h2>
              {!isReadOnly ? (
                <div className="ms-2">
                  <TooltipItem
                    id="tooltip-1"
                    title="Build your own AI generated reports using natural language"
                  >
                    <Icon name="info" className="text-primary" />
                  </TooltipItem>
                </div>
              ) : null}
            </div>
            {report && report.name ? (
              <div className="d-flex align-items-center w-100">
                <span
                  className="text-link"
                  onClick={() => setSmartReportDetailModal(true)}
                >
                  {report.name}
                </span>
                {!isLoadingGenerateReport ? (
                  <span className="text-muted ms-2">
                    ({report.data.length})
                  </span>
                ) : null}
              </div>
            ) : prompt ? (
              <div className="d-flex align-items-center w-100">
                <i style={{ maxWidth: "75%" }}>{prompt}</i>
                {!isLoadingGenerateReport ? (
                  <span className="text-muted ms-2">
                    ({report.data.length})
                  </span>
                ) : null}
              </div>
            ) : query ? (
              <div className="d-flex align-items-center w-100 pe-4">
                <code>{query}</code>
                {!isLoadingGenerateReport ? (
                  <span className="text-muted ms-2">
                    ({report.data.length})
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="d-flex align-items-center justify-content-end flex-shrink-0">
            {!isReadOnly ? (
              <Button
                disabled={isLoadingGenerateReport}
                className="me-3"
                color="primary"
                onClick={() => setNewSmartReportModal(true)}
              >
                New Report
              </Button>
            ) : null}
            <Button
              disabled={isLoadingGenerateReport}
              className="me-3"
              color="primary"
              onClick={() => setSelectSmartReportModal(true)}
            >
              Select Report
            </Button>
            {!isReadOnly && (prompt || query) && !generateReportError ? (
              <Button
                disabled={isLoadingGenerateReport}
                className="me-3"
                color="primary"
                onClick={() => setSaveSmartReportModal(true)}
              >
                Save Report
              </Button>
            ) : null}
            {prompt || query ? (
              <Button
                disabled={isLoadingGenerateReport}
                size="sm"
                className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                data-testid="refresh-button"
                onClick={() => setRefresh((prev) => !prev)}
              >
                <Icon name="refresh-cw" />
              </Button>
            ) : null}
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
          </div>
        </CardHeader>
        <CardBody className={`section-body ${workOrderId ? "mx-0" : ""}`}>
          {isLoadingGenerateReport ? (
            <Loader />
          ) : !report.query ? (
            isReadOnly ? (
              <div className="text-center text-muted">
                Start by selecting a report
              </div>
            ) : (
              <div className="text-center text-muted">
                Start by generating a new report or selecting an existing one
              </div>
            )
          ) : report.data.length ? (
            <div className="d-flex flex-column" key={refresh}>
              <AdvanceTable
                columnsSortable={true}
                columns={getColumns(report)}
                data={report.data}
                pageSize={report.data.length}
                exportable
                exportName={report.name || "custom.csv"}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                }}
              />
            </div>
          ) : (
            <div className="text-center text-muted small">
              No data available in the report
            </div>
          )}
        </CardBody>
      </Card>
      {newSmartReportModal ? (
        <NewSmartReportModal
          context={workOrderId ? "Work Order" : "Global"}
          onSubmit={(data, from) => {
            if (from === "prompt") {
              setPrompt(data);
              setQuery();
            } else {
              setPrompt();
              setQuery(data);
            }
            setNewSmartReportModal();
            setSelectedReportId();
          }}
          onClose={() => setNewSmartReportModal()}
        />
      ) : saveSmartReportModal ? (
        <SaveSmartReportModal
          report={report}
          workOrderId={workOrderId}
          onSubmit={(report) => {
            setSaveSmartReportModal();
            setSelectedReportId(report.id);
            setPrompt();
            setQuery();
            setRefresh(!refresh);
          }}
          onClose={() => setSaveSmartReportModal()}
        />
      ) : selectSmartReportModal ? (
        <SelectSmartReportModal
          isReadOnly={isReadOnly}
          workOrderId={workOrderId}
          selectedReportId={report?.id}
          onSubmit={(id) => {
            setSelectSmartReportModal();
            setSelectedReportId(id);
            setPrompt();
            setQuery();
          }}
          onClose={() => setSelectSmartReportModal()}
        />
      ) : smartReportDetailModal ? (
        <SmartReportDetailModal
          isReadOnly={isReadOnly}
          report={report}
          onSubmit={() => {
            setSmartReportDetailModal();
            setRefresh(!refresh);
          }}
          onClose={() => setSmartReportDetailModal()}
        />
      ) : null}
    </Container>
  );
};

export default SmartReports;
