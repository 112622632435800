import React, { useCallback, useEffect, useMemo, useState } from "react";

import WorkPerformedWorkDay from "./WorkPerformedWorkDay";

import { data, components, sharedHelper } from "@crewos/shared";

import WorkPerformedCards from "./WorkPerformedCards";

import { Button, Card, CardBody } from "reactstrap";

import moment from "moment";
import axios from "axios";

import { useCrews } from "../providers/crewsProvider";
import { useCrewWorkDays } from "../providers/crewWorkDaysProvider";
import { useGetWorkDaySummary } from "@crewos/workorders";

const { WorkOrderDateSelector, InformationModal } = components;

const { useWorkOrderDetails, useAuth } = data;

const WorkPerformed = ({ onEdit, onDelete }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();
  const [crewsContext] = useCrews();

  const [isLoading, setIsLoading] = useState();
  const [informationModal, setInformationModal] = useState();
  const [workDaySummary, setWorkDaySummary] = useState();

  const onDownloadPdf = useCallback(async () => {
    try {
      setIsLoading(true);
      sharedHelper.loadingToast();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/work-order/${workOrderDetails.workOrder.id}/report`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );
      const woNumber = workOrderDetails.workOrder.workOrderNumber;
      const customerName = workOrderDetails.workOrder.customer
        ? workOrderDetails.workOrder.customer.customerName
        : "No customer";
      const fileName = `${woNumber}-${customerName}.pdf`
        .replaceAll(" ", "_")
        .toLowerCase();
      sharedHelper.downloadFile(response.data, fileName);
      setIsLoading(false);
    } catch (error) {
      sharedHelper.errorToast(error.message);
    } finally {
      sharedHelper.dismissLoadingToast();
    }
  }, [workOrderDetails]);

  const {
    data: workDaySummaryData,
    isLoading: isLoadingGetWorkDaySummary,
    get: getWorkDaySummary,
  } = useGetWorkDaySummary(workOrderDetails.workOrder.id);

  const onGetWorkDaySummary = () => {
    getWorkDaySummary({ date: workOrderDetails.selectedDate });
  };

  useEffect(() => {
    if (workDaySummaryData) {
      setWorkDaySummary(workDaySummaryData);
    }
  }, [workDaySummaryData]);

  useEffect(() => {
    if (workDaySummary) {
      setInformationModal({
        isOpen: true,
        title: `Work Day Summary for ${moment(
          workOrderDetails.selectedDate
        ).format("YYYY/MM/DD")}`,
        body: workDaySummary,
        onClose: () => {
          setWorkDaySummary();
          setInformationModal();
        },
      });
    }
  }, [workDaySummary, workOrderDetails.selectedDate]);

  const workDaySummaryEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        "AUTO_DAY_SUMMARY_ENABLED"
      ),
    [authContext.userData]
  );

  return (
    <>
      <Card className="w-100 px-0 box-shadow-none mb-3">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0">Work Performed</h2>
            <div className="d-flex align-items-center">
              {workDaySummaryEnabled ? (
                <Button
                  color="primary"
                  onClick={onGetWorkDaySummary}
                  disabled={isLoadingGetWorkDaySummary}
                >
                  Work Day Summary
                </Button>
              ) : null}
              <Button
                color="primary"
                className="ms-2"
                onClick={onDownloadPdf}
                disabled={isLoading}
              >
                Download Report
              </Button>
              <Button color="primary" className="ms-2" onClick={onEdit}>
                Edit Info
              </Button>
              <Button color="danger" className="ms-2" onClick={onDelete}>
                Delete
              </Button>
            </div>
          </div>
          <WorkPerformedCards />
          <WorkOrderDateSelector
            crewWorkDays={crewWorkDaysContext.crewWorkDays}
          />
        </CardBody>
      </Card>
      {crewsContext.crews.length ? (
        crewsContext.crews.map((crew) => (
          <WorkPerformedWorkDay key={crew.id} crew={crew} />
        ))
      ) : (
        <div className="p-3 small text-muted text-center bg-white border-radius-default">
          Start by creating your first crew
        </div>
      )}
      {informationModal ? <InformationModal {...informationModal} /> : null}
    </>
  );
};

export default WorkPerformed;
