import React from "react";

import moment from "moment";

import { components, data, sharedHelper } from "@crewos/shared";

const { TooltipItem, Icon } = components;

const { useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const WorkOrderCrewIndicators = ({ entry, onDeleteCrew }) => {
  const [authContext] = useAuth();

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    DIRECT_ASSIGNATION
  );

  return (
    <div className="d-flex cursor-pointer">
      {entry.isLead && !directAssignationEnabled ? (
        <div className="d-flex align-items-center justify-content-end">
          <TooltipItem
            id={`${entry.employee.id}-tooltip-1`}
            title={"Crew Lead"}
          >
            <Icon
              name="shield"
              data-testid="shield-icon"
              className="text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
      {!entry.employee.isActive ? (
        <div className="ms-3">
          <TooltipItem
            id={`${entry.employee.id}-tooltip-2`}
            title="Employee Inactive"
          >
            <Icon
              name="alert-circle"
              data-testid="alert-circle-icon"
              className="text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
      {entry.createdAt ? (
        <div className="ms-3">
          <TooltipItem
            id={`${entry.employee.id}-tooltip-3`}
            title={`Added on ${moment(entry.createdAt).format("YYYY-MM-DD")}`}
          >
            <Icon
              name="info"
              data-testid="created-icon"
              className="text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
      {directAssignationEnabled ? (
        <div className="ms-3">
          <TooltipItem
            id={`${entry.employee.id}-tooltip-3`}
            title="Delete assignment"
          >
            <Icon
              onClick={onDeleteCrew}
              name="trash-2"
              data-testid="trash-icon"
              className="text-danger"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
    </div>
  );
};
export default WorkOrderCrewIndicators;
