import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
} from "reactstrap";

import { components, sharedHelper, api } from "@crewos/shared";

const { useCreateServiceLocation, useUpdateServiceLocation } = api;
const { Loader } = components;

const ServiceLocationModal = ({
  serviceLocation = { name: "", isActive: true },
  onClose,
  onSubmit,
}) => {
  const [serviceLocationData, setServiceLocationData] =
    useState(serviceLocation);

  const {
    isLoading: isLoadingUpdateServiceLocation,
    update: updateServiceLocation,
    data: updateServiceLocationData,
  } = useUpdateServiceLocation();

  const {
    isLoading: isLoadingCreateServiceLocation,
    mutate: createServiceLocation,
    data: createServiceLocationData,
  } = useCreateServiceLocation();

  useEffect(() => {
    if (updateServiceLocationData) {
      sharedHelper.successToast(`Service Location saved`);
      onSubmit();
    }
  }, [updateServiceLocationData, onSubmit]);

  useEffect(() => {
    if (createServiceLocationData) {
      sharedHelper.successToast(`Service Location created`);
      onSubmit();
    }
  }, [createServiceLocationData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    const data = { ...serviceLocationData };
    if (serviceLocationData.id) {
      await updateServiceLocation({ ...data });
    } else {
      await createServiceLocation({ ...data });
    }
  };

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {serviceLocationData.id ? "Edit" : "Create"} Service Location
        </ModalHeader>
        <ModalBody>
          {isLoadingCreateServiceLocation || isLoadingUpdateServiceLocation ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter a name"
                  name="name"
                  value={serviceLocationData.name || ""}
                  onChange={(e) =>
                    setServiceLocationData({
                      ...serviceLocationData,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ServiceLocationModal;
