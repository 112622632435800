import moment from "moment";
import { components } from "@crewos/shared";

const { Icon } = components;

const Signature = ({ signatureUrl, signedAt, signedBy }) => {
  return (
    <div
      className="d-flex flex-column align-items-center p-2 mt-2 bg-light rounded-3"
      style={{ minHeight: "240px" }}
    >
      <div
        className="w-100 border-bottom border-white d-flex align-items-center justify-content-center border bg-white rounded-3"
        style={{ height: "100px" }}
      >
        {signatureUrl ? (
          <img
            src={signatureUrl}
            alt={`Signed by ${signedBy}`}
            className="w-100"
          />
        ) : (
          <div className="p-3 bg-white rounded-3 h-100 w-100 d-flex align-items-center justify-content-center">
            <Icon name="feather" className="fs-1 mx-2" />
            <span className="text-muted">No signature provided</span>
          </div>
        )}
      </div>
      <div className="d-flex flex-column align-items-center py-2">
        <span className="fw-semibold">
          <Icon name="user" className="fs-1 mx-2" />
          {signedBy || "No Employee"}
        </span>
        {signedAt && (
          <div className="text-muted d-flex flex-column align-items-center justify-content-center">
            <span className="fw-light">
              {moment(signedAt).format("MM/DD/YYYY")}
            </span>
            <span className="fw-light">
              {moment(signedAt).format("hh:mm A")}
            </span>
            {moment(signedAt).isBefore(moment()) && (
              <span className="fw-lighter">
                (Signed {moment(signedAt).fromNow()})
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Signature;
