import { sharedHelper, data } from "@crewos/shared";
import { Outlet } from "react-router-dom";
import React from "react";

const { useAuth } = data;

const SafetyWrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "safety"
  );
  if (!packageEnabled || !authContext.userData) {
    return null;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default SafetyWrapper;
