// packages/shared/src/components/dynamicAttributes/AttributeFormModal.js

import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
} from "reactstrap";
import CustomCheckboxWithLabel from "../CustomCheckboxWithLabel";
import {
  useCreateDynamicAttribute,
  useUpdateDynamicAttribute,
} from "../../api/DynamicAttributes.hooks";
import { sharedHelper } from "../../helpers/sharedHelper";

const AttributeFormModal = ({ entity, attribute, onClose, onSubmit }) => {
  const [formData, setFormData] = useState(attribute || {});

  useEffect(() => {
    setFormData(attribute || {});
  }, [attribute]);

  const isEdit = useMemo(() => Boolean(attribute.id), [attribute]);

  // Generic change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const {
    isLoading: isLoadingUpdateDynamicAttribute,
    update: updateDynamicAttribute,
    data: updateDynamicAttributeData,
  } = useUpdateDynamicAttribute();

  const {
    isLoading: isLoadingCreateDynamicAttribute,
    mutate: createDynamicAttribute,
    data: createDynamicAttributeData,
  } = useCreateDynamicAttribute();

  const isLoading = useMemo(
    () => isLoadingCreateDynamicAttribute || isLoadingUpdateDynamicAttribute,
    [isLoadingCreateDynamicAttribute, isLoadingUpdateDynamicAttribute]
  );

  useEffect(() => {
    if (updateDynamicAttributeData && !isLoading) {
      sharedHelper.successToast("Dynamic attribute saved");
      onSubmit(updateDynamicAttributeData.data);
    }
  }, [updateDynamicAttributeData, isLoading, onSubmit]);

  useEffect(() => {
    if (createDynamicAttributeData && !isLoading) {
      sharedHelper.successToast("Dynamic attribute created");
      onSubmit(createDynamicAttributeData.data);
    }
  }, [createDynamicAttributeData, isLoading, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (formData.id) {
      await updateDynamicAttribute({ ...formData, entity });
    } else {
      await createDynamicAttribute({ ...formData, entity });
    }
  };

  return (
    <Modal isOpen={true} toggle={onClose} size="lg">
      <Form data-testid="attribute-form" onSubmit={doSubmit}>
        <ModalHeader toggle={onClose}>
          {isEdit ? "Edit Attribute" : "Add Attribute"}
        </ModalHeader>
        <ModalBody className="d-flex">
          {/* Left Column */}
          <Col xs={6} className="pe-4">
            {/* KEY */}
            <FormGroup>
              <Label for="key">
                <span>Key</span>
                <span className="text-danger ms-1">*</span>
              </Label>
              <Input
                type="text"
                name="key"
                id="key"
                value={formData.key || ""}
                onChange={handleChange}
                required
              />
            </FormGroup>
            {/* NAME */}
            <FormGroup>
              <Label for="name">
                <span>Name</span>
                <span className="text-danger ms-1">*</span>
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name || ""}
                onChange={handleChange}
                required
              />
            </FormGroup>
            {/* LABEL */}
            <FormGroup>
              <Label for="label">
                <span>Label</span>
                <span className="text-danger ms-1">*</span>
              </Label>
              <Input
                type="text"
                name="label"
                id="label"
                value={formData.label || ""}
                onChange={handleChange}
                required
              />
            </FormGroup>
            {/* TYPE */}
            <FormGroup>
              <Label for="type">
                <span>Type</span>
                <span className="text-danger ms-1">*</span>
              </Label>
              <Input
                type="select"
                name="type"
                id="type"
                value={formData.type || ""}
                onChange={handleChange}
                required
              >
                <option value="">Select the type</option>
                <option value="TEXT">TEXT</option>
                <option value="NUMBER">NUMBER</option>
                <option value="CURRENCY">CURRENCY</option>
              </Input>
            </FormGroup>
            {/* DESCRIPTION */}
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                type="text"
                name="description"
                id="description"
                value={formData.description || ""}
                onChange={handleChange}
              />
            </FormGroup>
            {/* ALLOW NEGATIVE */}
            <FormGroup className="mt-4">
              <CustomCheckboxWithLabel
                label="Allow Negative (For NUMBER/CURRENCY)"
                checked={!!formData.allowNegative}
                onClick={() =>
                  setFormData((prev) => ({
                    ...prev,
                    allowNegative: !prev.allowNegative,
                  }))
                }
                data-value={formData.allowNegative ? "Active" : "Inactive"}
                data-testid="allowNegative-switch"
                name="allowNegative"
                className="d-inline-flex align-items-center"
                small
                reverse
              />
            </FormGroup>
            {/* REQUIRED */}
            <FormGroup className="mt-4">
              <CustomCheckboxWithLabel
                label="Required"
                checked={!!formData.required}
                onClick={() =>
                  setFormData((prev) => ({
                    ...prev,
                    required: !prev.required,
                  }))
                }
                data-value={formData.required ? "Active" : "Inactive"}
                data-testid="required-switch"
                name="required"
                className="d-inline-flex align-items-center"
                small
                reverse
              />
            </FormGroup>
          </Col>
          {/* Right Column */}
          <Col xs={6} className="ps-4">
            {/* PLACEHOLDER */}
            <FormGroup>
              <Label for="placeholder">Placeholder</Label>
              <Input
                type="text"
                name="placeholder"
                id="placeholder"
                value={formData.placeholder || ""}
                onChange={handleChange}
              />
            </FormGroup>
            {/* MAX VALUE */}
            <FormGroup>
              <Label for="maxValue">Max Value</Label>
              <Input
                type="number"
                name="maxValue"
                id="maxValue"
                value={formData.maxValue || ""}
                onChange={handleChange}
              />
            </FormGroup>
            {/* MIN VALUE */}
            <FormGroup>
              <Label for="minValue">Min Value</Label>
              <Input
                type="number"
                name="minValue"
                id="minValue"
                value={formData.minValue || ""}
                onChange={handleChange}
              />
            </FormGroup>
            {/* STEP */}
            <FormGroup>
              <Label for="step">Step</Label>
              <Input
                type="number"
                name="step"
                id="step"
                step="any"
                value={formData.step || ""}
                onChange={handleChange}
              />
            </FormGroup>
            {/* MAX LENGTH */}
            <FormGroup>
              <Label for="maxLength">Max Length</Label>
              <Input
                type="number"
                name="maxLength"
                id="maxLength"
                value={formData.maxLength || ""}
                onChange={handleChange}
              />
            </FormGroup>
            {/* MIN LENGTH */}
            <FormGroup>
              <Label for="minLength">Min Length</Label>
              <Input
                type="number"
                name="minLength"
                id="minLength"
                value={formData.minLength || ""}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </ModalBody>
        <ModalFooter className="mt-2">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AttributeFormModal;
