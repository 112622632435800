import { useCallback } from "react";

import { HttpMethod } from "./Api";
import { useApiMutation, useApiGet } from "./Api.hooks";

const endpoint = `/api/service-location`;

export const useGetServiceLocations = () => {
  return useApiGet(endpoint);
};

export const useCreateServiceLocation = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateServiceLocation = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (result) => {
      await mutate(result);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteServiceLocation = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteServiceLocation = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteServiceLocation };
};
