import React, { useMemo, useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";

import DatePicker from "react-datepicker";

import { crewHelper, useCrews } from "@crewos/crews";

import moment from "moment";

import { useCreateTravelTime } from "../api/TravelTimes.hooks";

import { components, data, sharedHelper } from "@crewos/shared";

import CrewMembers from "./CrewMembers";

import { useCrewWorkDays, CREW_WORK_DAY_ACTIONS } from "@crewos/crews";

const { Select, Loader } = components;
const { useAuth, useWorkOrderDetails } = data;

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const AddTravelTimeModal = ({ onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [authContext] = useAuth();
  const [crewsContext] = useCrews();

  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(
    workOrderDetails.selectedDate
  );
  const [crewsWithLeadForDate, setCrewsWithLeadForDate] = useState([]);

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [timeToAdd, setTimeToAdd] = useState({});
  const [isDriver, setIsDriver] = useState({});
  const [mileageToAdd, setMileageToAdd] = useState({});

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    DIRECT_ASSIGNATION
  );

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    START_END_TRAVEL_TIME_SETTING
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const {
    isLoading: isLoadingCreateTravelTime,
    mutate: createTravelTime,
    data: createTravelTimeData,
  } = useCreateTravelTime();

  useEffect(() => {
    if (createTravelTimeData && crew) {
      sharedHelper.successToast(`Time added`);
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH_CREW_SECTION,
        payload: { crewId: crew.id, section: "TRAVEL_TIMES" },
      });
      onClose();
    }
  }, [
    onClose,
    createTravelTimeData,
    crewWorkDaysContext.crewWorkDays,
    setCrewWorkDaysContext,
    crew,
  ]);

  const doSubmit = async (event) => {
    event.preventDefault();
    const employees = Object.keys(timeToAdd).map((employeeId) => {
      const date = moment(selectedDate);
      const data = {
        isDriver: isDriver[employeeId] ? isDriver[employeeId] : false,
        mileage: mileageToAdd[employeeId],
        date: date.format("YYYY-MM-DD"),
        employeeId,
        crewId: crew.id,
      };
      if (startEndTimeSettingEnabled) {
        const { startTime, endTime } = timeToAdd[employeeId];
        data.startTime = startTime;
        data.endTime = endTime;
      } else {
        data.hours = timeToAdd[employeeId];
      }
      return data;
    });
    if (!employees.length) {
      sharedHelper.warningToast("Add time to at least one employee");
    }
    await createTravelTime({
      employees,
    });
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setTimeToAdd({});
    setIsDriver({});
    setMileageToAdd({});
    return () => setTimeToAdd({}) && setIsDriver({}) && setMileageToAdd({});
  }, [crew]);

  useEffect(() => {
    setTimeToAdd({});
    setIsDriver({});
    setMileageToAdd({});
    setCrew();
    return () =>
      setTimeToAdd({}) && setIsDriver({}) && setMileageToAdd({}) && setCrew({});
  }, [selectedDate]);

  useEffect(() => {
    const crewsWithLeadForDate = crewsContext.crews.filter((crew) =>
      crewHelper.getCrewLead(crew, employeeCrewPastDatesEnabled, selectedDate)
    );
    setCrewsWithLeadForDate(crewsWithLeadForDate);
  }, [crewsContext.crews, selectedDate, employeeCrewPastDatesEnabled]);

  const onSelectedCrew = (selected) => {
    const crew = crewsContext.crews.find((c) => c.id === selected.value);
    setCrew(crew);
  };

  const crewForDate = useMemo(
    () => (crew ? crewsWithLeadForDate.find((c) => c.id === crew.id) : false),
    [crew, crewsWithLeadForDate]
  );

  const crewSelect = useMemo(() => {
    return crewsWithLeadForDate.map((crew) => {
      const lead = crewHelper.getCrewLead(crew, employeeCrewPastDatesEnabled);
      return {
        label: lead
          ? `${lead.employee.firstName} ${lead.employee.lastName}`
          : "No Lead",
        value: crew.id,
      };
    });
  }, [crewsWithLeadForDate, employeeCrewPastDatesEnabled]);

  const defaultCrew = useMemo(() => {
    return crewSelect.find((c) => c.value === crew?.id);
  }, [crewSelect, crew]);

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Add Travel Time
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateTravelTime ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>Date</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                <div className="d-flex col-12 align-items-center justify-content-between">
                  <DatePicker
                    selected={
                      selectedDate
                        ? moment(selectedDate).startOf("day").toDate()
                        : null
                    }
                    onChange={onSelectedDate}
                    className="d-flex align-items-center justify-content-between form-control-redesign cursor-pointer"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>{directAssignationEnabled ? "Employee" : "Crew"}</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                {crewsWithLeadForDate.length ? (
                  <Select
                    id="crewSelect"
                    name="crewSelect"
                    data-testid={`${
                      directAssignationEnabled ? "employee" : "crew"
                    }-select`}
                    value={defaultCrew}
                    onChange={onSelectedCrew}
                    placeholder={`Select a ${
                      directAssignationEnabled ? "n Employee" : " Crew"
                    }`}
                    options={crewSelect}
                    required
                  />
                ) : (
                  <div className="text-muted small">
                    No crews for selected date
                  </div>
                )}
              </FormGroup>
            </>
          )}
          {selectedDate && crewForDate ? (
            <CrewMembers
              selectedDate={selectedDate}
              crew={crew}
              timeToAdd={timeToAdd}
              isDriver={isDriver}
              mileageToAdd={mileageToAdd}
              employeeEnabled={employeeEnabled}
              setTimeToAdd={setTimeToAdd}
              setIsDriver={setIsDriver}
              setMileageToAdd={setMileageToAdd}
              setEmployeeEnabled={setEmployeeEnabled}
            />
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateTravelTime ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddTravelTimeModal;
