import React, { useMemo, useEffect, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import { components, data } from "@crewos/shared";

import { sharedHelper } from "@crewos/shared";

import { useGetPerDiems } from "../api/PerDiems.hooks";

import EmployeeCrewRow from "./EmployeeCrewRow";

import { crewHelper, useCrewWorkDays } from "@crewos/crews";

const { useAuth, useWorkOrderDetails } = data;

const { Loader, Icon } = components;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CrewWorkDayPerDiems = ({ crew }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();

  const [refresh, setRefresh] = useState();
  const [perDiems, setPerDiems] = useState([]);

  //only one crew travel day per date/crew
  const crewWorkDay = useMemo(
    () =>
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate]?.find(
        (cwd) => cwd.crewId === crew.id
      ),
    [workOrderDetails.selectedDate, crewWorkDaysContext.crewWorkDays, crew]
  );

  const {
    data: getPerDiemsData,
    isLoading: isLoadingGetPerDiems,
    get: getPerDiems,
  } = useGetPerDiems();

  useEffect(() => {
    if (crewWorkDay) {
      getPerDiems({
        crewWorkDayId: crewWorkDay.id,
      });
    }
  }, [getPerDiems, crewWorkDay, refresh]);

  useEffect(() => {
    if (getPerDiemsData) {
      setPerDiems(getPerDiemsData);
    }
  }, [getPerDiemsData, setPerDiems]);

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const activeCrew = useMemo(
    () =>
      crewHelper.filterCrew(
        crew,
        employeeCrewPastDatesEnabled,
        workOrderDetails.selectedDate
      ),
    [crew, employeeCrewPastDatesEnabled, workOrderDetails.selectedDate]
  );

  const onRefresh = () => {
    setRefresh((prev) => !prev);
  };

  return (
    <Card className="w-100 px-0 box-shadow-none mb-3">
      <CardBody className="pb-2">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h3>Per Diem</h3>
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={onRefresh}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </div>
        {!directAssignationEnabled ? (
          <div className="fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-graylight border-radius-default">
            <div className="col-6">CREW MEMBER</div>
            <div className="col-6 text-end">PER DIEM</div>
          </div>
        ) : null}
        {isLoadingGetPerDiems ? (
          <Loader size="sm" className="my-3" />
        ) : activeCrew.length ? (
          !Object.values(perDiems).length ? (
            <div className="p-2 small text-muted text-center">
              No per diem generated
            </div>
          ) : (
            activeCrew.map((employeeCrew) => {
              const employeePerDiem = perDiems[employeeCrew.employeeId];
              return (
                <EmployeeCrewRow
                  key={employeeCrew.id}
                  employeeCrew={employeeCrew}
                  perDiem={employeePerDiem}
                />
              );
            })
          )
        ) : (
          <div className="p-2 small text-muted text-center">
            Empty crew for date
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const CrewWorkDayPerDiemsWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(authContext.userData?.packages, "perdiems")
  ) {
    return null;
  }

  return <CrewWorkDayPerDiems {...props} />;
};

export default CrewWorkDayPerDiemsWrapper;
