import React, { useCallback, useEffect, useMemo } from "react";
import { components, data, sharedHelper } from "@crewos/shared";
import { useParams } from "react-router-dom";
import {
  useGetQuestionTypes,
  useGetSafetyTemplates,
  useUpdateSafetyTemplateConfiguration,
  useCreateSafetyTemplateConfiguration,
} from "../../api/Safety.hooks";
import ConfigurationModal from "../../components/ConfigurationModal";

const { FormTemplate, Loader } = components;
const { FormTemplateProvider } = data;

const ViewTemplates = () => {
  const { id: templateId } = useParams();

  const {
    data: questionTypes,
    get: getQuestionTypes,
    isLoading: isQuestionTypesLoading,
  } = useGetQuestionTypes();

  const {
    data: safetyTemplates,
    get: getSafetyTemplates,
    isLoading: isSafetyTemplatesLoading,
  } = useGetSafetyTemplates();

  const {
    data: safetyTemplateConfigurationsDataUpdated,
    mutate: updateSafetyTemplateConfiguration,
    isLoading: isSafetyTemplateConfigurationsLoading,
    error: updateSafetyTemplateConfigurationError,
  } = useUpdateSafetyTemplateConfiguration();

  const {
    data: safetyTemplateConfigurationsDataCreated,
    mutate: createSafetyTemplateConfiguration,
    isLoading: isCreateSafetyTemplateConfigurationLoading,
    error: createSafetyTemplateConfigurationError,
  } = useCreateSafetyTemplateConfiguration();

  const handlePublishTemplate = useCallback(
    async (template) => {
      const { data } = template;
      const { configuration } = data;
      if (configuration) {
        const configurationData = {
          ...configuration,
          safetyTemplateId: data.id,
        };
        if (configuration.id) {
          await updateSafetyTemplateConfiguration(configurationData);
        } else {
          await createSafetyTemplateConfiguration(configurationData);
        }
      }
    },
    [updateSafetyTemplateConfiguration, createSafetyTemplateConfiguration]
  );

  const handleSaveConfiguration = useCallback(
    async (configuration) => {
      const configurationData = {
        ...configuration,
        safetyTemplateId: templateId,
      };
      if (configurationData.id) {
        await updateSafetyTemplateConfiguration(configurationData);
      } else {
        if (
          configurationData.safetyTemplateId &&
          configurationData.signatureRequest
        ) {
          await createSafetyTemplateConfiguration(configurationData);
        }
      }
      sharedHelper.successToast("Configuration saved");
    },
    [
      updateSafetyTemplateConfiguration,
      createSafetyTemplateConfiguration,
      templateId,
    ]
  );

  useEffect(() => {
    if (
      safetyTemplateConfigurationsDataCreated ||
      safetyTemplateConfigurationsDataUpdated
    ) {
      getSafetyTemplates({ id: templateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    safetyTemplateConfigurationsDataCreated,
    safetyTemplateConfigurationsDataUpdated,
  ]);

  useEffect(() => {
    getQuestionTypes();
    if (templateId) {
      getSafetyTemplates({ id: templateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSuccessSaved = useMemo(() => {
    return (
      safetyTemplateConfigurationsDataUpdated &&
      !updateSafetyTemplateConfigurationError &&
      !createSafetyTemplateConfigurationError
    );
  }, [
    safetyTemplateConfigurationsDataUpdated,
    updateSafetyTemplateConfigurationError,
    createSafetyTemplateConfigurationError,
  ]);
  if (isQuestionTypesLoading || isSafetyTemplatesLoading) {
    return <Loader />;
  }
  return (
    <FormTemplateProvider>
      <FormTemplate
        isReadOnly
        data={safetyTemplates}
        questionTypes={questionTypes}
        prefix="safety"
        ConfigurationTemplateModal={ConfigurationModal}
        onPublishTemplate={handlePublishTemplate}
        onSaveConfiguration={handleSaveConfiguration}
        isLoadingSaveTemplate={
          isSafetyTemplateConfigurationsLoading ||
          isCreateSafetyTemplateConfigurationLoading
        }
        isSuccessSaved={isSuccessSaved}
      />
    </FormTemplateProvider>
  );
};

export default ViewTemplates;
