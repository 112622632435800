import React, { useEffect, useMemo, useCallback } from "react";
import { FormFeedback, FormGroup, Label, Input } from "reactstrap";
import { components } from "@crewos/shared";
import { useGetSafetyTemplates } from "../../api/Safety.hooks";

const { SelectAdvance, TooltipItem, Icon } = components;

const AssignTemplateWOForm = ({
  onSelectTemplate,
  error,
  onUpdateRequired,
  isRequired,
}) => {
  // Use the custom hook to fetch safety templates
  const {
    data: templatesData,
    isLoading: isLoadingTemplates,
    get: fetchTemplates,
  } = useGetSafetyTemplates();

  // Effect to fetch templates on component mount
  useEffect(() => {
    fetchTemplates({ search: "", pageSize: 30, status: "PUBLISHED" });
  }, [fetchTemplates]);

  // Handler for searching templates
  const handleSearchTemplates = useCallback(
    (searchText) => {
      fetchTemplates({ search: searchText, pageSize: 30, status: "PUBLISHED" });
    },
    [fetchTemplates]
  );

  // Memoize the options for SelectAdvance
  const templateOptions = useMemo(() => {
    if (templatesData && templatesData.data) {
      return templatesData.data.map((template) => ({
        value: template.id,
        label: template.name, // Assuming 'name' is the label key
        isRequired: template.configuration?.isRequired,
      }));
    }
    return [];
  }, [templatesData]);

  // Handler for when a template is selected
  const handleSelect = useCallback(
    (selectedOption) => {
      onSelectTemplate(selectedOption);
    },
    [onSelectTemplate]
  );

  const handleUpdateRequired = useCallback(
    (e) => {
      onUpdateRequired(e.target.checked);
    },
    [onUpdateRequired]
  );

  return (
    <>
      <FormGroup>
        <Label data-testid="select-template-label">
          <span>Safety Template</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <SelectAdvance
          entity="template"
          name="template"
          className={`form-control-redesign ${
            error?.template?.length > 0 ? "border-warning color-warning" : ""
          }`}
          wrapperClassName={error?.template?.length > 0 ? "is-invalid" : ""}
          placeholder="Search templates"
          noOptionsMessage="No templates found"
          options={templateOptions} // Pass the transformed options
          isLoading={isLoadingTemplates} // Pass the loading state
          onSelected={handleSelect} // Pass the selection handler
          onSearch={handleSearchTemplates} // Pass the search handler
          aria-invalid={error?.template?.length > 0}
        />
        {error?.template?.length > 0 &&
          error.template.map((errorLine, index) => (
            <FormFeedback key={index}>{errorLine}</FormFeedback>
          ))}
      </FormGroup>
      <FormGroup
        switch
        className="input-switch ps-5 d-flex align-items-center gap-2"
      >
        <Input
          type="switch"
          role="switch"
          id="is-required-template"
          name="is-required-template"
          onChange={handleUpdateRequired}
          checked={isRequired}
          className="cursor-pointer"
        />
        <Label
          for="is-required-template"
          className="cursor-pointer pt-1 mb-0 d-flex text-muted small"
        >
          Is Required
          <TooltipItem
            className="font-size-regular fw-regular"
            id="sl-tooltip"
            title={`If this template is required, it means that is necessary to complete it every work day before the work order can be closed`}
          >
            <Icon
              name="info"
              className="text-primary text-warning ms-2"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </Label>
      </FormGroup>
    </>
  );
};

export default AssignTemplateWOForm;
