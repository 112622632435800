import React, { useEffect, useMemo, useState, useCallback } from "react";

import { FormGroup } from "reactstrap";
import Label from "reactstrap/lib/Label";

import {
  isCrewLeadRole,
  useGetEmployeeRoles,
  useGetEmployees,
} from "@crewos/employees";

import { components, data, sharedHelper } from "@crewos/shared";

const { useAuth, useWorkOrderDetails } = data;
const { Icon, Select } = components;

const MODE_LEAD = 1;
const MODE_DEFAULT = 2;
const PAGE_SIZE = 30;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const SERVICE_LOCATIONS_ENABLED = "SERVICE_LOCATIONS_ENABLED";

const EmployeeSelector = ({
  selectedEmployees,
  setSelectedEmployees,
  selectedEmployee,
  mode,
}) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employee, setEmployee] = useState(null);
  const [roleSearch, setRoleSearch] = useState("");
  const [role, setRole] = useState(null);

  const directAssignationEnabled = useMemo(
    () =>
      authContext.userData
        ? sharedHelper.isSettingEnabled(
            authContext.userData.packages,
            DIRECT_ASSIGNATION
          )
        : false,
    [authContext.userData]
  );

  const serviceLocationEnabled = useMemo(
    () =>
      authContext.userData
        ? sharedHelper.isSettingEnabled(
            authContext.userData.packages,
            SERVICE_LOCATIONS_ENABLED
          )
        : false,
    [authContext.userData]
  );

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const {
    data: employeeRoles,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  useEffect(() => {
    if (selectedEmployee) {
      setEmployee({
        label: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
        value: selectedEmployee.id,
      });
      if (selectedEmployee.role) {
        setRole({
          label: (
            <span
              className={selectedEmployee.role.isActive ? "" : "text-muted"}
            >
              {`${selectedEmployee.role.name} ${
                !selectedEmployee.role.isActive ? "(Inactive)" : ""
              }`}
            </span>
          ),
          value: selectedEmployee.role.id,
        });
      } else {
        setRole(null);
      }
    } else {
      setEmployee(null);
      setRole(null);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    const params = {
      search: employeeSearch,
      excludeRoleSearch: true,
      pageSize: PAGE_SIZE,
    };
    if (serviceLocationEnabled) {
      params.serviceLocationId = workOrderDetails.workOrder.serviceLocationId;
    }
    getEmployees(params);
  }, [
    getEmployees,
    employeeSearch,
    workOrderDetails.workOrder,
    serviceLocationEnabled,
  ]);

  useEffect(() => {
    getEmployeeRoles({ search: roleSearch, pageSize: PAGE_SIZE });
  }, [getEmployeeRoles, roleSearch]);

  const onSelectEmployee = useCallback(
    (employee) => {
      const index = selectedEmployee
        ? selectedEmployees.findIndex((e) => e.id === selectedEmployee.id)
        : -1;
      if (employee && index < 0) {
        selectedEmployees.push(employee);
      } else if (employee) {
        selectedEmployees.splice(index, 1, employee);
      } else if (index >= 0) {
        selectedEmployees.splice(index, 1);
      }
      setSelectedEmployees([...selectedEmployees].filter(Boolean));
    },
    [selectedEmployees, selectedEmployee, setSelectedEmployees]
  );

  const onEmployeeChange = useCallback(
    (selected) => {
      if (selected) {
        const employee = employees.data.find(
          (employee) => employee.id === selected.value
        );
        employee.isLead = mode === MODE_LEAD;
        onSelectEmployee(employee);
      } else {
        onSelectEmployee();
      }
    },
    [employees, mode, onSelectEmployee]
  );

  const onRoleChange = useCallback(
    (selected) => {
      const employee = { ...selectedEmployee };
      if (selected) {
        const employeeRole = employeeRoles.data.find(
          (role) => role.id === selected.value
        );
        employee.role = employeeRole;
        onSelectEmployee(employee);
      } else {
        employee.role = null;
        onSelectEmployee(employee);
      }
    },
    [employeeRoles, onSelectEmployee, selectedEmployee]
  );

  const employeeOptions = useMemo(
    () =>
      (employees?.data || [])
        .filter(
          (option) =>
            !selectedEmployees.find((employee) => employee.id === option.id)
        )
        .map((option) => ({
          label: `${option.firstName} ${option.lastName}`,
          value: option.id,
        })),
    [employees, selectedEmployees]
  );

  const employeeRoleOptions = useMemo(
    () =>
      (employeeRoles?.data || [])
        .filter((role) => (mode === MODE_LEAD ? isCrewLeadRole(role) : true))
        .map((role) => ({
          label: role.name,
          value: role.id,
        })),
    [employeeRoles, mode]
  );

  return (
    <div className={`col-${directAssignationEnabled ? "12" : "6"} my-2 px-2`}>
      <FormGroup className="d-flex flex-column p-3 border border-radius-default">
        {!directAssignationEnabled ? (
          <Label className="d-flex align-items-center justify-content-between bg-graylight p-2 border-radius-md">
            <div className="d-flex align-items-center fw-bold">
              {mode === MODE_LEAD ? "Lead" : mode === MODE_DEFAULT ? "" : "New"}{" "}
              Employee
            </div>
            <Icon
              name={mode === MODE_LEAD ? "shield" : "hard-hat"}
              className="ms-2 text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </Label>
        ) : null}
        <Select
          name="employee"
          entity="employee"
          inputId="employee"
          placeholder="Search employees"
          noOptionsMessage={() => "No employees found"}
          options={employeeOptions}
          isSearchable
          isClearable
          inputValue={employeeSearch}
          value={employee}
          onInputChange={setEmployeeSearch}
          isLoading={isLoadingEmployees}
          onChange={onEmployeeChange}
        />
        {employee ? (
          <Select
            entity="employee-role"
            placeholder="Search roles"
            noOptionsMessage={() => "No roles found"}
            className="mt-3"
            options={employeeRoleOptions}
            isSearchable
            isClearable
            inputValue={roleSearch}
            value={role}
            onInputChange={setRoleSearch}
            isLoading={isLoadingEmployeeRoles}
            onChange={onRoleChange}
          />
        ) : null}
      </FormGroup>
    </div>
  );
};

export default EmployeeSelector;
