import React, { useMemo, useState } from "react";

import { Collapse } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { crewHelper } from "../helpers/crewHelper";

const { useAuth } = data;
const { Icon } = components;

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CrewWorkDayCrew = ({ children, crew, actions }) => {
  const [authContext] = useAuth();
  const [isOpen, setIsOpen] = useState(true);

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const crewLead = useMemo(
    () => crewHelper.getCrewLead(crew, employeeCrewPastDatesEnabled),
    [crew, employeeCrewPastDatesEnabled]
  );

  return (
    <div className="w-100 px-0" key={crew.id}>
      <div
        data-testid="view-more-less"
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer mb-3 py-3 px-3 d-flex justify-content-between align-items-center bg-white col-12 border-radius-default"
      >
        <div className="d-flex align-items-center bg-graylight text-primary px-4 py-2 border-radius-default fw-bold">
          {crewLead ? sharedHelper.getName(crewLead.employee) : "No Lead"}
        </div>
        <div className="col-6"></div>
        <div className="d-flex align-items-center justify-content-end col-3 px-0">
          <div className="me-3">{actions}</div>
          <small className="text-primary">
            View {isOpen ? "less" : "more"}
          </small>
          <Icon
            name="chevron-right"
            className={`mx-2 rotate ${isOpen ? "rotate-90" : ""}`}
          />
        </div>
      </div>
      <Collapse isOpen={Boolean(isOpen)} className="px-3">
        {children}
      </Collapse>
    </div>
  );
};

export default CrewWorkDayCrew;
