import { useCallback } from "react";

import { useApiMutation, useApiGet } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/dynamic-attribute`;

export const useGetAllDynamicAttributes = () => {
  return useApiGet(`${endpoint}/all`);
};

export const useCreateDynamicAttribute = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateDynamicAttribute = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (item) => {
      await mutate(item);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteDynamicAttribute = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteDynamicAttribute = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteDynamicAttribute };
};
