import React, { useCallback, useState } from "react";
import { Collapse } from "reactstrap";

import classNames from "classnames";

import { components } from "@crewos/shared";

import GroupDetail from "./GroupDetail";

const { Icon } = components;

const SectionDetail = ({ section }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapse = useCallback(($event) => {
    $event.preventDefault();
    setIsOpen((prev) => !prev);
  }, []);

  const questions = section.groups.flatMap((group) => group.questions);

  const totalAnswers = questions.reduce(
    (acc, question) => {
      acc.required += question.isRequired ? 1 : 0;
      acc.answered += question.answers.length > 0 ? 1 : 0;
      return acc;
    },
    { required: 0, answered: 0 }
  );

  return (
    <div
      className={classNames(
        "section-container border mb-3 border-radius-md overflow-hidden",
        {
          "border-primarylight": isOpen,
          "border-gray": !isOpen,
        }
      )}
    >
      <div
        onClick={handleCollapse}
        className={classNames(
          "cursor-pointer p-2 d-flex justify-content-between border-radius-bottom-0 align-items-center",
          {
            "bg-primarylight": isOpen,
          }
        )}
        aria-expanded={isOpen}
      >
        <div className="w-100 d-flex justify-content-between align-items-center">
          <span className="fw-semibold">
            {section.order}. {section.name}
          </span>
          <small className="text-gray ms-1">
            <span
              className={classNames("fw-semibold", {
                "text-success": totalAnswers < questions.length,
                "text-warning": totalAnswers === 0,
              })}
            >
              {totalAnswers.answered}
            </span>
            <span>/{questions.length} </span>
            {totalAnswers.answered === totalAnswers.required && (
              <Icon name="check-circle" className="text-success" />
            )}
          </small>
        </div>
        <div className="ms-2">
          <Icon
            name="chevron-right"
            className={`mx-2 rotate ${isOpen ? "rotate-90" : ""}`}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        {section.groups
          .sort((a, b) => a.order - b.order)
          .map((group) => (
            <GroupDetail key={group.id} group={group} />
          ))}
      </Collapse>
    </div>
  );
};

export default SectionDetail;
