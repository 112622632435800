import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import classNames from "classnames";

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import FormTemplateSectionItem from "./FormTemplateSectionItem";
import SortableWrapper from "../SortableWrapper";
import {
  useFormSections,
  useFormTemplate,
} from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

const FormTemplateSections = ({
  isReadOnly,
  sections,
  ConfigurationTemplateModal,
  onSaveConfiguration,
}) => {
  const [isOpenConfiguration, setIsOpenConfiguration] = useState(false);
  const [activeSectionId, setActiveSectionId] = useState(null);
  const { updateSectionsOrder, addSection } = useFormSections();
  const { setConfiguration, state } = useFormTemplate();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active?.id !== over?.id) {
        const oldIndex = sections.findIndex(
          (section) => section.id === active.id
        );
        const newIndex = sections.findIndex(
          (section) => section.id === over.id
        );
        const newSections = arrayMove(sections, oldIndex, newIndex);
        updateSectionsOrder(newSections);
      }
    },
    [updateSectionsOrder, sections]
  );

  const handleOpenConfigurationModal = useCallback(() => {
    setIsOpenConfiguration((prev) => !prev);
  }, []);

  const handleCloseConfigurationModal = useCallback(() => {
    setIsOpenConfiguration((prev) => !prev);
  }, []);

  const handleSaveConfiguration = useCallback(
    (data) => {
      if (onSaveConfiguration) {
        onSaveConfiguration(data);
      } else {
        setConfiguration(data);
      }
      handleCloseConfigurationModal();
    },
    [setConfiguration, handleCloseConfigurationModal, onSaveConfiguration]
  );

  const handleCopySection = useCallback((sectionId) => {
    setActiveSectionId(sectionId);
  }, []);

  const handleScrollToSection = useCallback((sectionId) => {
    const sectionElement = document.getElementById(`section-${sectionId}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (activeSectionId) {
      handleScrollToSection(activeSectionId);
    }
    return () => {
      setActiveSectionId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  return (
    <Row className="border-bottom-0 mb-0 gap-3" sm={1}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={sections.map((section) => section.id)}
          strategy={verticalListSortingStrategy}
        >
          {sections.map((section) => (
            <SortableWrapper
              isReadOnly={isReadOnly}
              key={section.id}
              id={section.id}
              className="nav-item rounded-4 d-flex align-items-center gap-4 px-3 py-2"
            >
              <FormTemplateSectionItem
                section={section}
                isReadOnly={isReadOnly}
                onCopySection={handleCopySection}
              />
            </SortableWrapper>
          ))}
          <Col className="nav-item p-0 d-flex gap-2">
            {ConfigurationTemplateModal && (
              <Button
                size="sm"
                className="py-2 px-3 btn-text rounded-4 rounded-end-0 w-100 text-start d-flex align-items-center gap-1"
                color="secondary"
                onClick={handleOpenConfigurationModal}
              >
                <Icon name="settings" /> Configurations
              </Button>
            )}
            {!isReadOnly && (
              <Button
                size="sm"
                className={classNames(
                  "py-2 px-3 btn-text rounded-4 rounded-end-0 w-100 d-flex align-items-center gap-1",
                  {
                    "justify-content-center": !ConfigurationTemplateModal,
                    "justify-content-end": ConfigurationTemplateModal,
                  }
                )}
                color="secondary"
                onClick={addSection}
              >
                <Icon name="plus-circle" /> Add Section
              </Button>
            )}
          </Col>
        </SortableContext>
      </DndContext>
      {ConfigurationTemplateModal && isOpenConfiguration && (
        <ConfigurationTemplateModal
          isOpen={isOpenConfiguration}
          onClose={handleCloseConfigurationModal}
          onSave={handleSaveConfiguration}
          configuration={state.configuration}
        />
      )}
    </Row>
  );
};

export default FormTemplateSections;
