import React, { useEffect, useCallback, useMemo } from "react";

import {
  useGetQuestionTypes,
  useUpdateOrCreateTemplateFull,
  useGetSafetyTemplates,
} from "../../api/Safety.hooks";
import { components, sharedHelper, data } from "@crewos/shared";
import { useNavigate, useParams } from "react-router-dom";
import ConfigurationModal from "../../components/ConfigurationModal";

const { FormTemplate, Loader } = components;
const { FormTemplateProvider } = data;

const EditTemplates = () => {
  const { id: templateId } = useParams();

  const {
    data: questionTypes,
    get: getQuestionTypes,
    isLoading: isQuestionTypesLoading,
  } = useGetQuestionTypes();

  const {
    data: templateSavedData,
    mutate: saveTemplate,
    isLoading: isSavingTemplate,
    error: saveTemplateError,
  } = useUpdateOrCreateTemplateFull(templateId);

  const {
    data: safetyTemplates,
    get: getSafetyTemplates,
    isLoading: isSafetyTemplatesLoading,
  } = useGetSafetyTemplates();

  const navigate = useNavigate();

  const handleSave = useCallback(
    async (template, errors, failedUploads) => {
      if (errors && Object.keys(errors).length > 0) {
        sharedHelper.warningToast("Complete all required fields");
        return;
      }
      if (failedUploads) {
        sharedHelper.warningToast("Failed to upload guidance attachments");
        return;
      }
      if (template) {
        await saveTemplate(template);
      }
    },
    [saveTemplate]
  );

  const handleSaveTemplate = useCallback(
    async (template) => {
      const { data, errors, failedUploads } = template;
      await handleSave({ ...data, status: "DRAFT" }, errors, failedUploads);
    },
    [handleSave]
  );

  const handlePublishTemplate = useCallback(
    async (template) => {
      const { data, errors, failedUploads } = template;
      await handleSave({ ...data, status: "PUBLISHED" }, errors, failedUploads);
    },
    [handleSave]
  );

  useEffect(() => {
    if (templateSavedData && !saveTemplateError) {
      sharedHelper.successToast("Template saved");
      getSafetyTemplates({ id: templateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSavedData, navigate, saveTemplateError]);

  useEffect(() => {
    if (safetyTemplates && safetyTemplates.totalSafetys > 0) {
      navigate(`/safety/templates/view/${templateId}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safetyTemplates]);

  useEffect(() => {
    getQuestionTypes();
    if (templateId) {
      getSafetyTemplates({ id: templateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSuccessSaved = useMemo(() => {
    return templateSavedData && !saveTemplateError;
  }, [templateSavedData, saveTemplateError]);

  if (isQuestionTypesLoading || isSafetyTemplatesLoading) {
    return <Loader />;
  }

  return (
    <FormTemplateProvider>
      <FormTemplate
        isReadOnly={false}
        data={safetyTemplates}
        questionTypes={questionTypes}
        onSaveTemplate={handleSaveTemplate}
        onPublishTemplate={handlePublishTemplate}
        isLoadingSaveTemplate={isSavingTemplate}
        prefix="safety"
        ConfigurationTemplateModal={ConfigurationModal}
        isSuccessSaved={isSuccessSaved}
        fieldToRestrict="totalSafetys"
      />
    </FormTemplateProvider>
  );
};

export default EditTemplates;
