import React from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ImagesViewer, { MODE_FULL } from "./ImagesViewer";

const ImagesViewerModal = ({
  title,
  items,
  onClose,
  onDelete,
  showCaption = true,
  showActions = true,
  slide = true,
}) => {
  return (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title}
      </ModalHeader>
      <ModalBody>
        <ImagesViewer
          onDelete={onDelete}
          items={items}
          showCaption={showCaption}
          showActions={showActions}
          slide={slide}
          mode={MODE_FULL}
          width="100%"
          height="80vh"
        />
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImagesViewerModal;
