import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

import moment from "moment";

import { components, data, sharedHelper } from "@crewos/shared";

import {
  EditNonJobTimeModal,
  useRecalculateNonJobTime,
} from "@crewos/nonjobtimes";

import { EditTravelTimeModal } from "@crewos/traveltimes";

import {
  calculateHoursWithExceptions,
  EditWorkTimeModal,
} from "@crewos/worktimes";

import { useGetPayroll } from "../api/Reports.hooks";

const dateFormat = "MM/DD";

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const {
  Icon,
  AdvanceTable,
  InformationModal,
  ConfirmationModal,
  TooltipItem,
  Loader,
} = components;

const { useAuth, WORK_ORDER_DETAILS_TABS } = data;

const PayrollDateDetail = ({
  employeeId,
  employee,
  date,
  isTotal,
  onClose,
}) => {
  const [authContext] = useAuth();

  const { data: reportData, isLoading, get: getPayroll } = useGetPayroll();

  const { update: recalculateNonJobTime, data: recalculateNonJobTimeData } =
    useRecalculateNonJobTime();

  const isSuperAdmin = useMemo(
    () =>
      sharedHelper.userHasScope(authContext.userData?.role, [
        IS_SUPER_ADMIN_USER,
      ]),
    [authContext.userData]
  );

  const [data, setData] = useState();
  const [refresh, setRefresh] = useState();

  const [touched, setTouched] = useState();
  const [editTravelTimeModal, setEditTravelTimeModal] = useState();
  const [editWorkTimeModal, setEditWorkTimeModal] = useState();
  const [editNonJobTimeModal, setEditNonJobTimeModal] = useState();

  const [informationModal, setInformationModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  useEffect(() => {
    const params = {
      employeeId,
    };
    if (isTotal) {
      params.weekStart = date;
    } else {
      params.date = date;
    }
    getPayroll(params);
  }, [getPayroll, refresh, date, isTotal, employeeId]);

  useEffect(() => {
    if (reportData?.data?.length) {
      setData(reportData.data[0]);
    }
  }, [reportData, setData]);

  const onSubmitTimes = useCallback(() => {
    setRefresh((refresh) => !refresh);
    setTouched(true);
  }, []);

  useEffect(() => {
    if (recalculateNonJobTimeData) {
      onSubmitTimes();
    }
  }, [recalculateNonJobTimeData, onSubmitTimes]);

  const handleClose = useCallback(() => onClose(touched), [onClose, touched]);

  const onEditEntry = (entry) => {
    const time = rawTimes.find((time) => time.id === entry.id);
    if (time.type === "Travel") {
      setEditTravelTimeModal(time);
    } else if (time.date) {
      //non job time
      setEditNonJobTimeModal(time);
    } else {
      setEditWorkTimeModal(time);
    }
  };

  const onNonJobTimeInfo = (generationRule) => {
    setInformationModal({
      title: "Non Job Time Details",
      rawBody: true,
      size: "xl",
      onClose: () => setInformationModal(),
      body: (
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-graylight small">
              <th>Rule</th>
              <th>Precedence</th>
              <th>Condition</th>
              <th>Hours</th>
              <th>Dates</th>
            </tr>
          </thead>
          <tbody className="small">
            <tr>
              <td>{generationRule.name}</td>
              <td>{generationRule.precedence}</td>
              <td>
                <code>{generationRule.condition}</code>
              </td>
              <td>{generationRule.amount}</td>
              <td>
                {generationRule.startDate
                  ? `${generationRule.startDate} to ${generationRule.endDate}`
                  : "Open Dates"}
              </td>
            </tr>
          </tbody>
        </Table>
      ),
    });
  };

  const onRecalculateNonJobTime = (nonJobTimeId) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await recalculateNonJobTime({ id: nonJobTimeId });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Recalculate Time Entry",
      body: `Are you sure you want to recalculate this time entry using current rules?`,
      confirmText: "Recalculate",
    });
  };

  const startEndTimeSettingEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        START_END_WORK_TIME_SETTING
      ),
    [authContext.userData]
  );

  const totalColumns = [
    {
      accessor: "Employee",
      header: "Employee",
      disableFilter: true,
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      type: "number",
      Cell: () => {
        return employee;
      },
    },
    !isTotal
      ? {
          accessor: "Date",
          header: "Date",
          headerProps: { className: "text-center" },
          cellProps: { className: "text-center" },
          Cell: (rowData) => {
            const date = moment(rowData.row.date);
            return `${date.format("dd")} ${date.format(dateFormat)}`;
          },
        }
      : null,
    {
      accessor: "Total Hours",
      header: "Total Hours",
      disableFilter: true,
      headerProps: { className: "text-end" },
      cellProps: { className: "text-end" },
      type: "number",
      Cell: (rowData) => {
        return rowData.row.totalHours;
      },
    },
  ].filter(Boolean);

  const detailColumns = [
    isTotal
      ? {
          accessor: "Date",
          header: "Date",
          headerProps: { className: "text-start", style: { maxWidth: 80 } },
          cellProps: { className: "text-start", style: { maxWidth: 80 } },
          Cell: (rowData) => {
            const date = moment(rowData.row.date);
            return `${date.format("dd")} ${date.format(dateFormat)}`;
          },
        }
      : null,
    {
      accessor: "Work Order",
      header: "Work Order",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start text-truncate" },
      Cell: (rowData) => {
        const isNonJobTime = rowData.row.isNonJobTime;
        return isNonJobTime ? (
          <span
            data-value={rowData.row.workOrder}
            data-testid="work-order-link"
          >
            {rowData.row.workOrder}
          </span>
        ) : (
          <a
            className="text-link"
            target="_blank"
            rel="noreferrer"
            data-value={rowData.row.workOrder}
            data-testid="work-order-link"
            href={`/workorders/details/${rowData.row.workOrderId}/${WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB}`}
          >
            {rowData.row.workOrder}
          </a>
        );
      },
    },
    {
      accessor: "Customer",
      header: "Customer",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center text-truncate" },
      Cell: (rowData) => {
        const isNonJobTime = rowData.row.isNonJobTime;
        return isNonJobTime || rowData.row.customer === "-" ? (
          <span data-value={rowData.row.customer} data-testid="customer-link">
            {rowData.row.customer}
          </span>
        ) : (
          <a
            className="text-link"
            target="_blank"
            rel="noreferrer"
            data-value={rowData.row.customer}
            data-testid="customer-link"
            href={`/customers/${rowData.row.customerId}`}
          >
            {rowData.row.customer}
          </a>
        );
      },
    },
    {
      accessor: "Type",
      header: "Type",
      headerProps: { className: "text-center", style: { maxWidth: 180 } },
      cellProps: { className: "text-center", style: { maxWidth: 180 } },
      Cell: (rowData) => {
        return rowData.row.type;
      },
    },
  ].filter(Boolean);

  if (startEndTimeSettingEnabled) {
    detailColumns.push({
      accessor: "Start Time",
      header: "Start Time",
      headerProps: { className: "text-center", style: { maxWidth: 120 } },
      cellProps: { className: "text-center", style: { maxWidth: 120 } },
      type: "string",
      Cell: (rowData) => {
        const startTime = rowData.row.startTime;
        const isNonJobTime = rowData.row.isNonJobTime;
        const value = moment(startTime).isValid()
          ? sharedHelper.formatDateTime(startTime, "h:mm a")
          : "-";
        return (
          <span
            className={isNonJobTime ? "" : `text-link`}
            data-value={value}
            onClick={() => (isNonJobTime ? null : onEditEntry(rowData.row))}
          >
            {value}
          </span>
        );
      },
    });
    detailColumns.push({
      accessor: "End Time",
      header: "End Time",
      headerProps: { className: "text-center", style: { maxWidth: 120 } },
      cellProps: { className: "text-center", style: { maxWidth: 120 } },
      type: "string",
      Cell: (rowData) => {
        const endTime = rowData.row.endTime;
        const startTime = rowData.row.startTime;
        const isNonJobTime = rowData.row.isNonJobTime;
        const value = moment(endTime).isValid()
          ? sharedHelper.formatDateTime(endTime, "h:mm a")
          : "-";
        const isNextDay = moment(endTime).isAfter(moment(startTime), "date");
        return (
          <span
            className={`${
              isNonJobTime ? "" : `text-link`
            } d-flex align-items-start justify-content-center`}
            data-value={value}
            onClick={() => (isNonJobTime ? null : onEditEntry(rowData.row))}
          >
            {value}
            {isNextDay ? (
              <small className="text-muted ms-1" style={{ fontSize: 10 }}>
                +1
              </small>
            ) : (
              ""
            )}
          </span>
        );
      },
    });
  }

  detailColumns.push({
    accessor: "Hours",
    header: "Hours",
    headerProps: { className: "text-center", style: { maxWidth: 120 } },
    cellProps: { className: "text-center", style: { maxWidth: 120 } },
    type: "number",
    Cell: (rowData) => {
      const value =
        rowData.row.endTime === "In Progress"
          ? rowData.row.endTime
          : calculateHoursWithExceptions(rowData.row);
      const formattedValue = isNaN(value) ? value : value.toFixed(2);
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          data-value={formattedValue}
        >
          <span
            onClick={() => onEditEntry(rowData.row)}
            className="text-link"
            style={{ minmaxWidth: 50 }}
          >
            {formattedValue}
          </span>
        </div>
      );
    },
  });

  detailColumns.push({
    accessor: "id",
    header: "",
    disableFilter: true,
    headerProps: { className: "text-end", style: { maxWidth: 150 } },
    cellProps: { className: "text-end", style: { maxWidth: 150 } },
    type: "number",
    Cell: (rowData) => {
      const isNonJobTime = rowData.row.isNonJobTime;
      return (
        <div className="d-flex align-items-center justify-content-end">
          {isNonJobTime ? (
            <>
              {rowData.row.autoGeneration ? (
                <TooltipItem
                  id="recalculate-tooltip"
                  title="Recalculate"
                  className="cursor-pointer"
                >
                  <Icon
                    name="refresh-cw"
                    onClick={() => onRecalculateNonJobTime(rowData.row.id)}
                    className="ms-3 text-primary cursor-pointer"
                    data-testid="sync-icon"
                  />
                </TooltipItem>
              ) : null}
              {rowData.row.isOverwritten ? (
                <div className="d-flex align-items-center justify-content-center">
                  <TooltipItem id="overwritten-tooltip" title="Overwritten">
                    <Icon
                      name="info"
                      className="ms-3 text-primary"
                      data-testid="info-circle-icon-overwritten"
                    />
                  </TooltipItem>
                </div>
              ) : rowData.row.autoGeneration ? (
                <TooltipItem
                  id="auto-tooltip"
                  title="Auto Generated"
                  cursor="default"
                >
                  <Icon
                    name="info"
                    className="ms-3 text-primary"
                    data-testid="info-circle-icon-auto"
                  />
                </TooltipItem>
              ) : null}
              {isSuperAdmin ? (
                <TooltipItem
                  id="billing-type-tooltip"
                  title={rowData.row.billingType}
                  cursor="default"
                >
                  <Icon
                    name="info"
                    className="ms-3 text-primary"
                    data-testid="info-circle-icon-billing-type"
                  />
                </TooltipItem>
              ) : null}
              {isSuperAdmin && rowData.row.generationRule ? (
                <TooltipItem id="rule-tooltip" title="Generation Rule">
                  <Icon
                    onClick={() => onNonJobTimeInfo(rowData.row.generationRule)}
                    name="info"
                    className="ms-3 text-primary cursor-pointer"
                    data-testid="info-circle-icon-rule"
                  />
                </TooltipItem>
              ) : null}
            </>
          ) : rowData.row.exceptions.length ? (
            <TooltipItem
              id="exceptions-tooltip"
              title="Has Exceptions"
              cursor="default"
            >
              <Icon
                name="info"
                className="ms-3 text-primary"
                data-testid="info-circle-icon-auto"
              />
            </TooltipItem>
          ) : (
            "-"
          )}
        </div>
      );
    },
  });

  const dateData = useMemo(
    () =>
      data
        ? isTotal
          ? {
              hours: Object.values(data)
                .filter((e) => typeof e === "object")
                .reduce((p, c) => p + c.hours, 0),
              rawTimes: Object.values(data)
                .filter((e) => typeof e === "object")
                .reduce((p, c) => [...p, ...c.rawTimes], []),
            }
          : data[date]
        : {},
    [data, isTotal, date]
  );

  const { hours = 0, rawTimes = [] } = dateData;

  const totalTableData = useMemo(() => {
    const formattedDate = `${moment(date).format("dd")} ${moment(date).format(
      dateFormat
    )}`;
    return [
      {
        date: formattedDate,
        totalHours: Math.max(hours, 0).toFixed(2),
      },
    ];
  }, [hours, date]);

  const detailTableData = useMemo(
    () =>
      rawTimes.map((rawTime) => {
        const data = {
          id: rawTime.id,
          date: rawTime.date || rawTime.crewWorkDay?.date || "-",
          isNonJobTime: rawTime.isNonJobTime,
          exceptions: rawTime.exceptions || [],
          workOrder: rawTime.crewWorkDay?.crew.workOrder.workOrderNumber || "-",
          workOrderId: rawTime.crewWorkDay?.crew.workOrder.id || "-",
          customer:
            rawTime.crewWorkDay?.crew.workOrder.customer?.customerName || "-",
          customerId: rawTime.crewWorkDay?.crew.workOrder.customer?.id || "-",
          type: rawTime.type,
          hours: rawTime.hours,
        };
        if (rawTime.isNonJobTime) {
          data.autoGeneration = rawTime.autoGeneration;
          data.billingType = rawTime.billingType;
          data.generationRule = rawTime.generationRule;
          data.isOverwritten = rawTime.isOverwritten;
        }
        if (startEndTimeSettingEnabled) {
          data.startTime = rawTime.startTime || "-";
          data.endTime =
            rawTime.endTime || (rawTime.startTime ? "In Progress" : "-");
        }
        return data;
      }),
    [rawTimes, startEndTimeSettingEnabled]
  );

  return editWorkTimeModal ? (
    <EditWorkTimeModal
      workTimes={[editWorkTimeModal]}
      employee={editWorkTimeModal.employee}
      onClose={() => {
        setEditWorkTimeModal();
      }}
      onSubmit={() => {
        onSubmitTimes();
        setEditWorkTimeModal();
      }}
    />
  ) : editTravelTimeModal ? (
    <EditTravelTimeModal
      travelTimes={[editTravelTimeModal]}
      employee={editTravelTimeModal.employee}
      onClose={() => {
        setEditTravelTimeModal();
      }}
      onSubmit={() => {
        onSubmitTimes();
        setEditTravelTimeModal();
      }}
    />
  ) : editNonJobTimeModal ? (
    <EditNonJobTimeModal
      nonJobTime={editNonJobTimeModal}
      employee={editNonJobTimeModal.employee}
      onClose={() => {
        setEditNonJobTimeModal();
      }}
      onSubmit={() => {
        onSubmitTimes();
        setEditNonJobTimeModal();
      }}
    />
  ) : informationModal ? (
    <InformationModal {...informationModal} />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} onClosed={handleClose} size="xl">
      <ModalHeader
        toggle={handleClose}
        className="d-flex justify-content-between"
      >
        {dateData.employee || ""}
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader size="sm" />
        ) : (
          <>
            <AdvanceTable
              exportable
              exportName="PayrollDateTotals.csv"
              columns={totalColumns}
              data={totalTableData}
              pageSize={1}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "mb-0 table-layout-fixed",
              }}
            />
            <AdvanceTable
              className="mt-2"
              exportable
              exportName="PayrollDateDetails.csv"
              columns={detailColumns}
              data={detailTableData}
              pageSize={Math.max(rawTimes.length, 1)}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "table-layout-fixed",
              }}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end" data-testid="modal-footer">
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PayrollDateDetail;
