import React, { useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const RestrictEditTemplateModal = ({
  isOpen,
  onClose,
  template,
  onDuplicate,
  onView,
  fieldToRestrict,
}) => {
  const handleDuplicate = useCallback(() => {
    onDuplicate();
    onClose();
  }, [onDuplicate, onClose]);

  const handleView = useCallback(() => {
    onView();
    onClose();
  }, [onView, onClose]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={onClose}>Template Editing Restricted</ModalHeader>
      <ModalBody>
        <p>
          Is not possible to edit templates with inspections assigned. Please
          create a new template or duplicate this one to use it as a baseline.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleView}>
          View Template
        </Button>
        <Button color="primary" onClick={handleDuplicate}>
          Duplicate
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RestrictEditTemplateModal;
