import React, { useEffect, useState } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import { useVerify2FA } from "../../api/2FA.hooks";
import Loader from "../Loader";
import Icon from "../Icon";
import CustomCheckboxWithLabel from "../CustomCheckboxWithLabel";
import InformationModal from "../InformationModal";
import { sharedHelper } from "../../helpers/sharedHelper";

const TwoFAForm = ({
  onComplete,
  onBack,
  message,
  userId,
  className = "login-input",
  isSetup,
}) => {
  const {
    isLoading: isLoadingVerify2FA,
    mutate: verify2FA,
    data: verify2FAData,
    error,
  } = useVerify2FA();

  const [oneTimePassword, setOneTimePassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(false);

  const [informationModal, setInformationModal] = useState();

  useEffect(() => {
    if (verify2FAData) {
      onComplete(verify2FAData.data);
    }
  }, [verify2FAData, onComplete]);

  useEffect(() => {
    if (error) {
      sharedHelper.errorToast(
        error.response.data.msg || "There was an error with your request."
      );
    }
  }, [error]);

  const onNext = async (e) => {
    e.preventDefault();
    setLoading(true);
    await verify2FA({
      token: oneTimePassword,
      userId,
      remember,
    });
    setLoading(false);
  };

  const onLostDevice = () => {
    setInformationModal({
      title: "2FA Device Lost",
      rawBody: true,
      size: "sm",
      body: (
        <span>
          If you lost your 2FA device please contact support at{" "}
          <a href="mailto:support@crewos.io">support@crewos.io</a>
        </span>
      ),
      onClose: () => setInformationModal(),
    });
  };

  return (
    <Form
      onSubmit={onNext}
      className="flex-grow-1 d-flex flex-column justify-content-start w-100"
    >
      <FormGroup className="d-flex flex-column">
        {message ? (
          <Label className="fw-bold" for="otpUser">
            {message}
          </Label>
        ) : null}
        <input
          className={`mb-2 ${className}`}
          id="otpUser"
          data-test-id="otp-input"
          maxLength="50"
          required={true}
          name="otp"
          type="text"
          placeholder="Enter an oTP"
          value={oneTimePassword}
          onChange={(event) =>
            setOneTimePassword(event.currentTarget.value.trim())
          }
        />
      </FormGroup>
      {!isSetup ? (
        <FormGroup>
          <div className="d-flex" onClick={() => setRemember(!remember)}>
            <CustomCheckboxWithLabel
              checked={remember}
              label="Remember this device"
            />
          </div>
        </FormGroup>
      ) : null}
      <div className="text-center mt-3 d-flex flex-column justify-content-center">
        {isLoadingVerify2FA || loading ? (
          <Loader size="sm" />
        ) : (
          <div className="w-100 d-flex">
            <Button
              className="me-2"
              color="secondary"
              onClick={onBack}
              size="lg"
            >
              <Icon name="arrow-left" />
            </Button>
            <Button color="primary" type="submit" className="flex-grow-1">
              CONFIRM
            </Button>
          </div>
        )}
      </div>
      {!isSetup ? (
        <div className="mt-4 text-center">
          <small className="text-link-a" onClick={onLostDevice}>
            Lost your device?
          </small>
        </div>
      ) : null}
      {informationModal ? <InformationModal {...informationModal} /> : null}
    </Form>
  );
};

export default TwoFAForm;
