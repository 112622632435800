import React, { useEffect, useState } from "react";
import { useDeleteAsset, useGetAssets } from "../api/Assets.hooks";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import AssetModal from "../components/AssetModal";
import { DebounceInput } from "react-debounce-input";
import { useNavigate, useParams } from "react-router";

const { AdvanceTable, AdvanceTablePagination, ConfirmationModal, Icon } =
  components;

const columns = (onDelete, onEdit, customerLocationId) =>
  [
    {
      accessor: "name",
      header: "Name",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      // We can also define a custom Cell if needed, but let's keep it simple
    },
    {
      accessor: "modelNumber",
      header: "Model #",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
    },
    {
      accessor: "serialNumber",
      header: "Serial #",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
    },
    {
      accessor: "manufacturer",
      header: "Manufacturer",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
    },
    {
      accessor: "assetType.name",
      header: "Type",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      disableFilter: true,
      disableSortBy: true,
    },
    customerLocationId
      ? false
      : {
          accessor: "customerLocation.customer.customerName",
          header: "Customer",
          headerProps: { className: "text-truncate" },
          cellProps: { className: "text-truncate" },
          Cell: (rowData) => {
            return rowData.row.customerLocation?.customer?.customerName || "-";
          },
        },
    customerLocationId
      ? false
      : {
          accessor: "customerLocation.shipToName",
          header: "Location",
          headerProps: { className: "text-truncate" },
          cellProps: { className: "text-truncate" },
          Cell: (rowData) => {
            return rowData.row.customerLocation?.shipToName || "-";
          },
        },
    {
      accessor: "inspectionTemplateWorkOrders",
      header: "Templates",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      Cell: (rowData) => {
        return rowData.row.inspectionTemplateWorkOrders
          ? rowData.row.inspectionTemplateWorkOrders.length
          : 0;
      },
    },
    {
      accessor: "id",
      header: "",
      disableFilter: true,
      cellProps: {
        className: "text-end",
        style: { maxWidth: 300 },
      },
      headerProps: {
        className: "text-truncate text-end",
        style: { maxWidth: 300 },
      },
      Cell: (rowData) => (
        <>
          <Button
            size="sm"
            color="none"
            className="ms-2 text-primary"
            onClick={() => onEdit(rowData.row)}
          >
            Edit
          </Button>
          <Button
            size="sm"
            className="ms-2 text-danger"
            color="none"
            onClick={() => onDelete(rowData.row)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ].filter(Boolean);

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const Assets = () => {
  const { locationId: customerLocationId } = useParams();
  const navigate = useNavigate();

  const [assets, setAssets] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(null);

  const [confirmationModal, setConfirmationModal] = useState(null);

  const {
    data: getAssetsData,
    isLoading: isLoadingGetAssets,
    get: getAssets,
  } = useGetAssets();

  useEffect(() => {
    const params = {
      search,
      page,
      pageSize: sizePerPage,
    };
    if (customerLocationId) {
      params.customerLocationId = customerLocationId;
    }
    getAssets(params);
  }, [getAssets, customerLocationId, search, refresh, page, sizePerPage]);

  useEffect(() => {
    if (getAssetsData) {
      setAssets(getAssetsData);
    }
  }, [getAssetsData]);

  const { data: deleteAssetData, deleteAsset } = useDeleteAsset();

  useEffect(() => {
    if (deleteAssetData) {
      sharedHelper.successToast("Asset deleted");
      setRefresh((prev) => !prev);
    }
  }, [deleteAssetData]);

  const onEdit = (asset) => setUpdateModal(asset);

  const onDelete = (asset) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteAsset(asset.id);
        setConfirmationModal(null);
      },
      onClose: () => {
        setConfirmationModal(null);
        setRefresh((prev) => !prev);
      },
      title: "Delete Asset",
      body: `Are you sure you want to delete ${asset.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="d-flex px-2 text-dark">
            <div
              className="d-flex flex-column justify-content-center cursor-pointer"
              onClick={() => sharedHelper.goBack(navigate)}
              data-testid="back-button"
            >
              <Icon
                name="chevron-left"
                className="me-2 text-muted rounded-circle back-arrow bg-white fs-4"
              />
            </div>
            <div className="text-dark flex-grow-1 d-flex align-items-center">
              <h2 className="mb-0">Assets</h2>
              <small className="text-muted ms-2 pt-1">
                ({assets.count || 0})
              </small>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search assets"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            {customerLocationId ? (
              <Button color="primary" onClick={() => setCreateModal(true)}>
                Create
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onDelete, onEdit, customerLocationId)}
              data={assets.data || []}
              pageSize={sizePerPage}
              isLoading={isLoadingGetAssets}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
              // Add other props if needed
            />
          </div>
          <AdvanceTablePagination
            totalCount={assets.count || 0}
            pageCount={assets.totalPages || 0}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>

      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : createModal ? (
        <AssetModal
          defaultAsset={{
            dynamicAttributes: {},
            customerLocationId,
          }}
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : updateModal ? (
        <AssetModal
          defaultAsset={updateModal}
          onClose={() => setUpdateModal(null)}
          onSubmit={() => {
            setUpdateModal(null);
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
    </Container>
  );
};

export default Assets;
