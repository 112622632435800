import React, { useState, useCallback, useEffect } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import AssignTemplateWOForm from "./AssignTemplateWOForm";
import { useCreateTemplateWorkOrder } from "../../api/Safety.hooks";
import { sharedHelper } from "@crewos/shared";

const AssignTemplateWOModal = ({ isOpen, onClose, onAssign, workOrder }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [error, setError] = useState({});
  const [isRequired, setIsRequired] = useState(false);

  const {
    mutate: createTemplateWO,
    isLoading: isLoadingTemplateWO,
    data: dataTemplateWO,
  } = useCreateTemplateWorkOrder();

  const handleSelectTemplate = useCallback((template) => {
    setSelectedTemplate(template);
    setIsRequired(template.isRequired);
    setError({});
  }, []);

  const handleAssign = useCallback(
    async ($event) => {
      $event.preventDefault();
      let withError = false;
      if (!selectedTemplate) {
        setError((prev) => {
          return {
            ...prev,
            template: ["Please select a template"],
          };
        });
        withError = true;
      }
      if (withError) {
        return;
      }

      const formData = new FormData($event.target);
      const data = {
        ...Object.fromEntries(formData.entries()),
        isRequired,
        safetyTemplateId: selectedTemplate?.value,
        workOrderId: workOrder.id,
      };
      await createTemplateWO(data);
    },
    [selectedTemplate, createTemplateWO, workOrder, isRequired]
  );

  const handleUpdateRequired = useCallback((isRequired) => {
    setIsRequired(isRequired);
  }, []);

  useEffect(() => {
    if (dataTemplateWO) {
      sharedHelper.successToast("Template assigned");
      onAssign();
    }
  }, [dataTemplateWO, onAssign]);

  return (
    <Modal isOpen={isOpen} size="sm">
      <Form onSubmit={handleAssign}>
        <ModalHeader toggle={onClose}>Assign Safety Template</ModalHeader>
        <ModalBody>
          <AssignTemplateWOForm
            onSelectTemplate={handleSelectTemplate}
            error={error}
            onUpdateRequired={handleUpdateRequired}
            isRequired={isRequired}
          />
        </ModalBody>
        <ModalFooter className="d-flex flex-row flex-nowrap">
          <Button
            style={{ width: "100%" }}
            color="secondary"
            onClick={onClose}
            disabled={isLoadingTemplateWO}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "100%" }}
            color="primary"
            type="submit"
            disabled={isLoadingTemplateWO}
          >
            Assign
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AssignTemplateWOModal;
