import { components, data, helpers, sharedHelper } from "@crewos/shared";

import { useGetSmartReports } from "./api/Reports.hooks";
import LaborByEmployeeReport from "./pages/LaborByEmployeeReport";
import LaborByJobReport from "./pages/LaborByJobReport";
import JobLaborReport from "./pages/JobLaborReport";
import PayrollReport from "./pages/PayrollReport";
import SmartReports from "./pages/SmartReports";

import PayrollDateDetail from "./components/PayrollDateDetail";
import SmartReport from "./pages/SmartReport";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const { Settings } = components;

const REPORTS = "REPORTS";

const SMART_REPORTS_READ_ONLY = "SMART_REPORTS_READ_ONLY";
const SMART_REPORTS_IN_SIDEBAR = "SMART_REPORTS_IN_SIDEBAR";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

// Routes
const routes = (
  smartReportsReadOnlyEnabled,
  smartReportsInSidebarEnabled,
  smartReports = []
) => [
  {
    name: "Reports",
    icon: "archive",
    sidebar: true,
    path: "/reports",
    package: "reports",
    entries: [
      {
        path: `payroll`,
        name: "Payroll",
        sidebar: true,
        component: PayrollReport,
      },
      {
        path: "labor-employee",
        name: "Labor By Employee",
        sidebar: true,
        component: LaborByEmployeeReport,
      },
      {
        path: "labor-job",
        name: "Labor By Job",
        sidebar: true,
        component: LaborByJobReport,
      },
      {
        path: `smart`,
        name: `${smartReportsReadOnlyEnabled ? "Preset" : "Smart"} Reports`,
        sidebar: true,
        scopes: smartReportsInSidebarEnabled
          ? ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"]
          : ["IS_SUPER_ADMIN_USER"],
        component: SmartReports,
      },
      {
        path: `smart/:id`,
        sidebar: false,
        component: SmartReport,
      },
      ...smartReports
        .filter((report) => report.context === "GLOBAL" && report.showSidebar)
        .map((smartReport) => ({
          path: `smart/${smartReport.id}`,
          name: smartReport.name,
          sidebar: true,
          component: SmartReport,
        })),
    ],
  },
  {
    sidebar: true,
    name: "Reports Settings",
    path: `reports`,
    isSetting: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "reports",
    category: "Reports",
    component: Settings,
    order: 1,
  },
];

const Routes = createRoutes(routes());

const setReportSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];

  const isSuperAdmin = sharedHelper.userHasScope(userData?.role, [
    IS_SUPER_ADMIN_USER,
  ]);

  const smartReportsReadOnlyEnabled =
    sharedHelper.isSettingEnabled(packages, SMART_REPORTS_READ_ONLY) &&
    !isSuperAdmin;

  const smartReportsInSidebarEnabled = sharedHelper.isSettingEnabled(
    packages,
    SMART_REPORTS_IN_SIDEBAR
  );

  const sidebarRoutes = routes(
    smartReportsReadOnlyEnabled,
    smartReportsInSidebarEnabled,
    userData.smartReports
  ).filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "reports");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: REPORTS, order: 6 },
    });
  }
};

export {
  PayrollDateDetail,
  Routes as ReportRoutes,
  setReportSidebarRoutes,
  useGetSmartReports,
  SmartReport,
  SmartReports,
  LaborByEmployeeReport,
  JobLaborReport,
};
