import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import { useGetCustomers } from "../api/Customers.hooks";
import CustomerLocations from "../components/customerDetails/CustomerLocations";
import { WorkOrders } from "@crewos/workorders";

const { Loader, Icon } = components;

const CustomerDetails = () => {
  const { customerId } = useParams();

  const [customerData, setCustomerData] = useState();

  const [refresh, setRefresh] = useState();

  const navigate = useNavigate();

  const {
    data: customer,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  useEffect(() => {
    getCustomers({ id: customerId });
  }, [getCustomers, customerId, refresh]);

  useEffect(() => {
    if (customer) {
      setCustomerData(customer);
    }
  }, [customer, setCustomerData]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header d-flex flex-column align-items-start">
          <div className="d-flex align-items-center col-12 px-0">
            <div
              className="d-flex flex-column justify-content-center cursor-pointer"
              onClick={() => sharedHelper.goBack(navigate)}
              data-testid="back-button"
            >
              <Icon
                name="chevron-left"
                className="me-2 text-muted rounded-circle back-arrow bg-white fs-4"
              />
            </div>
            <h2 className="mb-0">{customerData?.customerName}</h2>
          </div>
          <div className="d-flex align-items-center justify-content-start mt-3 small">
            <div className="d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-center">
                <span className="text-muted">Customer #:</span>
                <div className="ms-1">{customerData?.customerNo}</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <span className="mx-2">·</span>
              <div className="d-flex align-items-center">
                <span className="text-muted">Email:</span>
                <div className="ms-1">{customerData?.email || "-"}</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <span className="mx-2">·</span>
              <div className="d-flex align-items-center">
                <span className="text-muted">Address:</span>
                <div className="ms-1">{customerData?.address || "-"}</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <span className="mx-2">·</span>
              <div className="d-flex align-items-center">
                <span className="text-muted">City:</span>
                <div className="ms-1">{customerData?.city || "-"}</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <span className="mx-2">·</span>
              <div className="d-flex align-items-center">
                <span className="text-muted">Zip Code:</span>
                <div className="ms-1">{customerData?.zipCode || "-"}</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <span className="mx-2">·</span>
              <div className="d-flex align-items-center">
                <span className="text-muted">Country:</span>
                <div className="ms-1">{customerData?.countryCode || "-"}</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <span className="mx-2">·</span>
              <div className="d-flex align-items-center">
                <span className="text-muted">Telephone:</span>
                <div className="ms-1">{customerData?.phone || "-"}</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <span className="mx-2">·</span>
              <div className="d-flex align-items-center">
                <span className="text-muted">Fax:</span>
                <div className="ms-1">{customerData?.faxNo || "-"}</div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          {isLoadingCustomers ? (
            <Loader />
          ) : customerData ? (
            <div className="flex flex-column">
              <div className="d-flex flex-wrap">
                <CustomerLocations
                  customer={customerData}
                  setRefresh={setRefresh}
                />
                <WorkOrders customerId={customerData.id} />
              </div>
            </div>
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CustomerDetails;
