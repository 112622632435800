import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { useGetWorkTimes } from "../api/WorkTimes.hooks";
import EmployeeCrewRow from "./EmployeeCrewRow";
import {
  crewHelper,
  useCrewWorkDays,
  CREW_WORK_DAY_ACTIONS,
} from "@crewos/crews";

const { useAuth, useWorkOrderDetails, WORK_ORDER_DETAILS_TABS } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const { Loader, Icon } = components;

const CrewWorkDayWorkTimes = ({ crew }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();
  const [authContext] = useAuth();

  const [workTimes, setWorkTimes] = useState([]);
  const [refresh, setRefresh] = useState();

  //only one crew work day per date/crew
  const crewWorkDay = useMemo(
    () =>
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate]?.find(
        (cwd) => cwd.crewId === crew.id
      ),
    [workOrderDetails.selectedDate, crewWorkDaysContext.crewWorkDays, crew]
  );

  const isReadOnly = useMemo(
    () =>
      workOrderDetails.activeTab === WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB,
    [workOrderDetails.activeTab]
  );

  const directAssignationEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        DIRECT_ASSIGNATION
      ),
    [authContext.userData]
  );

  const employeeCrewPastDatesEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
      ),
    [authContext.userData]
  );

  const {
    data: getWorkTimesData,
    isLoading: isLoadingGetWorkTimes,
    get: getWorkTimes,
  } = useGetWorkTimes();

  useEffect(() => {
    if (crewWorkDay) {
      getWorkTimes({
        crewWorkDayId: crewWorkDay.id,
      });
    }
  }, [getWorkTimes, refresh, crewWorkDay]);

  useEffect(() => {
    if (getWorkTimesData) {
      setWorkTimes(getWorkTimesData);
    }
  }, [getWorkTimesData, setWorkTimes]);

  const activeCrew = useMemo(
    () =>
      crewHelper.filterCrew(
        crew,
        employeeCrewPastDatesEnabled,
        workOrderDetails.selectedDate
      ),
    [crew, employeeCrewPastDatesEnabled, workOrderDetails.selectedDate]
  );

  const onRefresh = useCallback(() => {
    if (!crewWorkDay) {
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH,
      });
    } else {
      setRefresh((prev) => !prev);
    }
  }, [crewWorkDay, setCrewWorkDaysContext]);

  useEffect(() => {
    if (
      crewWorkDaysContext.refreshCrewSection.crewId === crew.id &&
      crewWorkDaysContext.refreshCrewSection.section === "WORK_TIMES"
    ) {
      onRefresh();
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH_CREW_SECTION_SUCCESS,
      });
    }
  }, [
    crewWorkDaysContext.refreshCrewSection,
    setCrewWorkDaysContext,
    crew,
    onRefresh,
  ]);

  return (
    <Card className="w-100 px-0 box-shadow-none mb-3">
      <CardBody className="pb-2">
        <div className="mb-n1 d-flex align-items-center justify-content-between">
          <h3>Work Time</h3>
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={onRefresh}
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </div>
        {!directAssignationEnabled ? (
          <div className="mt-3 fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-graylight border-radius-default">
            <div className="col-4">CREW MEMBER</div>
            <div className={`col-6 text-${isReadOnly ? "end" : "center"}`}>
              WORK TIME
            </div>
            {!isReadOnly ? <div className="col-2"></div> : null}
          </div>
        ) : null}
        {isLoadingGetWorkTimes ? (
          <Loader size="sm" className="my-3" />
        ) : activeCrew.length ? (
          !workTimes.length ? (
            <div className="my-1 p-2 small text-muted text-center">
              No work times
            </div>
          ) : (
            activeCrew.map((employeeCrew) => {
              const employeeWorkTimes = workTimes.filter(
                (wt) => wt.employeeId === employeeCrew.employeeId
              );
              return (
                <EmployeeCrewRow
                  key={employeeCrew.id}
                  employeeCrew={employeeCrew}
                  workTimes={employeeWorkTimes}
                  onRefresh={onRefresh}
                />
              );
            })
          )
        ) : (
          <div className="my-1 p-2 small text-muted text-center">
            Empty crew for date
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const CrewWorkDayWorkTimesWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(authContext.userData?.packages, "worktimes")
  ) {
    return null;
  }

  return <CrewWorkDayWorkTimes {...props} />;
};

export default CrewWorkDayWorkTimesWrapper;
