// DynamicAttributesModal.js
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import Loader from "../Loader";
import AttributeFormModal from "./AttributeFormModal";
import { useDeleteDynamicAttribute } from "../../api/DynamicAttributes.hooks";
import { sharedHelper } from "../../helpers/sharedHelper";
import ConfirmationModal from "../ConfirmationModal";

const DynamicAttributesModal = ({
  entity,
  dynamicAttributes,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteAttribute, setDeleteAttribute] = useState(false);
  const [touched, setTouched] = useState(false);

  const {
    isLoading,
    deleteDynamicAttribute,
    data: deleteDynamicAttributeData,
  } = useDeleteDynamicAttribute();

  const [confirmationModal, setConfirmationModal] = useState();

  const doClose = () => {
    onClose(touched);
  };

  const doDelete = useCallback(
    (id) => {
      setConfirmationModal({
        isOpen: true,
        confirmColor: "danger",
        onSubmit: async () => {
          await deleteDynamicAttribute(id);
          setConfirmationModal();
        },
        onClose: () => {
          setConfirmationModal();
          setDeleteAttribute();
        },
        title: "Delete Dynamic Attribute",
        body: `Are you sure you want to delete this attribute and all its data related?`,
        confirmText: "Delete",
      });
    },
    [deleteDynamicAttribute]
  );

  useEffect(() => {
    if (deleteDynamicAttributeData && deleteAttribute) {
      sharedHelper.successToast(`Dynamic attribute deleted`);
      setTouched(true);
      onDelete(deleteAttribute);
      setDeleteAttribute();
    }
  }, [deleteDynamicAttributeData, deleteAttribute, onDelete]);

  useEffect(() => {
    if (deleteAttribute) {
      doDelete(deleteAttribute);
    }
  }, [deleteAttribute, doDelete]);

  return confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : editModalOpen ? (
    <AttributeFormModal
      entity={entity}
      attribute={editModalOpen}
      onClose={() => setEditModalOpen()}
      onSubmit={(value) => {
        setEditModalOpen();
        setTouched(true);
        onSubmit(value);
      }}
    />
  ) : (
    <Modal isOpen={true} onClosed={doClose} size="xl">
      <ModalHeader toggle={doClose} className="d-flex justify-content-between">
        Dynamic Attributes
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center w-100">
            <Loader size="sm" />
          </div>
        ) : dynamicAttributes.length ? (
          <Table striped responsive className="small">
            <thead>
              <tr>
                <th>Key</th>
                <th>Entity</th>
                <th>Name</th>
                <th>Label</th>
                <th>Type</th>
                <th>Description</th>
                <th>Required</th>
                <th>Placeholder</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dynamicAttributes.map((attr) => (
                <tr key={attr.id}>
                  <td>{attr.key}</td>
                  <td>{attr.entity}</td>
                  <td>{attr.name}</td>
                  <td>{attr.label}</td>
                  <td>{attr.type}</td>
                  <td>{attr.description || ""}</td>
                  <td>{attr.required ? "Yes" : "No"}</td>
                  <td>{attr.placeholder || ""}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        color="primary"
                        size="sm"
                        className="me-2"
                        data-testid="edit-button"
                        onClick={() => setEditModalOpen(attr)}
                      >
                        Edit
                      </Button>
                      <Button
                        color="danger"
                        size="sm"
                        data-testid="delete-button"
                        onClick={() => {
                          setDeleteAttribute(attr.id);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center text-muted w-100">
            No attributes added
          </div>
        )}
      </ModalBody>

      <ModalFooter className="justify-content-between">
        <Button
          data-testid="discard-button"
          color="secondary"
          onClick={doClose}
          className="text-dark"
        >
          Close
        </Button>
        <Button
          data-testid="add-button"
          className="ms-2"
          color="primary"
          onClick={() => setEditModalOpen({})}
        >
          Add Attribute
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DynamicAttributesModal;
