import moment from "moment";

export const relevantDiffDates = (startTime, endTime) => {
  const startMoment = moment.utc(startTime);
  const endMoment = moment.utc(endTime);

  // Calculate difference in milliseconds
  const diffMs = endMoment.diff(startMoment, "milliseconds");

  // If times are exactly the same
  if (diffMs === 0) {
    return "";
  }

  // If endTime is before startTime
  if (diffMs < 0) {
    return "few seconds";
  }

  // Now check differences in descending order
  const diffYears = endMoment.diff(startMoment, "years");
  if (diffYears > 0) {
    return `${diffYears} years`;
  }

  const diffMonths = endMoment.diff(startMoment, "months");
  if (diffMonths > 0) {
    return `${diffMonths} months`;
  }

  const diffDays = endMoment.diff(startMoment, "days");
  if (diffDays > 0) {
    return `${diffDays} days`;
  }

  const diffHours = endMoment.diff(startMoment, "hours");
  if (diffHours > 0) {
    return `${diffHours} hours`;
  }

  const diffMinutes = endMoment.diff(startMoment, "minutes");
  if (diffMinutes > 0) {
    return `${diffMinutes} minutes`;
  }

  const diffSeconds = endMoment.diff(startMoment, "seconds");
  if (diffSeconds > 0) {
    return `${diffSeconds} seconds`;
  }

  // If we reach here, difference is > 0 ms but < 1s
  return "few seconds";
};
