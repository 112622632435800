import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";

import { components, data } from "@crewos/shared";

const { useGetAllDynamicAttributes } = data;

const { DynamicAttributesModal } = components;

const SettingsHeaderActions = () => {
  const { get: getDynamicAttributes, data: dynamicAttributesData } =
    useGetAllDynamicAttributes();

  const [refresh, setRefresh] = useState();
  const [dynamicAttributes, setDynamicAttributes] = useState([]);
  const [dynamicAttributesModal, setDynamicAttributesModal] = useState();

  useEffect(() => {
    getDynamicAttributes({ entity: "workOrder" });
  }, [getDynamicAttributes, refresh]);

  useEffect(() => {
    if (dynamicAttributesData) {
      setDynamicAttributes(dynamicAttributesData);
    }
  }, [dynamicAttributesData]);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <Button
        size="sm"
        className="me-3 d-flex align-items-center"
        color="primary"
        onClick={() => setDynamicAttributesModal(true)}
      >
        Dynamic Attributes
      </Button>
      {dynamicAttributesModal ? (
        <DynamicAttributesModal
          entity="workOrder"
          dynamicAttributes={dynamicAttributes}
          onDelete={(id) => {
            const index = dynamicAttributes.findIndex((a) => a.id === id);
            const updatedData = [...dynamicAttributes];
            updatedData.splice(index, 1);
            setDynamicAttributes([...updatedData]);
          }}
          onSubmit={(value) => {
            if (value.id) {
              const index = dynamicAttributes.findIndex(
                (a) => a.id === value.id
              );
              if (index > -1) {
                const updatedData = [...dynamicAttributes];
                updatedData.splice(index, 1, value);
                setDynamicAttributes([...updatedData]);
              } else {
                setDynamicAttributes([...dynamicAttributes, value]);
              }
            } else {
              setDynamicAttributes([...dynamicAttributes, value]);
            }
          }}
          onClose={(touched) => {
            setDynamicAttributesModal();
            if (touched) {
              setRefresh(!refresh);
            }
          }}
        />
      ) : null}
    </div>
  );
};
export default SettingsHeaderActions;
