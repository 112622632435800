import { useCallback } from "react";
import { api } from "@crewos/shared";

const { useApiGet, useApiMutation, HttpMethod } = api;

const expenseTypeEndpoint = `/api/expense-type`;

// GET expense types (paginated, filtered, etc.)
export const useGetExpenseTypes = () => {
  return useApiGet(expenseTypeEndpoint);
};

// GET all expense types (no pagination)
export const useGetAllExpenseTypes = () => {
  return useApiGet(`${expenseTypeEndpoint}/all`);
};

// CREATE expense type
export const useCreateExpenseType = () => {
  return useApiMutation(HttpMethod.POST, expenseTypeEndpoint);
};

// UPDATE expense type
export const useUpdateExpenseType = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    expenseTypeEndpoint
  );

  const update = useCallback(
    async (expenseType) => {
      await mutate(expenseType);
    },
    [mutate]
  );

  return { ...rest, update };
};

// DELETE expense type
export const useDeleteExpenseType = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    expenseTypeEndpoint
  );

  const deleteExpenseType = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteExpenseType };
};
