import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Form,
} from "reactstrap";

import {
  useCreateAssetType,
  useUpdateAssetType,
} from "../api/AssetTypes.hooks"; // <-- Import from ../api, not ../src

import { components, sharedHelper } from "@crewos/shared";

const { Loader, DynamicAttributesModal } = components;

const AssetTypeModal = ({ assetType = {}, onSubmit, onClose }) => {
  // State variables
  const [name, setName] = useState(assetType.name || "");
  const [dynamicAttributes, setDynamicAttributes] = useState(
    assetType.dynamicAttributes || []
  );
  const [showDynamicModal, setShowDynamicModal] = useState(false);

  // Hooks
  const {
    isLoading: isLoadingCreateAssetType,
    mutate: createAssetType,
    data: createAssetTypeData,
  } = useCreateAssetType();

  const {
    isLoading: isLoadingUpdateAssetType,
    update: updateAssetType,
    data: updateAssetTypeData,
  } = useUpdateAssetType();

  // Effects
  useEffect(() => {
    if (createAssetTypeData) {
      sharedHelper.successToast(`Asset type created`);
      onSubmit?.();
    }
  }, [createAssetTypeData, onSubmit]);

  useEffect(() => {
    if (updateAssetTypeData) {
      sharedHelper.successToast(`Asset type saved`);
      onSubmit?.();
    }
  }, [updateAssetTypeData, onSubmit]);

  // Submit
  const doSubmit = async (e) => {
    e.preventDefault();
    const payload = { name, dynamicAttributes };

    if (assetType.id) {
      await updateAssetType({ id: assetType.id, ...payload });
    } else {
      await createAssetType(payload);
    }
  };

  if (showDynamicModal) {
    return (
      <DynamicAttributesModal
        dynamicAttributes={dynamicAttributes}
        onSubmit={(newAttributes) => {
          setDynamicAttributes(newAttributes);
          setShowDynamicModal(false);
        }}
        onClose={() => setShowDynamicModal(false)}
      />
    );
  }

  return (
    <Modal isOpen={true} toggle={onClose} size="md">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {assetType.id ? "Edit Asset Type" : "Add Asset Type"}
      </ModalHeader>
      <Form data-testid="asset-type-form" onSubmit={doSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Name</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="50"
              type="text"
              placeholder="Enter a name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          {isLoadingCreateAssetType || isLoadingUpdateAssetType ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-between">
              <Button color="white" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className="ms-2"
                color="secondary"
                onClick={() => setShowDynamicModal(true)}
              >
                Dynamic Attributes
              </Button>
              <Button className="ms-2" color="primary" type="submit">
                Confirm
              </Button>
            </div>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AssetTypeModal;
