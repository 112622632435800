import React from "react";
import { useAuth } from "../providers/authProvider";
import { Navigate } from "react-router-dom";
import { sharedHelper } from "../helpers/sharedHelper";
import Loader from "./Loader";

const ScopeFilter = ({ route, children }) => {
  const [authContext] = useAuth();

  if (!authContext || !authContext.userData) {
    return <Loader />;
  }

  const packages = authContext.userData.packages || [];

  const packageEnabled = route.package
    ? sharedHelper.isPackageEnabled(packages, route.package)
    : true;

  const settingEnabled =
    route.package && route.setting
      ? sharedHelper.isSettingEnabled(packages, route.setting)
      : true;

  const routeMeetsRole = sharedHelper.routeMeetsRole(
    route,
    authContext.userData?.role.userRoleScopes
  );

  if (!routeMeetsRole || !packageEnabled || !settingEnabled) {
    return <Navigate to={`/error/401`} />;
  }

  return children;
};

export default ScopeFilter;
