import React, { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";

import {
  Route,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import {
  Page404,
  Page500,
  Page401,
  setCoreSidebarRoutes,
  CoreRoutes,
  SignIn,
} from "@crewos/core";

import { WorkOrderRoutes, setWorkOrderSidebarRoutes } from "@crewos/workorders";
import { UserRoutes, setUserSidebarRoutes } from "@crewos/users";
import { EmployeeRoutes, setEmployeeSidebarRoutes } from "@crewos/employees";

import { CustomerRoutes, setCustomerSidebarRoutes } from "@crewos/customers";
import { ExpenseRoutes, setExpenseSidebarRoutes } from "@crewos/expenses";
import { EquipmentRoutes, setEquipmentSidebarRoutes } from "@crewos/equipments";
import { ScheduleRoutes, setScheduleSidebarRoutes } from "@crewos/schedules";

import { WorkTimeRoutes, setWorkTimeSidebarRoutes } from "@crewos/worktimes";
import { CrewRoutes, setCrewSidebarRoutes } from "@crewos/crews";
import {
  TravelTimeRoutes,
  setTravelTimeSidebarRoutes,
} from "@crewos/traveltimes";

import {
  NonJobTimeRoutes,
  setNonJobTimesSidebarRoutes,
} from "@crewos/nonjobtimes";

import { ReportRoutes, setReportSidebarRoutes } from "@crewos/reports";

import {
  InspectionsRoutes,
  setInspectionsSidebarRoutes,
} from "@crewos/inspections";

import { SafetyRoutes, setSafetySidebarRoutes } from "@crewos/safety";

import { Navigate, useLocation } from "react-router-dom";

import { data, components, api, layouts } from "@crewos/shared";

import { ToastContainer } from "react-toastify";

const { AdminLayout, AuthLayout } = layouts;

const { useAuth, AuthProvider, SidebarProvider, useSidebar, AUTH_ACTIONS } =
  data;

const { authApi } = api;

const { Loader } = components;

const RoutesWrapper = () => {
  const [, setSidebarContext] = useSidebar();
  const [authContext, setAuthContext] = useAuth();
  const location = useLocation();

  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userData = await authApi.validate();
        if (!userData) {
          setLoading(false);
          setRedirectToLogin(true);
        } else {
          setAuthContext({
            action: AUTH_ACTIONS.SET_USER_DATA,
            payload: { userData },
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setRedirectToLogin(true);
      }
    };
    if (!authContext.userData) {
      fetchData();
    }
  }, [authContext.userData, setAuthContext]);

  useEffect(() => {
    const params = {
      setSidebarContext,
      userData: authContext.userData,
    };
    setCoreSidebarRoutes(params);
    setWorkOrderSidebarRoutes(params);
    setScheduleSidebarRoutes(params);
    setCustomerSidebarRoutes(params);
    setExpenseSidebarRoutes(params);
    setEmployeeSidebarRoutes(params);
    setUserSidebarRoutes(params);
    setEquipmentSidebarRoutes(params);
    setWorkTimeSidebarRoutes(params);
    setCrewSidebarRoutes(params);
    setTravelTimeSidebarRoutes(params);
    setReportSidebarRoutes(params);
    setInspectionsSidebarRoutes(params);
    setNonJobTimesSidebarRoutes(params);
    setSafetySidebarRoutes(params);
  }, [setSidebarContext, authContext.userData]);

  return loading ? (
    <Loader />
  ) : redirectToLogin ? (
    <Navigate to={`/auth/sign-in?return-url=${location.pathname}`} />
  ) : (
    <Outlet />
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={"/auth/sign-in"}
        element={
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        }
      />
      <Route path={""} element={<Navigate to="/workorders" />} />
      <Route path={"/"} element={<RoutesWrapper />}>
        <Route path={"/"} element={<AdminLayout />}>
          {CoreRoutes}
          {UserRoutes}
          {WorkOrderRoutes}
          {EmployeeRoutes}
          {CustomerRoutes}
          {ExpenseRoutes}
          {EquipmentRoutes}
          {ScheduleRoutes}
          {WorkTimeRoutes}
          {TravelTimeRoutes}
          {CrewRoutes}
          {ReportRoutes}
          {InspectionsRoutes}
          {NonJobTimeRoutes}
          {SafetyRoutes}
          <Route path={"*"} element={<Page404 />} />
          <Route path={"/error/500"} element={<Page500 />} />
          <Route path={"/error/404"} element={<Page404 />} />
          <Route path={"/error/401"} element={<Page401 />} />
        </Route>
      </Route>
    </>
  )
);

const App = () => (
  <AuthProvider>
    <SidebarProvider>
      <ToastContainer />
      <RouterProvider router={router} />
    </SidebarProvider>
  </AuthProvider>
);

export default App;
