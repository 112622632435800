import { useState, useCallback, useMemo, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import {
  useGetInspectionsTemplates,
  useDeleteTemplate,
  useDuplicateTemplate,
} from "../../../api/Inspections.hooks";

const templatesStatus = [
  { name: "Published", id: "published" },
  { name: "Draft", id: "draft" },
];

export const filters = templatesStatus;

export const useListTemplateLogic = () => {
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);

  const navigate = useNavigate();

  const {
    data: templatesData,
    isLoading,
    get: getInspectionsTemplates,
  } = useGetInspectionsTemplates();

  const { mutate: deleteTemplate } = useDeleteTemplate();

  const { mutate: duplicateTemplate } = useDuplicateTemplate();

  const handleSearch = useCallback(
    ($event) => {
      setSearch($event.target.value);
    },
    [setSearch]
  );

  const handleSelectFilter = useCallback(
    (filter) => {
      setSelectedFilter(filter);
    },
    [setSelectedFilter]
  );

  const handleRefresh = useCallback(async () => {
    await getInspectionsTemplates({ pageSize: 100 });
  }, [getInspectionsTemplates]);

  const handleDeleteItem = useCallback(
    async (id) => {
      await deleteTemplate({ id });
      await handleRefresh();
    },
    [deleteTemplate, handleRefresh]
  );

  const handleCopyItem = useCallback(
    async (id) => {
      await duplicateTemplate({}, { pathParams: { templateId: id } });
      await handleRefresh();
    },
    [duplicateTemplate, handleRefresh]
  );

  const handleView = useCallback(
    (id) => {
      navigate(`/inspections/templates/view/${id}`);
    },
    [navigate]
  );

  const handleEdit = useCallback(
    (id) => {
      navigate(`/inspections/templates/edit/${id}`);
    },
    [navigate]
  );

  useEffect(() => {
    getInspectionsTemplates({ pageSize: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templates = useMemo(() => {
    if (!templatesData) {
      return [];
    }
    return templatesData.data;
  }, [templatesData]);

  const templatesGrouped = useMemo(() => {
    if (!selectedFilter) {
      return templatesStatus.map((status) => ({
        title: status.name,
        templates: templates.filter((template) => {
          const searchCondition = search
            ? template.name.toLowerCase().includes(search.toLowerCase())
            : true;
          return (
            template.status.toLowerCase() === status.id.toLowerCase() &&
            searchCondition
          );
        }),
      }));
    }
    return [
      {
        title: selectedFilter.name,
        templates: templates.filter((template) => {
          const searchCondition = search
            ? template.name.toLowerCase().includes(search.toLowerCase())
            : true;
          return (
            template.status.toLowerCase() === selectedFilter.id.toLowerCase() &&
            searchCondition
          );
        }),
      },
    ];
  }, [templates, selectedFilter, search]);

  return {
    selectedFilter,
    templates,
    templatesGrouped,
    isLoading,
    search,
    handleRefresh,
    handleSearch,
    handleSelectFilter,
    handleDeleteItem,
    handleCopyItem,
    handleView,
    handleEdit,
  };
};
