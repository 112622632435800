import React, { useEffect, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import { useDeleteEquipment } from "../api/Equipments.hooks";
import EquipmentQuantityModal from "./EquipmentQuantityModal";

const { ConfirmationModal, Icon } = components;

const Equipment = ({ equipment, isReadOnly = false, onRefresh }) => {
  const [equipmentModal, setEquipmentModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState();

  const { data: deleteEquipmentData, deleteEquipment } = useDeleteEquipment();

  useEffect(() => {
    if (deleteEquipmentData) {
      sharedHelper.successToast(`Equipment deleted`);
      onRefresh();
    }
  }, [deleteEquipmentData, onRefresh]);

  const onEdit = (equipment, isReadOnly) =>
    setEquipmentModal({ ...equipment, isReadOnly });

  const onDelete = (equipment) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await deleteEquipment(equipment.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Equipment",
      body: `Are you sure you want to delete ${
        equipment.equipmentOption?.name || "N/A"
      } from this work day?`,
      confirmText: "Delete",
    });
  };

  return (
    <Card key={equipment.id} className="h-100 mb-0">
      <CardBody className="d-flex flex-column justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="currency mb-0">{equipment.quantity}</h4>
          <small className="text-muted text-end">
            {equipment.equipmentOption?.name || "N/A"}
          </small>
        </div>
        {!isReadOnly ? (
          <div className="mt-3 d-flex">
            <Button
              className="cursor-pointer border-radius-md"
              onClick={() => onDelete(equipment)}
              data-testid="delete-equipment"
            >
              <Icon name="trash" className="text-danger" />
            </Button>
            <Button
              color="primary"
              className="cursor-pointer ms-2 flex-grow-1"
              onClick={() => onEdit(equipment)}
            >
              Edit
            </Button>
          </div>
        ) : null}
      </CardBody>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : equipmentModal ? (
        <EquipmentQuantityModal
          equipment={equipmentModal}
          onSubmit={() => {
            setEquipmentModal(false);
            sharedHelper.successToast(`Equipment saved`);
            onRefresh();
          }}
          onClose={() => setEquipmentModal(false)}
        />
      ) : null}
    </Card>
  );
};

export default Equipment;
