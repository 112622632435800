import React, { useEffect, useMemo, useState } from "react";

import { Menu, MenuItem, SubMenu } from "react-pro-sidebar-crewos";

import { Link, useLocation, useNavigate } from "react-router-dom";

import Icon from "../Icon";

import {
  getEntriesByCategory,
  getEntryNotifications,
  menuItemStyles,
} from "../../helpers/sidebar";

import { useSidebar } from "../../providers/sidebarProvider";
import { useAuth, ACTIONS as AUTH_ACTIONS } from "../../providers/authProvider";
import TooltipItem from "../TooltipItem";
import { authApi } from "../../api/authServices";
import SupportModal from "../SupportModal";

export const MenuRoutes = ({ routes }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext] = useSidebar();

  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const [supportModal, setSupportModal] = useState();

  useEffect(() => {
    const active = routes.find((route) =>
      location.pathname.startsWith(route.path)
    );
    if (active) {
      setActiveSubMenu(active.path);
    }
  }, [routes, location.pathname]);

  const handleSubMenuClick = (path) => {
    if (activeSubMenu === path) {
      setActiveSubMenu();
    } else {
      setActiveSubMenu(path);
    }
  };

  const onLogout = () => {
    authApi.logout();
    setAuthContext({ action: AUTH_ACTIONS.REFRESH });
    navigate("/auth/sign-in");
  };

  const settingsHasNotifications = useMemo(() => {
    const settingRoutes = routes
      .filter((route) => route.isSettings)
      .flatMap((route) => route.entries);
    if (authContext.userData) {
      return Object.keys(authContext.userData.notifications).find(
        (path) =>
          settingRoutes.find((route) => route.path === path) &&
          authContext.userData.notifications[path] > 0
      );
    }
    return false;
  }, [authContext.userData, routes]);

  return (
    <Menu menuItemStyles={menuItemStyles} closeOnClick={true}>
      {routes.map((route, index) => {
        const entriesByCategory = getEntriesByCategory(
          route.name,
          sidebarContext.isOpen,
          authContext?.userData,
          route.package,
          route.entries
        );
        const categories = Object.keys(entriesByCategory);
        const suffix =
          route.isSettings && settingsHasNotifications ? (
            <Icon
              name="alert-circle"
              className="text-warning align-middle mx-2"
            />
          ) : null;
        const active = location.pathname.startsWith(route.path);
        return (
          <React.Fragment key={index}>
            {categories.length ? (
              <SubMenu
                key={index}
                label={route.name}
                icon={<Icon name={route.icon} />}
                suffix={suffix}
                id={route.path}
                defaultOpen={active}
                active={active}
                open={activeSubMenu === route.path}
                onClick={() => handleSubMenuClick(route.path)}
              >
                {categories
                  .sort((x, y) => (x < y ? -1 : 1))
                  .map((category, index) => {
                    const categoryEntries = entriesByCategory[category];
                    const hasCategory = category !== "N/A";
                    const showCategory =
                      hasCategory &&
                      (!sidebarContext.isOpen || route.isSettings);
                    return (
                      <React.Fragment key={index}>
                        {showCategory && (
                          <MenuItem className="ps-category" disabled>
                            {category}
                          </MenuItem>
                        )}
                        {categoryEntries
                          .sort((x, y) => (x.name < y.name ? -1 : 1))
                          .map((entry, index) => {
                            const path = `${route.path}/${entry.path}`;
                            const suffix = getEntryNotifications(
                              entry,
                              authContext.userData
                            );
                            return (
                              <MenuItem
                                key={index}
                                style={{ textIndent: hasCategory ? 10 : "" }}
                                active={location.pathname === path}
                                component={<Link to={path} />}
                                suffix={suffix}
                              >
                                {entry.name}
                              </MenuItem>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </SubMenu>
            ) : (
              <TooltipItem
                id={`${route.path}-tooltip`}
                title={route.name}
                active={!sidebarContext.isOpen}
                position="right"
              >
                <MenuItem
                  key={index}
                  active={location.pathname === route.path}
                  component={<Link to={route.path} />}
                  icon={<Icon name={route.icon} />}
                >
                  {route.name}
                </MenuItem>
              </TooltipItem>
            )}
          </React.Fragment>
        );
      })}
      <TooltipItem
        id="support-tooltip"
        title="Support"
        active={!sidebarContext.isOpen}
        position="right"
      >
        <MenuItem
          icon={<Icon name="headphones" />}
          onClick={() => setSupportModal(true)}
        >
          Support
        </MenuItem>
      </TooltipItem>
      <TooltipItem
        id="support-tooltip"
        title="Log out"
        active={!sidebarContext.isOpen}
        position="right"
      >
        <MenuItem icon={<Icon name="log-out" />} onClick={onLogout}>
          Logout
        </MenuItem>
      </TooltipItem>
      {supportModal ? <SupportModal onClose={() => setSupportModal()} /> : null}
    </Menu>
  );
};
