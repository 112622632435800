import React, { useState, useEffect } from "react";

import { useDeleteAttachment } from "../api/Attachments.hooks";
import { components, sharedHelper } from "@crewos/shared";

const { ImagesViewer, ImagesViewerModal, ConfirmationModal } = components;

const MODE_PREVIEW = "MODE_PREVIEW";

const width = 150;
const height = 150;

const Photos = ({ attachments, onRefresh }) => {
  const [imageModal, setImageModal] = useState();

  const { data: deleteAttachmentData, deleteAttachment } =
    useDeleteAttachment();

  const [confirmationModal, setConfirmationModal] = useState();

  useEffect(() => {
    if (deleteAttachmentData) {
      sharedHelper.successToast(`Photo deleted`);
      onRefresh();
    }
  }, [deleteAttachmentData, onRefresh]);

  const onDelete = (attachment) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await deleteAttachment(attachment.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Photo",
      body: `Are you sure you want to delete this photo?`,
      confirmText: "Delete",
    });
  };

  return (
    <div className="mt-3 col-12 d-flex justify-content-start align-items-center flex-wrap px-0">
      {attachments
        .filter((attachment) => attachment.url)
        .map((attachment) => {
          return (
            <div
              key={attachment.id}
              style={{ maxHeight: height, maxWidth: width }}
              className="my-1 cursor-pointer me-3 w-100"
              onClick={() => {
                setImageModal(attachment.id);
              }}
            >
              <ImagesViewer
                mode={MODE_PREVIEW}
                height={height}
                width={width}
                showActions={false}
                showCaption={false}
                items={[
                  {
                    url: attachment.url,
                    title: attachment.description,
                  },
                ]}
              />
            </div>
          );
        })}
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : imageModal ? (
        <ImagesViewerModal
          title="Photo Detail"
          items={attachments.map((attachment, j) => ({
            ...attachment,
            title: attachment.description,
            selected: imageModal === attachment.id,
          }))}
          onClose={() => setImageModal(false)}
          onDelete={onDelete}
        />
      ) : null}
    </div>
  );
};

export default Photos;
