import React, { useMemo, useState, useEffect, useCallback } from "react";

import { Button, Card, CardBody, Row } from "reactstrap";

import { components, data } from "@crewos/shared";

import { useGetEquipments } from "../api/Equipments.hooks";

import Equipment from "./Equipment";

import { useCrewWorkDays, CREW_WORK_DAY_ACTIONS } from "@crewos/crews";

const { Loader, Icon } = components;
const { useWorkOrderDetails } = data;

const CrewWorkDayEquipments = ({ crew }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [refresh, setRefresh] = useState();
  const [equipments, setEquipments] = useState([]);

  //only one crew travel day per date/crew
  const crewWorkDay = useMemo(
    () =>
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate]?.find(
        (cwd) => cwd.crewId === crew.id
      ),
    [workOrderDetails.selectedDate, crewWorkDaysContext.crewWorkDays, crew]
  );

  const {
    data: getEquipmentsData,
    isLoading: isLoadingGetEquipments,
    get: getEquipments,
  } = useGetEquipments();

  useEffect(() => {
    if (crewWorkDay) {
      getEquipments({
        crewWorkDayId: crewWorkDay.id,
      });
    }
  }, [getEquipments, refresh, crewWorkDay]);

  useEffect(() => {
    if (getEquipmentsData) {
      setEquipments(getEquipmentsData);
    }
  }, [getEquipmentsData, setEquipments]);

  const onRefresh = useCallback(() => {
    if (!crewWorkDay) {
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH,
      });
    } else {
      setRefresh((prev) => !prev);
    }
  }, [crewWorkDay, setCrewWorkDaysContext]);

  useEffect(() => {
    if (
      crewWorkDaysContext.refreshCrewSection.crewId === crew.id &&
      crewWorkDaysContext.refreshCrewSection.section === "EQUIPMENTS"
    ) {
      onRefresh();
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH_CREW_SECTION_SUCCESS,
      });
    }
  }, [
    crewWorkDaysContext.refreshCrewSection,
    setCrewWorkDaysContext,
    crew,
    onRefresh,
  ]);

  return (
    <Card className="w-100 px-0 box-shadow-none mb-3">
      <CardBody>
        <div className="mb-n1 d-flex justify-content-between align-items-center">
          <h3>Equipment</h3>
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={onRefresh}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </div>
        {isLoadingGetEquipments ? (
          <Loader size="sm" className="mb-3" />
        ) : equipments.length ? (
          <Row className="mt-3 d-flex justify-content-start align-items-start align-items-stretch">
            {equipments.map((equipment) => (
              <div key={equipment.id} className="col-3 my-1">
                <Equipment equipment={equipment} onRefresh={onRefresh} />
              </div>
            ))}
          </Row>
        ) : (
          <div className="p-2 small text-muted text-center">No equipments</div>
        )}
      </CardBody>
    </Card>
  );
};

export default CrewWorkDayEquipments;
