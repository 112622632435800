import React, { useEffect, useState } from "react";

import { useDeleteAssetType, useGetAssetTypes } from "../api/AssetTypes.hooks";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import AssetTypeModal from "../components/AssetTypeModal";
import { DebounceInput } from "react-debounce-input";

const { AdvanceTable, AdvanceTablePagination, ConfirmationModal, Icon } =
  components;

const { useGetAllDynamicAttributes } = data;

const { DynamicAttributesModal } = components;

const columns = (onDelete, onEdit) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.name;
    },
  },
  {
    accessor: "assets",
    header: "Assets",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    type: "number",
    Cell: (rowData) => {
      return rowData.row.assets.length;
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    cellProps: {
      className: "text-end",
      style: { maxWidth: 300 },
    },
    headerProps: {
      className: "text-truncate text-end",
      style: { maxWidth: 300 },
    },
    Cell: (rowData) => (
      <>
        <Button
          size="sm"
          color="none"
          className="ms-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="ms-2 text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const AssetTypes = () => {
  const { get: getDynamicAttributes, data: dynamicAttributesData } =
    useGetAllDynamicAttributes();

  const [assetTypes, setAssetTypes] = useState({});
  const [refresh, setRefresh] = useState();
  const [refreshDynAttr, setRefreshDynAttr] = useState();
  const [search, setSearch] = useState("");
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [createModal, setCreateModal] = useState();
  const [updateModal, setUpdateModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const [dynamicAttributes, setDynamicAttributes] = useState([]);
  const [dynamicAttributesModal, setDynamicAttributesModal] = useState();

  useEffect(() => {
    getDynamicAttributes({ entity: "assetType" });
  }, [getDynamicAttributes, refreshDynAttr]);

  useEffect(() => {
    if (dynamicAttributesData) {
      setDynamicAttributes(dynamicAttributesData);
    }
  }, [dynamicAttributesData]);

  const {
    data: getAssetTypesData,
    isLoading: isLoadingGetAssetTypes,
    get: getAssetTypes,
  } = useGetAssetTypes();

  useEffect(() => {
    getAssetTypes({
      search,
      page,
      pageSize: sizePerPage,
    });
  }, [getAssetTypes, search, refresh, page, sizePerPage]);

  useEffect(() => {
    if (getAssetTypesData) {
      setAssetTypes(getAssetTypesData);
    }
  }, [getAssetTypesData, setAssetTypes]);

  const { data: deleteAssetTypeData, deleteAssetType } = useDeleteAssetType();

  useEffect(() => {
    if (deleteAssetTypeData) {
      sharedHelper.successToast("Asset type deleted");
      setRefresh((prev) => !prev);
    }
  }, [deleteAssetTypeData]);

  const onEdit = (assetType) => setUpdateModal(assetType);

  const onDelete = (assetType) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteAssetType(assetType.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Asset Type",
      body: `Are you sure you want to delete ${assetType.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Asset Types</h2>
            <small className="text-muted ms-2 pt-1">({assetTypes.count})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <DebounceInput
                className="search form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search types"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 d-flex align-items-center"
              color="primary"
              onClick={() => setDynamicAttributesModal(true)}
            >
              Dynamic Attributes
            </Button>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onDelete, onEdit)}
              data={assetTypes.data || []}
              pageSize={sizePerPage}
              isLoading={isLoadingGetAssetTypes}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={assetTypes.count}
            pageCount={assetTypes.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : createModal ? (
        <AssetTypeModal
          onClose={() => setCreateModal()}
          onSubmit={() => {
            setCreateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : updateModal ? (
        <AssetTypeModal
          assetType={updateModal}
          onClose={() => setUpdateModal()}
          onSubmit={() => {
            setUpdateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : dynamicAttributesModal ? (
        <DynamicAttributesModal
          entity="assetType"
          dynamicAttributes={dynamicAttributes}
          onDelete={(id) => {
            const index = dynamicAttributes.findIndex((a) => a.id === id);
            const updatedData = [...dynamicAttributes];
            updatedData.splice(index, 1);
            setDynamicAttributes([...updatedData]);
          }}
          onSubmit={(value) => {
            if (value.id) {
              const index = dynamicAttributes.findIndex(
                (a) => a.id === value.id
              );
              if (index > -1) {
                const updatedData = [...dynamicAttributes];
                updatedData.splice(index, 1, value);
                setDynamicAttributes([...updatedData]);
              } else {
                setDynamicAttributes([...dynamicAttributes, value]);
              }
            } else {
              setDynamicAttributes([...dynamicAttributes, value]);
            }
          }}
          onClose={(touched) => {
            setDynamicAttributesModal();
            if (touched) {
              setRefreshDynAttr(!refreshDynAttr);
            }
          }}
        />
      ) : null}
    </Container>
  );
};

export default AssetTypes;
